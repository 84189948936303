import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useCartStore from '@/store/useCartStore'
import { usePaymentsList } from '@/utils/consts'

const Cash = () => {
  const navigate = useNavigate()
  const setPaymentResult = useCartStore((s) => s.setPaymentResult)
  const getCartTotalPrice = useCartStore((s) => s.getCartTotalPrice)
  const price = getCartTotalPrice()

  const PAYMENTS_LIST = usePaymentsList()

  useEffect(() => {
    setPaymentResult({
      id: PAYMENTS_LIST.CASH.id,
      amount: price,
      transactionId: '0000000',
    })

    navigate('/payment/success')
  }, [])

  return null
}

export default Cash
