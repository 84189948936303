interface Props {
  color: string
  width?: number
  height?: number
}

const CreditCardIcon = ({ color, width = 50, height = 50 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9504 50.1735C12.1268 50.503 11.2209 50.0088 10.8915 49.1853L2.57389 25.303C2.24447 24.4794 2.73859 23.5735 3.56212 23.2441L43.9151 9.16178C44.7386 8.83236 45.6445 9.32648 45.9739 10.15L50.8327 24.0677"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M37.0804 24.2326L40.1275 23.162C41.2804 22.7503 41.8569 21.515 41.4451 20.4444L41.0334 19.2091C40.6216 18.0562 39.3863 17.4797 38.3157 17.8915L35.2687 18.962C34.1157 19.3738 33.5393 20.6091 33.951 21.6797L34.3628 22.915C34.7746 24.0679 36.0098 24.6444 37.0804 24.2326Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M28.7617 21.1025L29.0911 22.0084"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M24.2324 22.668L24.5618 23.5739"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M19.7031 24.2324L20.0325 25.1383"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M15.1738 25.8799L15.5032 26.7858"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M10.6445 27.4443L10.9739 28.3502"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M18.386 60.5503C17.3977 60.5503 16.5742 59.7267 16.5742 58.7385V30.2444C16.5742 29.2561 17.3977 28.4326 18.386 28.4326H66.4801C67.4683 28.4326 68.2919 29.2561 68.2919 30.2444V58.7385C68.2919 59.7267 67.4683 60.5503 66.4801 60.5503H18.386Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M16.6562 35.1855H68.2092"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M16.6562 41.9385H63.1033"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M23.7382 56.0213C25.1026 56.0213 26.2088 54.9151 26.2088 53.5507C26.2088 52.1862 25.1026 51.0801 23.7382 51.0801C22.3737 51.0801 21.2676 52.1862 21.2676 53.5507C21.2676 54.9151 22.3737 56.0213 23.7382 56.0213Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M29.998 56.0205H32.7157"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M29.998 50.9971H40.1275"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M36.998 56.0205H46.6333"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  )
}

export default CreditCardIcon
