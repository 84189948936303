import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Fade, Modal, Stack, Typography, useTheme } from '@mui/material'
import { debounce } from 'lodash'
import PrimaryButton from '@/components/shared/buttons/PrimaryButton'
import SecondaryButton from '@/components/shared/buttons/SecondaryButton'
import CountdownTimer from '@/components/shared/CountdownTimer'
import useSettings from '@/hooks/useSettings'
import useCartStore from '@/store/useCartStore'
import useCategoryStore from '@/store/useCategoryStore'

const INACTIVITY_TIME = 60000 // 1 minute

const InactivityDetector = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation('global')
  const [modalOpen, setModalOpen] = useState(false)

  const clearCartStore = useCartStore((s) => s.clearCartStore)
  const clearCategoryStore = useCategoryStore((s) => s.clearCategoryStore)

  const { data: settings } = useSettings()

  let timer: NodeJS.Timeout | undefined

  useEffect(() => {
    if (settings?.type === 'PORTAL') return

    // eslint-disable-next-line react-hooks/exhaustive-deps
    timer = setTimeout(() => {
      setModalOpen(true)
    }, INACTIVITY_TIME)

    const handleTouch = debounce(() => {
      clearTimeout(timer)

      timer = setTimeout(() => {
        setModalOpen(true)
      }, INACTIVITY_TIME)
    }, 500)

    window.addEventListener('touchstart', handleTouch)
    window.addEventListener('touchend', handleTouch)
    window.addEventListener('touchcancel', handleTouch)
    window.addEventListener('touchmove', handleTouch)

    return () => {
      window.removeEventListener('touchstart', handleTouch)
      window.removeEventListener('touchend', handleTouch)
      window.removeEventListener('touchcancel', handleTouch)
      window.removeEventListener('touchmove', handleTouch)

      clearTimeout(timer)
    }
  }, [])

  const handleCancelOrder = () => {
    clearCartStore()
    clearCategoryStore()

    if (window.navigator.onLine) {
      window.location.href = '/'
    } else {
      navigate('/')
    }
  }

  const handleClose = () => setModalOpen(false)

  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <Fade in={modalOpen}>
        <Box
          width="100%"
          maxWidth="1000px"
          position="absolute"
          top="33%"
          left="50%"
          borderRadius="100px"
          pt={13}
          px={7.5}
          pb={7.5}
          sx={{
            transform: 'translate(-50%, -50%)',
            background: theme.palette.primary.light,
            [theme.breakpoints.down('md')]: {
              top: '50%',
              p: 2,
              borderRadius: '30px',
              width: '94%',
            },
          }}
        >
          <Box
            width={100}
            height={100}
            position="absolute"
            sx={{
              transform: 'translate(-50%, -50%)',
              top: '0%',
              left: '50%',
              [theme.breakpoints.down('md')]: {
                width: '46px',
                height: '44px',
                svg: {
                  width: 30,
                },
              },
            }}
          >
            <PrimaryButton fullWidth onClick={handleClose}>
              <svg
                width="100"
                height="100"
                viewBox="0 0 50 50"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 46L25.0001 25.0001M25.0001 25.0001L46 4M25.0001 25.0001L4 4M25.0001 25.0001L46 46"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </PrimaryButton>
          </Box>
          <Box maxWidth="700px" mx="auto">
            <Box
              mb={5}
              sx={{
                [theme.breakpoints.down('md')]: {
                  mb: 2,
                },
              }}
            >
              <Typography
                variant="h3"
                textAlign="center"
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: '20px',
                  },
                }}
              >
                {t('inactivityQuestion')}
              </Typography>
            </Box>

            <Stack direction="column" spacing={2.5}>
              <PrimaryButton onClick={handleClose}>
                {t('continue')}
              </PrimaryButton>
              <SecondaryButton onClick={handleCancelOrder}>
                {t('cancelOrder')}
                <CountdownTimer
                  initialSeconds={20}
                  onFinish={handleCancelOrder}
                />
              </SecondaryButton>
            </Stack>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}

export default InactivityDetector
