import { styled } from '@mui/material'

export const Container = styled('ol')`
  padding-right: 28px;
`

export const Ol = styled('ol')`
  padding-right: 0;
`

export const Ul = styled('ul')`
  padding-right: 18px;
`

export const LiDef = styled('li')`
  padding: 8px 0;
`

export const Block = styled('li')``

export const Li = styled('li')`
  padding: 8px 0;
  list-style-type: none;
`
