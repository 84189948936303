import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Fade, Modal, Stack, Typography, useTheme } from '@mui/material'
import useUpsells from '@/hooks/useUpsells'
import useUpsellStore from '@/store/useUpsellStore'
import type ICustomer from '@/types/Customer'
import useSettings from '../../hooks/useSettings'
import useCartStore from '../../store/useCartStore'
import PrimaryButton from '../shared/buttons/PrimaryButton'
import SecondaryButton from '../shared/buttons/SecondaryButton'
import Keyboard from '../shared/keyboard/Keyboard'
import CheckoutUpsell from '../upsell/CheckoutUpsell'
// eslint-disable-next-line import/no-cycle
import { MyContext } from './Cart'
import UserInfoForm from './UserInfoForm'

const CartActions = () => {
  const theme = useTheme()
  const { t } = useTranslation('cart')
  const { isExtended, setIsExtended } = useContext(MyContext)!
  const { data: settings } = useSettings()
  const { data: upsells } = useUpsells()
  const upsellThresholdSum = upsells?.checkout?.bunches[0]
    ? upsells.checkout.bunches[0].thresholdSum
    : 0

  const [openForm, setOpenForm] = useState<boolean>(false)
  const [inputName, setInputName] = useState<string>('')
  const [inputNumber, setInputNumber] = useState<string>('')
  const [inputEmail, setInputEmail] = useState<string>('')
  const [focusedInput, setFocusedInput] = useState<boolean>(true)

  const isOpenSwitchBasket = () => {
    setIsExtended((prevIsExtended) => !prevIsExtended)
  }

  const setCustomer = useCartStore((s) => s.setCustomer)
  const orderType = useCartStore((s) => s.orderType)
  const minimumOrderPrice = useCartStore((s) => s.minimumOrderPrice)
  const { getCartTotalPrice } = useCartStore()
  const navigate = useNavigate()

  const cartPrice = getCartTotalPrice()

  const handleSubmit = (data: ICustomer) => {
    setCustomer(data)
    navigate('/payment')
  }

  const showCheckoutUpsell = useUpsellStore((s) => s.showCheckoutUpsell)
  const hideCheckoutUpsell = useUpsellStore((s) => s.hideCheckoutUpsell)
  const checkoutUpsellShown = useUpsellStore((s) => s.checkoutUpsellShown)

  const handleProceed = () => {
    if (orderType === 'DELIVERY') {
      navigate('/delivery/details')
      return
    }

    setIsExtended(false)

    if (!checkoutUpsellShown && cartPrice < upsellThresholdSum) {
      showCheckoutUpsell()
      return
    }

    hideCheckoutUpsell()
    setOpenForm(true)
  }

  return (
    <Box
      sx={{
        [theme.breakpoints.down('md')]: {
          mt: 2,
        },
      }}
    >
      <Stack
        direction="row"
        spacing={3.75}
        px={6.25}
        pb={5}
        sx={{
          background: theme.palette.primary.light,
          [theme.breakpoints.down('md')]: {
            pl: 1,
            pr: 1,
            pb: 2,
          },
        }}
      >
        <SecondaryButton onClick={isOpenSwitchBasket}>
          {isExtended ? t('cancel') : t('checkTheCart')}
        </SecondaryButton>
        <PrimaryButton
          fullWidth
          disabled={cartPrice < minimumOrderPrice}
          onClick={handleProceed}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {cartPrice < minimumOrderPrice
            ? `${t('minimumSum')}: ${minimumOrderPrice} ₪`
            : orderType === 'DELIVERY'
              ? t('deliveryDetails')
              : t('proceedToCheckout')}
        </PrimaryButton>
      </Stack>
      <Modal open={openForm} onClose={() => setOpenForm(false)}>
        <Fade in={openForm}>
          <Box
            width="100%"
            maxWidth="1000px"
            position="absolute"
            top="33%"
            left="50%"
            borderRadius="100px"
            pt={13}
            px={7.5}
            pb={7.5}
            sx={{
              transform: 'translate(-50%, -50%)',
              background: theme.palette.primary.light,
              [theme.breakpoints.down('md')]: {
                top: '50%',
                borderRadius: '30px',
                p: 2,
                width: '94%',
              },
            }}
          >
            <Box
              width={100}
              height={100}
              position="absolute"
              sx={{
                transform: 'translate(-50%, -50%)',
                top: '-7%',
                left: '50%',
                [theme.breakpoints.down('md')]: {
                  width: '46px',
                  height: '44px',
                  svg: {
                    width: 30,
                  },
                },
              }}
            >
              <PrimaryButton
                fullWidth
                onClick={() => setOpenForm((prSt) => !prSt)}
              >
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 46L25.0001 25.0001M25.0001 25.0001L46 4M25.0001 25.0001L4 4M25.0001 25.0001L46 46"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </PrimaryButton>
            </Box>

            <Box maxWidth="700px" mx="auto">
              <Box
                mb={7.5}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    mb: 2,
                  },
                }}
              >
                <Typography
                  variant="body"
                  fontWeight="600"
                  lineHeight="1.6"
                  textAlign="center"
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      fontSize: '20px',
                    },
                  }}
                >
                  {t('formTitle')}
                </Typography>
                <Typography
                  variant="body"
                  fontStyle="italic"
                  lineHeight="1.6"
                  textAlign="center"
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      fontSize: '16px',
                    },
                  }}
                >
                  {t('formSubtitle')}
                </Typography>
              </Box>

              <UserInfoForm
                inputName={inputName}
                inputNumber={inputNumber}
                inputEmail={inputEmail}
                onSubmit={handleSubmit}
                setFocusedInput={setFocusedInput}
                setInputName={setInputName}
                setInputNumber={setInputNumber}
                setInputEmail={setInputEmail}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>
      <CheckoutUpsell onProceed={handleProceed} />
      {settings?.type === 'KIOSK' && (
        <Keyboard
          openKeyboard={openForm}
          setOpenKeyboard={setOpenForm}
          inputValue={focusedInput ? inputName : inputNumber}
          setInputValue={focusedInput ? setInputName : setInputNumber}
          onlyNumber={focusedInput}
        />
      )}
    </Box>
  )
}

export default CartActions
