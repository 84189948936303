import { useTranslation } from 'react-i18next'
import { Stack, Typography, useTheme } from '@mui/material'
import FullPizzaIcon from '@/components/icons/product/pizza/FullPizzaIcon'
import LeftPartPizzaIcon from '@/components/icons/product/pizza/LeftPartPizzaIcon'
import RightPartPizzaIcon from '@/components/icons/product/pizza/RightPartPizzaIcon'

interface Props {
  selectedPart: string
  onModify: (pizzaPart: string, quantity: number) => void
}

const ChoicePizzaParts = ({ selectedPart, onModify }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation('product')

  const PIZZA_PARTS = {
    FULL: {
      name: t('fullPizza'),
      icon: FullPizzaIcon,
      quantity: 1,
    },
    LEFT: {
      name: t('leftPizzaPart'),
      icon: LeftPartPizzaIcon,
      quantity: 0.5,
    },
    RIGHT: {
      name: t('rightPizzaPart'),
      icon: RightPartPizzaIcon,
      quantity: 0.5,
    },
  }

  return (
    <>
      {Object.entries(PIZZA_PARTS).map(([key, value]) => {
        const PizzaPartIcon = value.icon

        return (
          <Stack
            direction="column"
            alignItems="center"
            p={1.25}
            borderRadius="5px"
            spacing={1.25}
            key={key}
            sx={{
              background:
                selectedPart === key
                  ? theme.palette.primary.main
                  : 'transparent',
              [theme.breakpoints.down('md')]: {
                gap: 1,
                svg: {
                  marginTop: '0 !important',
                  width: '25px',
                },
              },
            }}
            onClick={() => onModify(key, value.quantity)}
          >
            <Typography
              fontSize="16px"
              fontWeight="600"
              color={
                selectedPart === key
                  ? theme.palette.common.white
                  : theme.palette.primary.dark
              }
              sx={{
                [theme.breakpoints.down('md')]: {
                  display: 'none',
                },
              }}
            >
              {value.name}
            </Typography>

            <PizzaPartIcon />
          </Stack>
        )
      })}
    </>
  )
}

export default ChoicePizzaParts
