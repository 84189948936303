import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  useTheme,
} from '@mui/material'
import { DateTime } from 'luxon'
import useSettings from '@/hooks/useSettings'
import useSettingsStore from '@/store/useSettinsStore'

interface Props {
  availableDates: string[]
  selectedDate: string | null
  handleDateChange: (event: SelectChangeEvent<string>) => void
}
const DateSelect = ({
  availableDates,
  handleDateChange,
  selectedDate,
}: Props) => {
  const theme = useTheme()
  const currentLanguage = useSettingsStore((s) => s.currentLanguage)
  const { data: settings } = useSettings()

  return (
    <FormControl fullWidth>
      <Select
        value={DateTime.fromISO(selectedDate as string).toISODate() || ''}
        onChange={handleDateChange}
        label="Select Date"
        sx={{
          borderRadius: '30px',
          px: 5,
          py: 3.25,
          fontSize: '48px',
          color: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '.MuiSvgIcon-root': {
            fontSize: '100px',
            color: theme.palette.primary.main,
          },
          [theme.breakpoints.down('md')]: {
            fontSize: '16px',
            borderRadius: '20px',
            p: '10px',
            '.MuiSvgIcon-root': {
              fontSize: '50px',
              color: theme.palette.primary.main,
            },
          },
        }}
      >
        {availableDates?.map((date) => (
          <MenuItem
            key={date}
            value={date}
            sx={{
              fontSize: '40px',
              px: 5,
              py: 3.25,
              color: theme.palette.primary.main,
              [theme.breakpoints.down('md')]: {
                fontSize: '16px',
                p: '10px',
              },
            }}
          >
            {DateTime.fromISO(date)
              .setLocale(currentLanguage || settings?.defaultLanguage || 'en')
              .toLocaleString(DateTime.DATE_SHORT)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
export default DateSelect
