import { useQuery } from '@tanstack/react-query'
import { getChanelId } from '@/services/auth'
import { getMenu } from '@/services/menu'

const useMenu = () => {
  const channelId = getChanelId()

  return useQuery({
    queryKey: ['menu', channelId],
    queryFn: getMenu,
    staleTime: Infinity,
  })
}

export default useMenu
