import { useIsAuthenticated } from 'react-auth-kit'
import { Navigate } from 'react-router-dom'

interface Props {
  children: JSX.Element
}

const PrivateRoute = ({ children }: Props) => {
  const isAuthenticated = useIsAuthenticated()
  const auth = isAuthenticated()

  return auth ? children : <Navigate to="/login" />
}

export default PrivateRoute
