import { createContext, Dispatch, FC, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, useTheme } from '@mui/material'
import useCartStore from '../../store/useCartStore'
// eslint-disable-next-line import/no-cycle
import CartActions from './CartActions'
// eslint-disable-next-line import/no-cycle
import CartBody from './CartBody'
import CartHeader from './CartHeader'

interface IMyContextProps {
  isExtended: boolean
  setIsExtended: Dispatch<SetStateAction<boolean>>
}

export const MyContext = createContext<IMyContextProps | undefined>(undefined)

const Cart: FC = () => {
  const theme = useTheme()
  const { t } = useTranslation('cart')

  const [isExtended, setIsExtended] = useState<boolean>(false)
  const cart = useCartStore((s) => s.cart)

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <MyContext.Provider value={{ isExtended, setIsExtended }}>
      <Box
        sx={
          isExtended
            ? {
                display: 'flex',
                height: '100%',
                alignItems: 'flex-end',
                position: 'absolute',
                bottom: 0,
                width: '100%',
                backgroundColor: '#32131333',
                backdropFilter: 'blur(5px)',
              }
            : {
                position: 'absolute',
                width: '100%',
                bottom: 0,
              }
        }
        onClick={() => isExtended && setIsExtended(false)}
      >
        <Box
          borderRadius="75px 75px 0 0"
          overflow="hidden"
          flex="1"
          sx={{
            background: theme.palette.primary.light,
            [theme.breakpoints.down('md')]: {
              borderRadius: '45px 45px 0 0',
            },
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <CartHeader />

          {cart.length > 0 ? (
            <>
              <CartBody />
              <CartActions />
            </>
          ) : (
            <Box
              p={9}
              sx={{
                background: theme.palette.primary.light,
                [theme.breakpoints.down('md')]: {
                  pt: 2,
                  pb: 2,
                },
              }}
            >
              <Typography
                variant="body"
                textAlign="center"
                sx={{
                  background: theme.palette.primary.light,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '14px',
                  },
                }}
              >
                {t('emptyCart')}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </MyContext.Provider>
  )
}

export default Cart
