import { useEffect, useState } from 'react'
import { useSignOut } from 'react-auth-kit'
import {
  Box,
  CircularProgress,
  Fade,
  Modal,
  Typography,
  useTheme,
} from '@mui/material'
import CompanyLogo from '@/components/menu/CompanyLogo'
import PrimaryButton from '@/components/shared/buttons/PrimaryButton'
import SecondaryButton from '@/components/shared/buttons/SecondaryButton'
import useGetIPAddress from '@/hooks/useGetIpAddress'
import useSettings from '@/hooks/useSettings'
import useCartStore from '@/store/useCartStore'
import useCategoryStore from '@/store/useCategoryStore'

const ModalService = () => {
  const theme = useTheme()
  const [open, setOpen] = useState<boolean>(false)
  const signOut = useSignOut()

  const { data: settings } = useSettings()
  const [tapCount, setTapCount] = useState(0)

  const clearCartStore = useCartStore((s) => s.clearCartStore)
  const clearCategoryStore = useCategoryStore((s) => s.clearCategoryStore)

  const { data, isFetching } = useGetIPAddress(open)

  useEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined
    if (tapCount > 0) {
      timer = setTimeout(() => setTapCount(0), 300)
    }
    return () => clearTimeout(timer)
  }, [tapCount])

  const handleTap = () => {
    setTapCount((prevCount) => prevCount + 1)

    if (tapCount === 2) {
      setTimeout(() => setOpen(true), 500)
      setTapCount(0)
    }
  }
  const isSignOut = () => {
    signOut()
    clearCartStore()
    clearCategoryStore()
  }

  const handleClose = () => setOpen(false)

  return (
    <Box
      tabIndex={0}
      onTouchStart={handleTap}
      sx={{
        width: '15px',
        height: '15px',
        position: 'absolute',
        top: '10px',
        right: '10px',
        p: 5,
      }}
    >
      <Modal open={open} onClose={handleClose}>
        <Fade in={open}>
          <Box
            sx={{
              width: '65%',
              height: '30%',
              position: 'absolute',
              top: '45%',
              p: '20px',
              left: '50%',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              alignItems: 'center',
              transform: 'translate(-50%, -50%)',
              background: theme.palette.primary.light,
              borderRadius: '30px',
            }}
          >
            <CompanyLogo />
            <Box
              sx={{
                mb: '55px',
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h3"
                sx={{ fontWeight: 600, fontSize: '35px' }}
              >
                {settings?.channelName}
              </Typography>
              {isFetching ? (
                <CircularProgress />
              ) : (
                <>{data?.ip && <Typography>IP: {data.ip}</Typography>}</>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                gap: '10px',
              }}
            >
              <PrimaryButton fullWidth onClick={isSignOut}>
                Log Out
              </PrimaryButton>
              <SecondaryButton onClick={() => setOpen(false)}>
                Close
              </SecondaryButton>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  )
}

export default ModalService
