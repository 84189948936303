import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Stack, useTheme } from '@mui/material'
import CompanyIcon from '../../../components/icons/payment/CompanyIcon'
import CreditCardIcon from '../../../components/icons/payment/CreditCardIcon'
import ManualInputIcon from '../../../components/icons/payment/ManualInputIcon'
import PaymentOptionButton from '../../../components/payment/PaymentOptionButton'
import useSettings from '../../../hooks/useSettings'
import PaymentLayout from '../../../layouts/PaymentLayout'

const Cibus = () => {
  const theme = useTheme()
  const { t } = useTranslation('payment')

  const { data: settings } = useSettings()
  const navigate = useNavigate()

  useEffect(() => {
    if (settings?.type === 'PORTAL') {
      navigate('/payment/cibus/manual-input')
    }
  }, [settings])

  return (
    <PaymentLayout
      title={t('cibusTitle')}
      subTitle={t('chooseOption')}
      backTo="/payment"
    >
      <Stack
        direction="column"
        width="100%"
        maxWidth="785px"
        mx="auto"
        gap={7.7}
        sx={{
          [theme.breakpoints.down('md')]: {
            gap: '10px',
          },
        }}
      >
        <PaymentOptionButton
          to="/payment/cibus/terminal"
          name={t('attachCard')}
          icon={
            <CreditCardIcon
              width={70}
              height={70}
              color={theme.palette.primary.dark}
            />
          }
        />

        <PaymentOptionButton
          to="/payment/cibus/choose-company"
          name={t('chooseCompany')}
          icon={<CompanyIcon color={theme.palette.primary.dark} />}
        />

        <PaymentOptionButton
          to="/payment/cibus/manual-input"
          name={t('manualInput')}
          icon={<ManualInputIcon color={theme.palette.primary.dark} />}
        />
      </Stack>
    </PaymentLayout>
  )
}

export default Cibus
