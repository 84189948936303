import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import CompanyIcon from '@/components/icons/payment/CompanyIcon'
import Order from '@/components/order/Order'
import PaymentOptionButton from '@/components/payment/PaymentOptionButton'
import useCartStore from '@/store/useCartStore'
import { usePaymentsList } from '@/utils/consts'

const PaymentSuccess = () => {
  const theme = useTheme()
  const { t } = useTranslation('payment')

  const [searchParams] = useSearchParams()
  const company = searchParams.get('company')

  const paymentResult = useCartStore((s) => s.paymentResult)
  const PAYMENTS_LIST = usePaymentsList()
  const paymentMethod = Object.values(PAYMENTS_LIST).find(
    (p) => p.id === paymentResult.id,
  )

  const navigate = useNavigate()
  const orderType = useCartStore((s) => s.orderType)

  useEffect(() => {
    if (!orderType) {
      navigate('/where-to-eat')
    }
  }, [orderType])

  return (
    <Stack
      direction="column"
      alignItems="center"
      width="100%"
      maxWidth="785px"
      mx="auto"
      py={10}
      gap={7.7}
      sx={{
        [theme.breakpoints.down('md')]: {
          gap: 2,
          p: 2,
        },
      }}
    >
      {paymentMethod ? (
        <Typography
          variant="h1"
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '34px',
            },
          }}
        >
          {paymentMethod.name}
        </Typography>
      ) : null}

      <Typography
        variant="body"
        sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: '20px',
          },
        }}
      >
        {t('paymentStatus')}
      </Typography>

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        borderRadius="30px"
        px={5}
        py={3.75}
        sx={{
          background: '#1E8133',
          [theme.breakpoints.down('md')]: {
            p: 2,
            borderRadius: '15px',
            svg: {
              height: 33,
            },
          },
        }}
      >
        <Typography
          variant="h2"
          color="common.white"
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '20px',
            },
          }}
        >
          {t('done')}
        </Typography>
        <svg
          width="59"
          height="60"
          viewBox="0 0 59 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="59" height="60" fill="#1E8133" />
          <path
            d="M3.53906 33.8398L18.3733 49.1998L55.4591 10.7998"
            stroke="white"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Stack>

      {company ? (
        <>
          <Typography variant="body">{t('choosenCompanyIs')}</Typography>

          <Box width="100%">
            <PaymentOptionButton
              name={company}
              icon={<CompanyIcon color={theme.palette.primary.dark} />}
            />
          </Box>
        </>
      ) : null}

      <Order />
    </Stack>
  )
}

export default PaymentSuccess
