import { useEffect } from 'react'
import { Box } from '@mui/material'
import MultipleChoiceOption from '@/components/product/options/multiple-choice-option'
import SingleChoiceOption from '@/components/product/options/single-choice-option'
import useProductErrorStore from '@/store/useProductErrorStore'
import useProductStore from '@/store/useProductStore'

const ProductCustomizations = () => {
  const product = useProductStore((s) => s.product)
  const optionsWithError = useProductErrorStore((s) => s.optionsWithError)
  const clearOptionsError = useProductErrorStore((s) => s.clearOptionsError)

  // TODO: Refactore option errors
  const hasError = (optionId: string) =>
    optionsWithError.some((option) => option.id === optionId)

  useEffect(() => {
    if (optionsWithError.length > 0) {
      const timer = setTimeout(() => {
        clearOptionsError()
      }, 3000)

      return () => {
        clearTimeout(timer)
        clearOptionsError()
      }
    }
  }, [optionsWithError])

  return (
    <Box>
      {product?.options.map((option) => (
        <Box key={option.id} id={option.id}>
          {(option.type === 'MULTIPLE_CHOICE' ||
            option.type === 'MULTIPLE_CHOICE_PIZZA') && (
            <MultipleChoiceOption option={option} error={hasError(option.id)} />
          )}

          {option.type === 'SINGLE_CHOICE' && (
            <SingleChoiceOption option={option} />
          )}
        </Box>
      ))}
    </Box>
  )
}

export default ProductCustomizations
