import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, useTheme } from '@mui/material'
import OrderSummary from '@/components/order/OrderSummary'
import Receipt from '@/components/receipt'
import PrimaryButton from '@/components/shared/buttons/PrimaryButton'
import CountdownTimer from '@/components/shared/CountdownTimer'
import useSettings from '@/hooks/useSettings'
import { createOrder, saveOrderLocally } from '@/services/order'
import useCartStore from '@/store/useCartStore'
import useCategoryStore from '@/store/useCategoryStore'
import useSettingsStore from '@/store/useSettinsStore'

const Order = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation('finalScreen')
  const { data: settings } = useSettings()

  const [isLoading, setIsLoading] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isError, setIsError] = useState(false)
  const [response, setResponse] = useState({
    orderNumber: 0,
  })

  const cart = useCartStore((s) => s.cart)
  const orderType = useCartStore((s) => s.orderType)
  const customer = useCartStore((s) => s.customer)
  const paymentResult = useCartStore((s) => s.paymentResult)
  const getCartTotalPrice = useCartStore((s) => s.getCartTotalPrice)
  const selectedDate = useCartStore((s) => s.selectedDate)

  const totalPrice = getCartTotalPrice()

  const order = {
    currency: settings?.defaultCurrency || '',
    totals: {
      subtotal: 0,
      total: totalPrice,
      tax: 0,
      discount: 0,
    },
    type: orderType,
    details: { promisedTime: selectedDate },
    sourceType: settings?.type || 'KIOSK',
    payments: [paymentResult],
    customers: [
      {
        name: customer.name,
        phone: customer.phone ? customer.phone : '0500000000',
        email: customer.email,
        items: cart.map((product, index) => ({
          ...product,
          totalPrice: product.options.reduce(
            (acc1, option) =>
              acc1 +
              option.choices.reduce(
                (acc2, choice) =>
                  acc2 + choice.price * (choice.quantity - choice.freeQuantity),
                0,
              ),
            product.price,
          ),
          options: product.options
            .map((option) => {
              return {
                ...option,
                choices: option.choices
                  .filter((choice) => choice.quantity > 0)
                  .map((choice, index) => ({
                    ...choice,
                    quantity: choice.quantity * product.quantity,
                    position: index + 1,
                    price: choice.freeQuantity ? 0 : choice.price,
                  })),
              }
            })
            .filter((option) => option.choices.length > 0),
          position: index + 1,
        })),
      },
    ],
    deliveryDetails: {
      address: {
        apartment: customer.address?.apartment || '',
        floor: customer.address?.floor || '',
        entrance: customer.address?.entrance || '',
        latitude: customer.address?.location?.lat || 0,
        longitude: customer.address?.location?.lng || 0,
        comment: customer.address?.comment || '',
      },
    },
  }

  useEffect(() => {
    setIsLoading(true)

    if (window.navigator.onLine) {
      createOrder(order)
        .then((res: any) => {
          setIsLoading(false)
          setIsLoaded(true)
          setResponse({ orderNumber: res.id })
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
          setIsError(true)
        })
    } else {
      saveOrderLocally(order)
      setIsLoading(false)
      setIsLoaded(true)
      setResponse({ orderNumber: 0 })
    }
  }, [])

  const clearCartStore = useCartStore((s) => s.clearCartStore)
  const clearCategoryStore = useCategoryStore((s) => s.clearCategoryStore)
  const clearSettingsStore = useSettingsStore((s) => s.clearSettingsStore)

  const resetApp = () => {
    clearCartStore()
    clearCategoryStore()
    clearSettingsStore()

    if (window.navigator.onLine) {
      window.location.href = '/'
    } else {
      navigate('/')
    }
  }

  if (isLoading)
    return (
      <Box py={20}>
        <Typography
          variant="body"
          fontStyle="italic"
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '20px',
            },
          }}
        >
          {t('sendingOrder')}
        </Typography>
      </Box>
    )

  if (isError)
    return (
      <Box py={20}>
        <Typography
          variant="body"
          fontStyle="italic"
          color={theme.palette.error.main}
          mb={10}
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '20px',
            },
          }}
        >
          {t('errorSendingOrder')}
        </Typography>

        <PrimaryButton onClick={resetApp}>
          {t('backToHome')}{' '}
          <CountdownTimer initialSeconds={20} onFinish={resetApp} />
        </PrimaryButton>
      </Box>
    )

  if (isLoaded)
    return <OrderSummary number={response.orderNumber} price={totalPrice} />

  return <Receipt order={order} />
}

export default Order
