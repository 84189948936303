import { useQuery } from '@tanstack/react-query'
import { getCibusCompanies } from '@/services/cibus-companies'

const useCibusCompanies = () => {
  return useQuery({
    queryKey: ['companyCodes'],
    queryFn: getCibusCompanies,
    staleTime: 24 * 60 * 60 * 1000,
  })
}

export default useCibusCompanies
