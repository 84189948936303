/* eslint-disable react/no-unescaped-entities */
import { Box, Link, SxProps, Typography } from '@mui/material'
import { Li, Ul } from '@/components/home/documents/styled'

const AccessibilityHe = ({ sx }: { sx: SxProps }) => {
  return (
    <Box sx={sx} dir="RTL">
      <Typography variant="h6" component="h1" gutterBottom>
        הסדרי נגישות של tap2dine.com
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        מבוא
      </Typography>

      <Typography paragraph>
        האינטרנט מהווה כיום את המאגר הגדול ביותר לחופש המידע עבור כלל המשתמשים,
        ומשתמשים בעלי מוגבלויות בפרט. ככזה, אנו שמים חשיבות רבה במתן אפשרות שווה
        לאנשים עם מוגבלות לשימוש במידע המוצג באתר, ולאפשר חווית גלישה טובה יותר.
        אנו שואפים להבטיח כי השירותים הדיגיטליים יהיו נגישים לאנשים עם
        מוגבלויות, ועל כן הושקעו משאבים רבים להקל את השימוש באתר עבור אנשים עם
        מוגבלויות, ככל האפשר, מתוך אמונה כי לכל אדם מגיעה הזכות לחיות בשוויון,
        כבוד, נוחות ועצמאות.
      </Typography>

      <Typography paragraph>
        כדי לממש הבטחה זו, אנו שואפים לדבוק ככל האפשר בהמלצות התקן הישראלי (ת"י
        5568) לנגישות תכנים באינטרנט ברמת AA ומסמך WCAG2.1 הבינלאומי.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        כיצד עובדת ההנגשה באתר?
      </Typography>

      <Typography paragraph>
        באתר מוצב תפריט הנגשה של חברת הנגשת אתרים - נגיש בקליק. לחיצה על כפתור
        הנגישות מאפשרת את פתיחת התפריט המכיל כפתורי ההנגשה. לאחר בחירת נושא
        בתפריט יש להמתין לטעינת הדף. התוכנה פועלת בדפדפנים הפופולריים: Chrome,
        Firefox, Safari, Opera. אחריות השימוש והיישום באתר חלה על בעלי האתר ו/או
        מי מטעמו לרבות התוכן המוצג באתר בכפוף למגבלות ולתנאי השימוש בתוכנה.
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        אפשרויות הנגישות בתפריט
      </Typography>

      <Ul>
        <Li>התאמה לניווט מקלדת- מתן אפשרות לניווט ע" מקלדת</Li>
        <Li>
          התאמה לקורא מסך - התאמת האתר עבור טכנולוגיות מסייעות כגון NVDA , JAWS
        </Li>
        <Li>חסימת הבהובים - עצירת אלמנטים נעים וחסימת הבהובים</Li>
        <Li>הגדלת פונט האתר ל-4 גדלים שונים</Li>
        <Li>התאמות ניגודיות - שינוי ניגודיות צבעי האתר</Li>
        <Li>התאמת האתר לעיוורי צבעים</Li>
        <Li>שינוי הפונט לקריא יותר</Li>
        <Li>הגדלת הסמן ושינוי צבעו לשחור או לבן</Li>
        <Li>הגדלת התצוגה לכ-200%</Li>
        <Li>הדגשת קישורים באתר</Li>
        <Li>הדגשת כותרות באתר</Li>
        <Li>הצגת תיאור אלטרנטיבי לתמונות</Li>
        <Li>הצהרת נגישות</Li>
        <Li>שליחת משוב נגישות</Li>
      </Ul>

      <Typography variant="h6" component="h2" gutterBottom>
        שימוש ברכיבים ואתרי צד שלישי
      </Typography>

      <Typography paragraph>
        כאשר נעשה שימוש ברכיבים או אתרי אינטרנט של צד שלישי באתר כגון פייסבוק,
        אינסטגרם, יוטיוב, טוויטר, צ`אטים חיצוניים ואחרים שאינם בשליטתנו, ייתכן
        והדבר יציב אתגרים בשימוש באתר בידי אנשים בעלי מוגבלויות שאין ביכולתנו
        לתקן. להלן מספר דוגמאות למדיניות הנגישות באתרי צד שלישי:
      </Typography>

      <Ul>
        <Li>מדיניות הנגישות של פייסבוק</Li>
        <Li>מדיניות הנגישות של יוטיוב</Li>
        <Li>מדיניות הנגישות של אינסטגרם</Li>
        <Li>מדיניות הנגישות של טוויטר</Li>
      </Ul>

      <Typography variant="h6" component="h2" gutterBottom>
        פניה לרכז הנגישות
      </Typography>

      <Typography paragraph>
        אם במהלך הגלישה באתר נתקלתם בקושי בנושא נגישות, צוות הנגישות של החברה
        עומד לרשותכם במגוון ערוצים לפנייה בנושאי נגישות, נשמח לקבל מכם משוב.
      </Typography>

      <Typography paragraph>
        טלפון:
        <Link href="tel:0526923399">0526923399</Link>
        <br />
        דואר אלקטרוני:
        <Link href="mailto:info@tap2dine.com">info@tap2dine.com</Link>
      </Typography>

      <Typography paragraph fontSize={16}>
        תאריך עדכון אחרון: 09/07/2024
      </Typography>

      <Typography variant="h6" component="h2" gutterBottom>
        הבהרה
      </Typography>

      <Typography paragraph>
        חרף מאמצנו לאפשר גלישה באתר נגיש עבור כל דפי האתר, יתכן ויתגלו דפים באתר
        שטרם הונגשו, או שטרם נמצא הפתרון הטכנולוגי המתאים. אנו ממשיכים במאמצים
        לשפר את נגישות האתר, ככל האפשר, וזאת מתוך אמונה ומחויבות מוסרית לאפשר
        שימוש באתר לכלל האוכלוסייה לרבות אנשים עם מוגבלויות.
      </Typography>
    </Box>
  )
}

export default AccessibilityHe
