import { PaletteColor } from '@mui/material'

interface Props {
  paletteColor: PaletteColor
}

const DineInIcon = ({ paletteColor }: Props) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 41.6667H18.75M18.75 41.6667H25M18.75 41.6667V31.25M18.75 31.25C18.75 31.25 11.4583 27.35 9.375 22.9167C7.125 18.1313 9.375 9.375 9.375 9.375H28.125C28.125 9.375 30.375 18.1313 28.125 22.9167C26.0417 27.35 18.75 31.25 18.75 31.25ZM35.4167 41.6667V25C35.4167 25 40.625 22.9167 40.625 18.75V9.375M35.4167 17.7083V9.375"
        stroke={paletteColor.dark}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DineInIcon
