import { Stack, useTheme } from '@mui/material'

interface Props {
  isFree: boolean
  isSelected: boolean
  isDisabled: boolean
  children: React.ReactNode
  onClick?: () => void
}

const ChoiceWrapper = ({
  isFree,
  isSelected,
  isDisabled,
  children,
  onClick,
}: Props) => {
  const theme = useTheme()

  // eslint-disable-next-line no-nested-ternary
  const borderColor = isFree
    ? '#1E8133'
    : isSelected
      ? theme.palette.primary.main
      : theme.palette.primary.midtone

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={5}
      px={2.5}
      py={1.25}
      borderRadius="30px"
      mb={2.5}
      border={`2px solid ${borderColor}`}
      sx={{
        background: theme.palette.primary.midtone,
        opacity: isDisabled ? 0.5 : 1,
        pointerEvents: isDisabled ? 'none' : 'auto',

        [theme.breakpoints.down('md')]: {
          pl: '10px',
          pr: '10px',
          borderRadius: '15px',
          mb: '12px',
        },
      }}
      onClick={onClick}
    >
      {children}
    </Stack>
  )
}

export default ChoiceWrapper
