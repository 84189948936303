import { useQuery } from '@tanstack/react-query'
import { getIPAddress } from '@/services/ip-address'

const useGetIPAddress = (enabled: boolean) => {
  return useQuery({
    queryKey: ['companyCodes'],
    queryFn: getIPAddress,
    enabled,
  })
}

export default useGetIPAddress
