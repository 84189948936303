import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Stack, Typography, useTheme } from '@mui/material'
import PhoneConfirmationForm, {
  ICodePhone,
} from '@/components/delivery/PhoneConfirmationForm'
import PhoneForm from '@/components/delivery/PhoneForm'
import useSettings from '@/hooks/useSettings'
import MainLayout from '@/layouts/MainLayout'
import useCartStore from '@/store/useCartStore'

interface IPhone {
  phone: string
}

const Delivery = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation('delivery')
  const { data: settings } = useSettings()
  const customer = useCartStore((s) => s.customer)
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false)

  const setCustomer = useCartStore((s) => s.setCustomer)

  const sendCode = () => {
    console.log('sendVerificationCode')
    setIsCodeSent(true)
  }

  const verifyCode = (data: ICodePhone) => {
    console.log('verifyPhone', data)
    navigate('/delivery/address')
  }

  const onSubmit = (data: IPhone) => {
    setCustomer({ ...customer, phone: data.phone })

    if (settings?.deliveryPhoneConfirmation) {
      sendCode()
    } else {
      navigate('/delivery/address')
    }
  }

  return (
    <MainLayout showDeliverySteps={false}>
      <Stack
        direction="column"
        justifyContent="center"
        height="100%"
        p="0 80px"
        sx={{
          [theme.breakpoints.down('md')]: {
            p: '0 40px',
          },
        }}
      >
        <Stack
          direction="column"
          gap={12}
          sx={{
            [theme.breakpoints.down('md')]: {
              gap: 5,
            },
          }}
        >
          <Typography
            variant="h1"
            textAlign="center"
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: 34,
              },
            }}
          >
            {t('deliveryTitle')}
          </Typography>

          <Stack flex="1" justifyContent="center">
            {isCodeSent ? (
              <PhoneConfirmationForm
                onSubmit={(data) => verifyCode(data)}
                onResend={() => sendCode()}
              />
            ) : (
              <PhoneForm onSubmit={(data) => onSubmit(data)} />
            )}
          </Stack>
        </Stack>
      </Stack>
    </MainLayout>
  )
}

export default Delivery
