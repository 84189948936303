const RightPartPizzaIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="42"
      viewBox="0 0 41 42"
      fill="none"
    >
      <path
        d="M20.5 1C23.1264 1 25.7272 1.51732 28.1537 2.52241C30.5802 3.5275 32.785 5.00069 34.6421 6.85786C36.4993 8.71504 37.9725 10.9198 38.9776 13.3463C39.9827 15.7728 40.5 18.3736 40.5 21C40.5 23.6264 39.9827 26.2272 38.9776 28.6537C37.9725 31.0802 36.4993 33.285 34.6421 35.1421C32.785 36.9993 30.5802 38.4725 28.1537 39.4776C25.7272 40.4827 23.1264 41 20.5 41L20.5 21L20.5 1Z"
        fill="#321313"
      />
      <circle
        cx="20.5"
        cy="21"
        r="20"
        transform="rotate(-90 20.5 21)"
        stroke="#321313"
      />
    </svg>
  )
}

export default RightPartPizzaIcon
