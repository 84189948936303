import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Button, useTheme } from '@mui/material'

interface Props {
  children: React.ReactNode
  onClick?: () => void
  to?: string
}

const SecondaryButton = ({ children, onClick, to = '' }: Props) => {
  const theme = useTheme()

  return (
    <Button
      onClick={onClick}
      component={to ? RouterLink : 'button'}
      to={to}
      variant="outlined"
      fullWidth
      sx={{
        p: 2.25,
        borderWidth: '2px',
        borderRadius: '20px',
        fontSize: '36px',
        lineHeight: '1.2',
        fontWeight: 600,
        color: theme.palette.primary.dark,
        ':hover': {
          borderWidth: '2px',
        },
        [theme.breakpoints.down('md')]: {
          p: 0,
          height: 44,
          fontSize: 14,
          borderRadius: '10px',
        },
      }}
    >
      {children}
    </Button>
  )
}

export default SecondaryButton
