interface Props {
  color: string
  width?: number
  height?: number
}

const ManualInputIcon = ({ color, width = 70, height = 70 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3328 42.4999L18.0162 37.4365C16.1439 35.6533 13.1632 35.7889 11.4606 37.7349C9.92185 39.4934 9.96714 42.1321 11.5653 43.8369L28.896 62.323C29.9988 63.4993 31.5365 64.1666 33.1488 64.1666C36.3134 64.1666 41.5162 64.1666 46.6661 64.1666C53.6661 64.1666 58.3328 58.3333 58.3328 52.4999C58.3328 52.4999 58.3328 32.5001 58.3328 27.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.584 29.1667C49.584 29.1667 49.584 28.8017 49.584 27.5C49.584 20.8333 58.334 20.8333 58.334 27.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.834 29.167C40.834 29.167 40.834 26.7705 40.834 24.167C40.834 17.5003 49.584 17.5003 49.584 24.167C49.584 24.8179 49.584 26.8495 49.584 27.5003C49.584 28.8021 49.584 29.167 49.584 29.167"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.084 29.1668C32.084 29.1668 32.084 25.1294 32.084 21.875C32.084 15.2083 40.834 15.2083 40.834 21.875C40.834 21.875 40.834 23.5158 40.834 24.1667C40.834 26.7702 40.834 29.1668 40.834 29.1668"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.334 42.4996V10.208C23.334 7.79175 25.2927 5.83301 27.709 5.83301C30.1252 5.83301 32.084 7.78881 32.084 10.2051C32.084 13.6779 32.084 18.4959 32.084 21.8747C32.084 25.129 32.084 29.1663 32.084 29.1663"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ManualInputIcon
