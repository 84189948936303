import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { getCardNumberFromTerminal } from '@/services/payment'
import AttachCard from './AttachCard'

interface Props {
  onCardRead: (cardNumber: string) => void
  onError: (error: string) => void
}

const ReadCard = ({ onCardRead, onError }: Props) => {
  const { t } = useTranslation('payment')

  useEffect(() => {
    getCardNumberFromTerminal()
      .then(({ status, card }) => {
        if (status) {
          onCardRead(card)
        } else {
          onError(t('errorReadingCard'))
        }
      })
      .catch((err) => {
        console.log(err)
        onError(t('errorReadingCard'))
      })
  }, [])

  return <AttachCard />
}

export default ReadCard
