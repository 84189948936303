import { Typography, useTheme } from '@mui/material'

interface Props {
  price: number
  isSelected: boolean
}

const ChoicePrice = ({ price, isSelected }: Props) => {
  const theme = useTheme()

  return (
    <Typography
      variant="smallBody"
      fontSize="16px"
      fontWeight="600"
      textAlign="center"
      color={
        isSelected ? theme.palette.common.white : theme.palette.primary.main
      }
      sx={{
        [theme.breakpoints.down('md')]: {
          fontSize: '14px',
        },
      }}
    >
      {price} ₪
    </Typography>
  )
}

export default ChoicePrice
