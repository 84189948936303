import { PaletteColor } from '@mui/material'

interface Props {
  paletteColor: PaletteColor
}

const PickupIcon = ({ paletteColor }: Props) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5833 5.25C14.4788 5.25 13.5833 6.14543 13.5833 7.25V14.5833C13.5833 15.1356 14.031 15.5833 14.5833 15.5833C15.1356 15.5833 15.5833 15.1356 15.5833 14.5833V7.25L34.4167 7.25L34.4167 14.5833C34.4167 15.1356 34.8644 15.5833 35.4167 15.5833C35.969 15.5833 36.4167 15.1356 36.4167 14.5833V7.25C36.4167 6.14543 35.5212 5.25 34.4167 5.25H15.5833ZM8.25 17.75C6.59315 17.75 5.25 19.0931 5.25 20.75V41.75C5.25 43.4069 6.59315 44.75 8.25 44.75H41.75C43.4069 44.75 44.75 43.4069 44.75 41.75V20.75C44.75 19.0931 43.4069 17.75 41.75 17.75H8.25ZM7.25 20.75C7.25 20.1977 7.69772 19.75 8.25 19.75H41.75C42.3023 19.75 42.75 20.1977 42.75 20.75V41.75C42.75 42.3023 42.3023 42.75 41.75 42.75H8.25C7.69772 42.75 7.25 42.3023 7.25 41.75V20.75Z"
        fill={paletteColor.dark}
      />
    </svg>
  )
}

export default PickupIcon
