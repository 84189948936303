import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, FormHelperText, Typography, useTheme } from '@mui/material'
import PrimaryButton from '@/components/shared/buttons/PrimaryButton'
import CustomInputControlled from '@/components/shared/form/CustomInputControlled'
import useCartStore from '@/store/useCartStore'

interface Props {
  onSubmit: (data: IFormData) => void
}

interface IFormData {
  phone: string
}

const PhoneForm = ({ onSubmit }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation('delivery')
  const customer = useCartStore((s) => s.customer)

  const [inputNumber, setInputNumber] = useState<string>(customer.phone || '')

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormData>({
    defaultValues: {
      phone: customer.phone,
    },
  })

  useEffect(() => {
    setValue('phone', inputNumber)
  }, [inputNumber, setValue])

  return (
    <Box>
      <Typography
        variant="body"
        textAlign="center"
        mb={7.5}
        sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: '16px',
            mb: 3,
          },
        }}
      >
        {t('enterPhoneNumberText')}
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          mb={7.5}
          sx={{
            [theme.breakpoints.down('md')]: {
              mb: 2,
            },
          }}
        >
          <Typography
            component="label"
            variant="h3"
            textAlign="center"
            display="block"
            mb={2.5}
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: '20px',
                mb: 1,
              },
            }}
          >
            {t('phoneNumber')}
          </Typography>

          <CustomInputControlled
            type="tel"
            name="phone"
            value={inputNumber}
            placeholder="05 XXX XXXXX"
            control={control}
            rules={{
              required: t('phoneIsRequired'),
              pattern: {
                value: /^05\d{8}$/,
                message: t('invalidPhoneNumber'),
              },
            }}
            onChange={(e) => setInputNumber(e.target.value)}
          />

          {errors.phone && (
            <FormHelperText
              error={!!errors.phone}
              sx={{
                fontSize: '24px',
                textAlign: 'center',
                p: 1,
                [theme.breakpoints.down('md')]: {
                  fontSize: '16px',
                },
              }}
            >
              {t('phoneIsRequired')}
            </FormHelperText>
          )}
        </Box>

        <PrimaryButton type="submit" fullWidth size="large">
          {t('confirm')}
        </PrimaryButton>
      </form>
    </Box>
  )
}

export default PhoneForm
