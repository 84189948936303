import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CircularProgress, Stack } from '@mui/material'
import { getPeleCardTransaction } from '@/services/payment'
import useCartStore from '@/store/useCartStore'
import usePaymentErrorStore from '@/store/usePaymentErrorStore'
import { usePaymentsList } from '@/utils/consts'

const PaymentPeleCardSuccess = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const pelecardTransactionId = params.get('PelecardTransactionId')

  const setPaymentResult = useCartStore((s) => s.setPaymentResult)
  const getCartTotalPrice = useCartStore((s) => s.getCartTotalPrice)
  const throwError = usePaymentErrorStore((s) => s.throwError)
  const price = getCartTotalPrice()

  const PAYMENTS_LIST = usePaymentsList()

  useEffect(() => {
    if (pelecardTransactionId) {
      getPeleCardTransaction(pelecardTransactionId).then((data) => {
        if (data.status) {
          setPaymentResult({
            ...data,
            id: PAYMENTS_LIST.CREDIT_CARD.id,
            amount: price,
          })

          navigate('/payment/success')
        } else {
          throwError('cashOnCloud', data.message)
        }
      })
    }
  }, [pelecardTransactionId])

  return (
    <Stack
      height="100%"
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress color="primary" size="100px" />
    </Stack>
  )
}

export default PaymentPeleCardSuccess
