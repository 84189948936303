import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Input, Stack, Typography, useTheme } from '@mui/material'
import { useMapsLibrary } from '@vis.gl/react-google-maps'
import useCartStore from '@/store/useCartStore'
import IAddressForm from '@/types/AddressForm'
import CustomInputControlled from '../shared/form/CustomInputControlled'

interface PlaceAutocompleteProps {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void
}

const PlaceAutocomplete = ({ onPlaceSelect }: PlaceAutocompleteProps) => {
  const theme = useTheme()
  const { t } = useTranslation('delivery')
  const customer = useCartStore((s) => s.customer)

  const [placeAutocomplete, setPlaceAutocomplete] =
    useState<google.maps.places.Autocomplete | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [inputAddress, setInputAddress] = useState<string>('')

  const places = useMapsLibrary('places')

  useEffect(() => {
    setInputAddress(customer.address?.address || '')

    if (!places || !inputRef.current) return

    const options = {
      fields: ['geometry', 'name', 'formatted_address'],
    }

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options))
  }, [places])

  useEffect(() => {
    if (!placeAutocomplete) return

    placeAutocomplete.addListener('place_changed', () => {
      const place = placeAutocomplete.getPlace()

      setInputAddress(place.name || '')
      onPlaceSelect(place)
    })
  }, [onPlaceSelect, placeAutocomplete])

  return (
    <div className="autocomplete-container">
      <Input
        inputRef={inputRef}
        value={inputAddress}
        disableUnderline
        fullWidth
        placeholder={t('typeOptions')}
        sx={{
          flex: 1,
          px: 5,
          py: 3.25,
          borderRadius: '30px',
          border: `2px solid ${theme.palette.primary.main}`,
          background: theme.palette.common.white,
          [theme.breakpoints.down('md')]: {
            p: '10px',
            borderRadius: '10px',
          },

          '& .MuiInput-input': {
            textAlign: 'center',
            fontSize: '48px',
            lineHeight: 1.2,
            fontWeight: 600,
            color: theme.palette.primary.dark,
            [theme.breakpoints.down('md')]: {
              fontSize: '16px',
              p: 0,
            },
          },

          '& .MuiInput-input::placeholder': {
            color: theme.palette.primary.midtone,
            opacity: 1,
          },
        }}
        onChange={(e) => setInputAddress(e.target.value)}
      />
    </div>
  )
}

const AddressForm = ({ onPlaceSelect }: PlaceAutocompleteProps) => {
  const theme = useTheme()
  const { t } = useTranslation('delivery')
  const [inputEntrance, setInputEntrance] = useState<string>('')
  const [inputFloor, setInputFloor] = useState<string>('')
  const [inputApartment, setInputApartment] = useState<string>('')
  const customer = useCartStore((s) => s.customer)
  const setCustomer = useCartStore((s) => s.setCustomer)
  const navigate = useNavigate()

  const { control, handleSubmit } = useForm<IAddressForm>({
    defaultValues: {
      entrance: '',
      floor: '',
      apartment: '',
    },
  })

  const onSubmit = (data: IAddressForm) => {
    setCustomer({
      ...customer,
      address: {
        ...customer.address,
        entrance: data.entrance,
        floor: data.floor,
        apartment: data.apartment,
      },
    })
    navigate('/delivery/address/details')
  }

  return (
    <Box>
      <Typography
        variant="h3"
        textAlign="center"
        mb={2.5}
        sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: 20,
            mb: 1,
          },
        }}
      >
        {t('address')}
      </Typography>

      <PlaceAutocomplete onPlaceSelect={onPlaceSelect} />

      {customer.address?.address && (
        <form id="address-form" onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction="row"
            alignItems="center"
            gap={5}
            mt={7.5}
            sx={{
              [theme.breakpoints.down('md')]: {
                mt: 3,
              },
            }}
          >
            <Box flex="1">
              <Typography
                variant="h3"
                textAlign="center"
                mb={2.5}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: 20,
                    mb: 1,
                  },
                }}
              >
                {t('entrance')}
              </Typography>

              <CustomInputControlled
                type="text"
                placeholder="XX"
                name="entrance"
                value={inputEntrance}
                control={control}
                rules={{
                  required: '',
                }}
                onChange={(e) => setInputEntrance(e.target.value)}
              />
            </Box>

            <Box flex="1">
              <Typography
                variant="h3"
                textAlign="center"
                mb={2.5}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: 20,
                    mb: 1,
                  },
                }}
              >
                {t('floor')}
              </Typography>

              <CustomInputControlled
                type="text"
                placeholder="XX"
                name="floor"
                value={inputFloor}
                control={control}
                rules={{
                  required: '',
                }}
                onChange={(e) => setInputFloor(e.target.value)}
              />
            </Box>

            <Box flex="1">
              <Typography
                variant="h3"
                textAlign="center"
                mb={2.5}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: 20,
                    mb: 1,
                  },
                }}
              >
                {t('apartment')}
              </Typography>

              <CustomInputControlled
                type="text"
                placeholder="XX"
                name="apartment"
                value={inputApartment}
                control={control}
                rules={{
                  required: '',
                }}
                onChange={(e) => setInputApartment(e.target.value)}
              />
            </Box>
          </Stack>
        </form>
      )}
    </Box>
  )
}

export default AddressForm
