import { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, FormHelperText, useTheme } from '@mui/material'
import IUserInfoForm from '@/types/UserInfoForm'
import useSettings from '../../hooks/useSettings'
import useCartStore from '../../store/useCartStore'
import PrimaryButton from '../shared/buttons/PrimaryButton'
import CustomInputControlled from '../shared/form/CustomInputControlled'

interface Props {
  onSubmit: SubmitHandler<IUserInfoForm>
  inputName: string
  inputNumber: string
  inputEmail: string
  setFocusedInput: (value: boolean) => void
  setInputName: (e: string) => void
  setInputNumber: (e: string) => void
  setInputEmail: (e: string) => void
}

const UserInfoForm = ({
  onSubmit,
  inputName,
  inputNumber,
  inputEmail,
  setFocusedInput,
  setInputName,
  setInputNumber,
  setInputEmail,
}: Props) => {
  const { t } = useTranslation('cart')
  const customer = useCartStore((s) => s.customer)
  const theme = useTheme()

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IUserInfoForm>({
    defaultValues: {
      name: customer.name,
      phone: customer.phone,
      email: customer.email,
    },
  })

  const { data: settings } = useSettings()
  const showPhone = settings?.phoneRequired ?? false
  const showEmail = settings?.emailRequired ?? false

  useEffect(() => {
    setValue('name', inputName)
    setValue('phone', inputNumber)
    setValue('email', inputEmail)
  }, [inputName, inputNumber, setValue])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        mb={7.5}
        sx={{
          [theme.breakpoints.down('md')]: {
            mb: 2,
          },
        }}
      >
        <CustomInputControlled
          type="text"
          name="name"
          value={inputName}
          placeholder={t('name')}
          control={control}
          rules={{ required: true }}
          onFocus={() => setFocusedInput(true)}
          onChange={(e) => setInputName(e.target.value)}
        />
        {errors.name?.type === 'required' && (
          <FormHelperText
            error={!!errors.name}
            sx={{
              fontSize: '24px',
              textAlign: 'center',
              p: 1,
              [theme.breakpoints.down('md')]: {
                fontSize: '16px',
              },
            }}
          >
            {t('nameIsRequired')}
          </FormHelperText>
        )}
      </Box>

      {showPhone && (
        <Box
          mb={7.5}
          sx={{
            [theme.breakpoints.down('md')]: {
              mb: 2,
            },
          }}
        >
          <CustomInputControlled
            type="tel"
            name="phone"
            value={inputNumber}
            placeholder={t('phone')}
            control={control}
            rules={{
              required: t('phoneIsRequired'),
              pattern: {
                value: /^05\d{8}$/,
                message: t('invalidPhoneNumber'),
              },
            }}
            onFocus={() => setFocusedInput(false)}
            onChange={(e) => setInputNumber(e.target.value)}
          />
          {errors.phone && (
            <FormHelperText
              error={!!errors.phone}
              sx={{
                fontSize: '24px',
                textAlign: 'center',
                p: 1,
                [theme.breakpoints.down('md')]: {
                  fontSize: '16px',
                },
              }}
            >
              {t('phoneIsRequired')}
            </FormHelperText>
          )}
        </Box>
      )}

      {showEmail && (
        <Box
          mb={7.5}
          sx={{
            [theme.breakpoints.down('md')]: {
              mb: 2,
            },
          }}
        >
          <CustomInputControlled
            type="email"
            name="email"
            value={inputEmail}
            placeholder={t('email')}
            control={control}
            rules={{
              required: t('emailIsRequired'),
            }}
            onFocus={() => setFocusedInput(false)}
            onChange={(e) => setInputEmail(e.target.value)}
          />
          {errors.email && (
            <FormHelperText
              error={!!errors.email}
              sx={{
                fontSize: '24px',
                textAlign: 'center',
                p: 1,
                [theme.breakpoints.down('md')]: {
                  fontSize: '16px',
                },
              }}
            >
              {t('emailIsRequired')}
            </FormHelperText>
          )}
        </Box>
      )}

      <PrimaryButton type="submit" fullWidth size="large">
        {t('confirmAndProceed')}
      </PrimaryButton>
    </form>
  )
}

export default UserInfoForm
