import { useTheme } from '@mui/material'

const TermsOfServiceIcon = () => {
  const theme = useTheme()

  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 4H7C5.89543 4 5 4.89543 5 6V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V6C19 4.89543 18.1046 4 17 4Z"
        stroke={theme.palette.primary.dark}
      />
      <path
        d="M9 9H15M9 13H15M9 17H13"
        stroke={theme.palette.primary.dark}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default TermsOfServiceIcon
