import { Typography, useTheme } from '@mui/material'

interface Props {
  price: number
}

const ChoicePrice = ({ price }: Props) => {
  const theme = useTheme()

  if (price === 0) return null

  return (
    <Typography
      variant="body"
      sx={{
        [theme.breakpoints.down('md')]: {
          fontSize: '14px',
          pr: 2,
        },
      }}
    >
      {`+${price} ₪`}
    </Typography>
  )
}

export default ChoicePrice
