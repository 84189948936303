import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import DeliverySteps from '@/components/delivery/DeliverySteps'
import PaymentErrorModal from '@/components/payment/PaymentErrorModal'
import TotalPriceBox from '@/components/payment/TotalPriceBox'
import BackButton from '@/components/shared/BackButton'
import InactivityDetector from '@/components/shared/InactivityDetector'
import ResetAppBanner from '@/components/shared/ResetAppBanner'
import useCartStore from '@/store/useCartStore'

interface Props {
  children: React.ReactNode
  title: string
  backTo: string
  subTitle?: string
  allowBack?: boolean
  showDeliverySteps?: boolean
  deliveryStep?: number
}

const PaymentLayout = ({
  children,
  title,
  backTo,
  subTitle,
  allowBack = true,
  showDeliverySteps = false,
  deliveryStep = 3,
}: Props) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const getCartTotalPrice = useCartStore((s) => s.getCartTotalPrice)
  const price = getCartTotalPrice()

  const orderType = useCartStore((s) => s.orderType)
  const isDelivery = orderType === 'DELIVERY'

  useEffect(() => {
    if (!orderType) {
      navigate('/where-to-eat')
    }

    if (price === 0) {
      navigate('/menu')
    }
  }, [orderType])

  return (
    <Stack direction="column" height="100vh">
      <Stack
        direction="column"
        height="100%"
        overflow="auto"
        pt={10}
        spacing={7.5}
        sx={{
          [theme.breakpoints.down('md')]: {
            pt: 1,
          },
        }}
      >
        {isDelivery && showDeliverySteps && (
          <DeliverySteps deliveryStep={deliveryStep} />
        )}

        <Stack direction="column" alignItems="center">
          {allowBack && (
            <>
              <Box
                mb={5}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    mb: 2,
                  },
                }}
              >
                <BackButton to={backTo} />
              </Box>

              <Box
                sx={{
                  width: '1px',
                  height: '100px',
                  background: theme.palette.primary.dark,
                  mb: 5,
                  [theme.breakpoints.down('md')]: {
                    display: 'none',
                  },
                }}
              />
            </>
          )}

          <Typography
            variant="h1"
            textAlign="center"
            mb={7.5}
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: '34px',
                mb: 2,
              },
            }}
          >
            {title}
          </Typography>

          <TotalPriceBox price={price} />

          {subTitle && (
            <Stack direction="row">
              <Typography
                variant="body"
                textAlign="center"
                mt={7.5}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: '16px',
                    m: 2,
                  },
                }}
              >
                {subTitle}
              </Typography>
            </Stack>
          )}
        </Stack>

        <Box
          flex="1"
          minHeight="500px"
          overflow="auto"
          pb={7.5}
          sx={{
            [theme.breakpoints.down('md')]: {
              m: '8px 8px 0 8px !important',
              pb: 0,
              minHeight: 400,
            },
          }}
        >
          {children}
        </Box>
      </Stack>

      <PaymentErrorModal />

      {allowBack && <ResetAppBanner />}

      <InactivityDetector />
    </Stack>
  )
}

export default PaymentLayout
