import { Box, useTheme } from '@mui/material'
import useSettings from '@/hooks/useSettings'

interface Props {
  url: string
}

const ChoiceImage = ({ url }: Props) => {
  const theme = useTheme()
  const { data: settings } = useSettings()

  return (
    <Box
      component="img"
      src={url ?? settings?.companyLogoUrl}
      width="104px"
      height="95px"
      sx={{
        objectFit: 'cover',

        [theme.breakpoints.down('md')]: {
          width: 50,
          height: 50,
        },
      }}
    />
  )
}

export default ChoiceImage
