import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface PaletteColor {
    midtone?: string
  }

  interface SimplePaletteColorOptions {
    midtone?: string
  }

  interface TypographyVariants {
    body: React.CSSProperties
    smallBody: React.CSSProperties
    summary: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    body?: React.CSSProperties
    smallBody?: React.CSSProperties
    summary?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body: true
    smallBody: true
    summary: true
  }
}

const PALETTES = {
  ORANGE: {
    light: '#F9F5F0',
    midtone: '#F2EAD3',
    main: '#F4991A',
    dark: '#321313',
    contrastText: '#fff',
  },
  TURQUOISE: {
    light: '#F0F8F9',
    midtone: '#D3EFF2',
    main: '#4CB8C4',
    dark: '#132F32',
    contrastText: '#fff',
  },
  BLUE: {
    light: '#F0F5F9',
    midtone: '#D3E3F2',
    main: '#1A87F4',
    dark: '#132332',
    contrastText: '#fff',
  },
}

export const getTheme = (paletteName = 'ORANGE') => {
  const selectedColorPalette = PALETTES[paletteName as keyof typeof PALETTES]

  return createTheme({
    // direction: i18n.dir(),
    typography: {
      fontFamily: ['Inter', 'sans-serif'].join(','),
      body: {
        fontSize: '24px',
        lineHeight: '1.2',
        fontWeight: 400,
        color: selectedColorPalette.dark,
      },
      smallBody: {
        fontSize: '20px',
        lineHeight: '1.2',
        fontWeight: 400,
        color: selectedColorPalette.dark,
      },
      h1: {
        fontSize: '64px',
        lineHeight: '1.2',
        fontWeight: 600,
        color: selectedColorPalette.dark,
      },
      h2: {
        fontSize: '48px',
        lineHeight: '1.2',
        fontWeight: 600,
        color: selectedColorPalette.dark,
      },
      h3: {
        fontSize: '46px',
        lineHeight: '1.2',
        fontWeight: 600,
        color: selectedColorPalette.dark,
      },
      summary: {
        fontSize: '40px',
        lineHeight: '1.2',
        fontWeight: 800,
        color: selectedColorPalette.main,
      },
    },
    palette: {
      primary: selectedColorPalette,
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            body: 'p',
            smallBody: 'p',
            h2: 'p',
            h3: 'p',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            minWidth: 0,
            textTransform: 'none',
          },
        },
      },
    },
  })
}

export default getTheme
