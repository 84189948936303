const CashIcon = ({ color }: { color: string }) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.082 24.7886H17.7056"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M21.1641 24.7886H24.7876"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M28.3291 24.7886H31.9526"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M35.4111 24.7886H39.0347"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M42.5762 24.7886H46.1997"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M49.6582 24.7886H53.2817"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M14.082 53.0356H17.7056"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M21.1641 53.0356H24.7876"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M28.3291 53.0356H31.9526"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M35.4111 53.0356H39.0347"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M42.5762 53.0356H46.1997"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M49.6582 53.0356H53.2817"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M51.964 38.9532C51.964 39.8591 51.2229 40.6003 50.317 40.6003C49.4111 40.6003 48.6699 39.8591 48.6699 38.9532C48.6699 38.0473 49.4111 37.3062 50.317 37.3062C51.2229 37.3062 51.964 38.0473 51.964 38.9532Z"
        fill="#321313"
      />
      <path
        d="M58.3057 47.8475V54.6004C58.3057 56.9063 56.4115 58.8004 54.1057 58.8004H9.05859V19.0239H54.1057C56.4115 19.0239 58.3057 20.918 58.3057 23.2239V34.3416"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M59.4583 43.1533H44.6348V34.6709H59.4583C60.1171 34.6709 60.6936 35.2474 60.6936 35.9062V41.8356C60.6936 42.5768 60.1171 43.1533 59.4583 43.1533Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M54.4351 14.9061V10.7061H12.7645C10.7057 10.7061 9.05859 12.3531 9.05859 14.4119V19.1061"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M14.082 14.9062H49.5761"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  )
}

export default CashIcon
