import { Typography, useTheme } from '@mui/material'

interface Props {
  title: string
  isSelected: boolean
}

const ChoiceTitle = ({ title, isSelected }: Props) => {
  const theme = useTheme()

  return (
    <Typography
      variant="smallBody"
      fontWeight="600"
      textAlign="center"
      color={
        isSelected ? theme.palette.common.white : theme.palette.primary.dark
      }
      mb={1}
      sx={{
        [theme.breakpoints.down('md')]: {
          fontSize: '14px',
          height: '31px',
        },
      }}
    >
      {title}
    </Typography>
  )
}

export default ChoiceTitle
