import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface ISettingsStore {
  currentLanguage: string
  setCurrentLanguage: (language: string) => void
  clearSettingsStore: () => void
}

const useSettingsStore = create<ISettingsStore>()(
  persist(
    (set) => ({
      currentLanguage: '',
      setCurrentLanguage: (language) => set({ currentLanguage: language }),
      clearSettingsStore: () => set({ currentLanguage: '' }),
    }),
    {
      name: 'settings-store',
    },
  ),
)

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('SettingsStore', useSettingsStore)
}

export default useSettingsStore
