import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import BigDownArrowIcon from '../icons/BigDownArrowIcon'

const AttachCard = () => {
  const theme = useTheme()
  const { t } = useTranslation('payment')

  return (
    <Stack
      direction="column"
      alignItems="center"
      spacing={5}
      sx={{
        [theme.breakpoints.down('md')]: {
          mt: 2,
        },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: '18px',
          },
        }}
      >
        {t('attachCardLong')}
      </Typography>

      <Box
        width="1px"
        height="100px"
        sx={{
          background: theme.palette.primary.dark,
        }}
      />

      <Box
        p={3}
        borderRadius="20px"
        border={`2px solid ${theme.palette.primary.main}`}
        sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: '18px',
            borderRadius: '15px',
            p: 1,
            svg: {
              width: 30,
            },
          },
        }}
      >
        <BigDownArrowIcon color={theme.palette.primary.dark} />
      </Box>
    </Stack>
  )
}

export default AttachCard
