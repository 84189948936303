import { useSignIn } from 'react-auth-kit'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Button, FormHelperText, Stack, useTheme } from '@mui/material'
import CustomInputControlled from '@/components/shared/form/CustomInputControlled'
import { logIn } from '@/services/auth'
import useCartStore from '@/store/useCartStore'
import useCategoryStore from '@/store/useCategoryStore'
import decodeToken from '@/utils/decode-jwt'

type FormData = {
  key: string
}

const Login = () => {
  const theme = useTheme()
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FormData>({ defaultValues: { key: '' } })

  const signIn = useSignIn()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const searchKey = searchParams.get('key')

  const clearCartStore = useCartStore((s) => s.clearCartStore)
  const clearCategoryStore = useCategoryStore((s) => s.clearCategoryStore)
  const clearScheduleStore = useCartStore((s) => s.clearSchedule)

  const logInWithKey = (key: string) => {
    clearCartStore()
    clearCategoryStore()
    clearScheduleStore()

    logIn(key)
      .then((res) => {
        if (res.accessToken) {
          const { exp = 0 } = decodeToken(res.accessToken) || {}
          const { exp: expRefresh = 0 } = decodeToken(res.refreshToken) || {}

          const result = signIn({
            token: res.accessToken,
            expiresIn: expRefresh / 1000 / 60, // in minutes
            tokenType: 'Bearer',
            authState: {
              name: 'John Doe',
              refreshToken: res.refreshToken,
              tokenExpireIn: new Date(Date.now() + exp),
            },
          })

          if (result) {
            navigate('/')
          }
        }
      })
      .catch((err) => {
        setError('key', { message: err.response.data })
      })
  }

  const onSubmit = ({ key }: FormData) => {
    logInWithKey(key)
  }

  if (searchKey) {
    logInWithKey(searchKey)
    return null
  }

  return (
    <Stack
      direction="column"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      sx={{
        [theme.breakpoints.down('md')]: {
          width: '90%',
          margin: '0 auto',
        },
      }}
    >
      <Box
        component="form"
        width="100%"
        maxWidth="700px"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box>
          <CustomInputControlled
            type="text"
            name="key"
            placeholder="Secret key"
            control={control}
            rules={{ required: 'Please enter your secret key' }}
          />
          <FormHelperText
            error
            sx={{
              fontSize: '24px',
              minHeight: '40px',
              mb: 1,
              [theme.breakpoints.down('md')]: {
                fontSize: '16px',
                textAlign: 'center',
                minHeight: '32px',
                mb: 0,
              },
            }}
          >
            {errors.key?.message}
          </FormHelperText>
        </Box>

        <Button
          type="submit"
          variant="contained"
          disableElevation
          fullWidth
          sx={{
            px: 5,
            py: 3.75,
            borderRadius: '30px',
            fontSize: '48px',
            lineHeight: '1.2',
            fontWeight: 600,
            [theme.breakpoints.down('md')]: {
              p: 1.6,
              borderRadius: '15px',
              fontSize: '24px',
            },
          }}
        >
          Log In
        </Button>
      </Box>
    </Stack>
  )
}

export default Login
