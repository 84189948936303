import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Fade, Modal, Stack, Typography, useTheme } from '@mui/material'
import usePaymentErrorStore from '../../store/usePaymentErrorStore'
import PrimaryButton from '../shared/buttons/PrimaryButton'
import SecondaryButton from '../shared/buttons/SecondaryButton'

const PaymentErrorModal = () => {
  const theme = useTheme()
  const { t } = useTranslation('payment')
  const navigate = useNavigate()
  const open = usePaymentErrorStore((s) => s.modalOpen)
  const errorMessage = usePaymentErrorStore((s) => s.errorMessage)
  const handleClose = usePaymentErrorStore((s) => s.clearError)

  const retry = () => {
    handleClose()
  }

  const cancel = () => {
    handleClose()
    navigate('/payment')
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Fade in={open}>
        <Box
          width="100%"
          maxWidth="1000px"
          position="absolute"
          top="50%"
          left="50%"
          borderRadius="100px"
          pt={13}
          px={7.5}
          pb={7.5}
          sx={{
            transform: 'translate(-50%, -50%)',
            background: theme.palette.primary.light,
            [theme.breakpoints.down('md')]: {
              borderRadius: '30px',
              p: 2,
            },
          }}
        >
          <Box maxWidth="700px" mx="auto">
            <Typography
              fontSize="40px"
              lineHeight="1.2"
              color="primary.dark"
              textAlign="center"
              mb={7.5}
              sx={{
                [theme.breakpoints.down('md')]: {
                  fontSize: '18px',
                  mb: 3,
                },
              }}
            >
              {errorMessage}
            </Typography>

            <Stack
              direction="column"
              gap={5.2}
              sx={{
                [theme.breakpoints.down('md')]: {
                  gap: 2,
                },
              }}
            >
              <SecondaryButton onClick={cancel}>
                {t('goToOptions')}
              </SecondaryButton>
              <PrimaryButton fullWidth onClick={retry}>
                {t('Retry')}
              </PrimaryButton>
            </Stack>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}

export default PaymentErrorModal
