import { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { useIsRestoring } from '@tanstack/react-query'
import useAuth from '@/hooks/useAuth'
import useSettings from '@/hooks/useSettings'
import router from '@/routing/router'
import { getChanelId } from '@/services/auth'
import { getTheme } from '@/services/theme'
import { initializateTranslation } from '@/services/translation'
import useSettingsStore from '@/store/useSettinsStore'
import './App.css'
// import { useTranslation } from 'react-i18next'
// import { CacheProvider } from '@emotion/react'
// import { prefixer } from 'stylis'
// import rtlPlugin from 'stylis-plugin-rtl'
// import createCache from '@emotion/cache'

const App = () => {
  // const { i18n } = useTranslation()

  // const cacheRtl = createCache({
  //   key: 'muirtl',
  //   stylisPlugins: [prefixer, rtlPlugin],
  // })

  // const emptyCache = createCache({
  //   key: 'meaningless-key',
  // })
  const channelId = getChanelId()

  const { data: settings, isLoading } = useSettings({ enabled: !!channelId })
  const isRestoring = useIsRestoring()
  const currentLanguage = useSettingsStore((s) => s.currentLanguage)
  useAuth()

  useEffect(() => {
    initializateTranslation(
      currentLanguage || settings?.defaultLanguage || 'en',
    )
  }, [currentLanguage, settings])

  if (isLoading || isRestoring) return null

  const theme = getTheme(settings?.colorTheme)

  return (
    // <CacheProvider value={i18n.dir() === 'rtl' ? cacheRtl : emptyCache}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
    // </CacheProvider>
  )
}

export default App
