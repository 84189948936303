import { axiosInstance } from '@/services/api-client'
import type IProduct from '@/types/Product'

interface IOrderTotals {
  subtotal: number // ?
  total: number // cart.totalPrice
  tax: number // ?
  discount: number // ?
}

interface IOrderPayment {
  id: number
  amount: number
  transactionId: string
}

interface IOrderCustomer {
  name: string // cartStore.customer.name
  phone: string // cartStore.customer.phone
  items: IProduct[] // cartStore.cart
}

export interface IOrder {
  currency: string // settings
  totals: IOrderTotals
  type: string | null // Eat in | Take out
  sourceType: 'KIOSK' | 'PORTAL'
  payments: IOrderPayment[]
  customers: IOrderCustomer[]
}

export const createOrder = async (order: IOrder) => {
  const result = await axiosInstance.post<IOrder>('/orders', order)

  return result.data
}

export const saveOrderLocally = (order: IOrder) => {
  const orders = JSON.parse(localStorage.getItem('orders') || '[]')
  orders.push(order)
  localStorage.setItem('orders', JSON.stringify(orders))
}
