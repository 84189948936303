import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { payWithCashOnCloud } from '@/services/payment'
import { usePaymentsList } from '@/utils/consts'
import useCartStore from '../../../store/useCartStore'
import usePaymentErrorStore from '../../../store/usePaymentErrorStore'

const CashOnCloud = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('payment')

  const setPaymentResult = useCartStore((s) => s.setPaymentResult)
  const getCartTotalPrice = useCartStore((s) => s.getCartTotalPrice)
  const throwError = usePaymentErrorStore((s) => s.throwError)
  const price = getCartTotalPrice()

  const PAYMENTS_LIST = usePaymentsList()

  useEffect(() => {
    payWithCashOnCloud(price * 100)
      .then(({ status, transactionId, message }) => {
        if (status) {
          setPaymentResult({
            id: PAYMENTS_LIST.CASH_ON_CLOUD.id,
            amount: price,
            transactionId,
          })

          navigate('/payment/success')
        } else {
          throwError('cashOnCloud', message)
        }
      })
      .catch((err) => {
        console.log(err.message)
        throwError('cashOnCloud', t('generalError'))
      })
  }, [])

  return null
}

export default CashOnCloud
