import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { CanceledError } from 'axios'
import { throttle } from 'lodash'
import {
  cancelCashMachinePayment,
  getCurrentAmountFromCashMachine,
  payWithCashMachine,
} from '@/services/payment'
import useCartStore from '@/store/useCartStore'
import usePaymentErrorStore from '@/store/usePaymentErrorStore'
import { usePaymentsList } from '@/utils/consts'
import BigDownArrowIcon from '../icons/BigDownArrowIcon'
import SecondaryButton from '../shared/buttons/SecondaryButton'

const PayWithCash = () => {
  const theme = useTheme()
  const { t } = useTranslation('payment')
  const navigate = useNavigate()
  const setPaymentResult = useCartStore((s) => s.setPaymentResult)
  const [payedSum, setPayedSum] = useState(0)
  const throwError = usePaymentErrorStore((s) => s.throwError)
  const getCartTotalPrice = useCartStore((s) => s.getCartTotalPrice)
  const price = getCartTotalPrice()
  const [isLoading, setIsLoading] = useState(false)
  const [changeAmount, setChangeAmount] = useState(0)

  const PAYMENTS_LIST = usePaymentsList()

  let waitingForPayment: boolean = false
  let getCurrentAmountErrors = 0

  let timer: NodeJS.Timeout | null = null

  const getCurrentAmount = (controller: AbortController) => {
    getCurrentAmountFromCashMachine(controller)
      .then((res) => {
        const data = JSON.parse(res.data.GetCurrentAmountResult)

        if (data.Status === 0) {
          const sum = data.CurrentAmount / 100
          setPayedSum(sum)

          if (sum < price && waitingForPayment) {
            timer = setTimeout(() => {
              getCurrentAmount(controller)
            }, 500)
          }
        } else {
          getCurrentAmountErrors += 1
          if (getCurrentAmountErrors > 2) {
            controller.abort()

            throwError(
              'cash',
              `${res.data.GetCurrentAmountResult.StatusDesc} (${res.data.GetCurrentAmountResult.Status})`,
            )
          }
        }
      })
      .catch((err) => {
        if (err instanceof CanceledError) return

        getCurrentAmountErrors += 1

        if (getCurrentAmountErrors > 2) {
          controller.abort()
          console.log(err)
          throwError('cash', t('generalError'))
        }
      })
  }

  function cancelPayment() {
    waitingForPayment = false
    setIsLoading(true)
    clearTimeout(timer!)
    cancelCashMachinePayment()
  }

  const throttleGetCurrentAmount = throttle(getCurrentAmount, 500)

  useEffect(() => {
    console.log('payWithCashMachine')
    const controller = new AbortController()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    waitingForPayment = true

    payWithCashMachine(price * 100, controller)
      .then((res) => {
        waitingForPayment = false

        const responseData = JSON.parse(res.data)

        if (responseData.Status === 0) {
          setPaymentResult({
            id: PAYMENTS_LIST.BALAMUT.id,
            amount: price,
            transactionId: '0000000',
          })

          const change = responseData.DispenseNotes + responseData.DispenseCoins

          if (change > 0) {
            setChangeAmount(change / 100)

            setTimeout(() => {
              navigate('/payment/success')
            }, 4000)
          } else {
            navigate('/payment/success')
          }
        } else if (responseData.Status === 32) {
          navigate('/payment')
        } else {
          controller.abort()
          throwError(
            'cash',
            `${responseData.StatusDesc} (${responseData.Status})`,
          )
        }
      })
      .catch((err) => {
        waitingForPayment = false
        controller.abort()

        if (err instanceof CanceledError) return

        console.log(err)
        throwError('cash', t('generalError'))
      })

    throttleGetCurrentAmount(controller)

    return () => {
      clearTimeout(timer!)
    }
  }, [])

  if (isLoading)
    return (
      <Stack
        height="100%"
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="primary" size="100px" />
      </Stack>
    )

  return (
    <>
      <Box flex="1" width="100%" px={10} py={20} maxWidth="945px" mx="auto">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={7.5}
          borderRadius="30px"
          border={`2px solid ${theme.palette.primary.light}`}
          px={5}
          py={3.25}
          bgcolor={theme.palette.primary.light}
        >
          <Typography fontSize="36px" color="primary.dark" fontWeight="600">
            {t('totalSum')}
          </Typography>
          <Typography fontSize="36px" color="primary.dark" fontWeight="600">
            {price} ₪
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={7.5}
          borderRadius="30px"
          border={`2px solid ${theme.palette.primary.dark}`}
          px={5}
          py={3.25}
        >
          <Typography fontSize="36px" color="primary.dark">
            {t('payedSum')}
          </Typography>
          <Typography fontSize="36px" color="primary.main" fontWeight="600">
            {payedSum} ₪
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={30}
          borderRadius="30px"
          border={`2px solid ${theme.palette.primary.dark}`}
          px={5}
          py={3.25}
        >
          <Typography fontSize="36px" color="primary.dark">
            {t('remainingSum')}
          </Typography>
          <Typography fontSize="36px" color="primary.main" fontWeight="600">
            {payedSum >= price ? 0 : price - payedSum} ₪
          </Typography>
        </Stack>

        {changeAmount > 0 ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            borderRadius="30px"
            border={`2px solid ${theme.palette.primary.dark}`}
            px={5}
            py={3.25}
          >
            <Typography fontSize="36px" color="primary.dark">
              {t('changeAmount')}
            </Typography>
            <Typography fontSize="36px" color="primary.main" fontWeight="600">
              {changeAmount} ₪
            </Typography>
          </Stack>
        ) : (
          <SecondaryButton onClick={() => cancelPayment()}>
            {t('cancelPayment')}
          </SecondaryButton>
        )}
      </Box>

      <Stack
        direction="column"
        alignItems="center"
        spacing={5}
        sx={{
          [theme.breakpoints.down('md')]: {
            mt: 2,
          },
        }}
      >
        <Typography
          variant="h2"
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '18px',
            },
          }}
        >
          {t('replenishCashMachine')}
        </Typography>

        <Box
          width="1px"
          height="100px"
          sx={{
            background: theme.palette.primary.dark,
          }}
        />

        <Box
          p={3}
          borderRadius="20px"
          border={`2px solid ${theme.palette.primary.main}`}
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '18px',
              borderRadius: '15px',
              p: 1,
              svg: {
                width: 30,
              },
            },
          }}
        >
          <BigDownArrowIcon color={theme.palette.primary.dark} />
        </Box>
      </Stack>
    </>
  )
}

export default PayWithCash
