import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Fade, Modal, Stack, Typography, useTheme } from '@mui/material'
import PrimaryButton from '@/components/shared/buttons/PrimaryButton'
import { useProcessTranslationObject } from '@/services/translation'
import type Product from '@/types/Product'

interface Props {
  product: Product
  onRemove: (uniqueId: string) => void
  onClickDelete?: () => void
}

const CartCard = ({ product, onRemove, onClickDelete }: Props) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation('product')
  const { processTranslationObject } = useProcessTranslationObject()

  const [open, setOpen] = useState<boolean>(false)

  const optionsPrice = product.options.reduce(
    (acc1, option) =>
      acc1 +
      option.choices.reduce(
        (acc2, choice) =>
          acc2 + choice.price * (choice.quantity - choice.freeQuantity),
        0,
      ),
    0,
  )

  const handleOpen = () => setOpen(true)

  const handleClose = () => setOpen(false)

  const handleRemove = () => {
    onRemove(product.uniqueId)
    if (onClickDelete) {
      onClickDelete()
    }
  }

  return (
    <Box
      display="inline-block"
      width="216px"
      pr={4.5}
      pt={2}
      position="relative"
      sx={{
        [theme.breakpoints.down('md')]: {
          width: '100%',
          pr: '4px',
        },
      }}
    >
      <Box
        borderRadius="30px"
        overflow="hidden"
        sx={{ background: theme.palette.common.white }}
        onClick={() => navigate(`/product/${product.uniqueId}/edit`)}
      >
        <Box
          component="img"
          src={product?.imageUrl}
          width="100%"
          height="120px"
          borderRadius="0 0 10px 10px"
          sx={{
            objectFit: 'cover',
            [theme.breakpoints.down('md')]: {
              height: 125,
            },
          }}
        />

        <Box p={0.5}>
          <Typography
            variant="smallBody"
            textAlign="center"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            mb={0.5}
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: '12px',
              },
            }}
          >
            {processTranslationObject(product.name)}
          </Typography>

          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              width="40px"
              height="30px"
              borderRadius="5px"
              sx={{
                background: theme.palette.primary.midtone,
                width: '30px',
                height: '20px',
              }}
            >
              <Typography
                variant="smallBody"
                fontWeight="600"
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: '12px',
                  },
                }}
              >
                {product.quantity}x
              </Typography>
            </Stack>

            <Typography
              variant="smallBody"
              fontWeight="700"
              color="primary.main"
              sx={{
                [theme.breakpoints.down('md')]: {
                  fontSize: '14px',
                },
              }}
            >
              {product.price + optionsPrice} ₪
            </Typography>
          </Stack>
        </Box>
      </Box>

      <Box
        position="absolute"
        top="0"
        right="0"
        borderRadius="12px"
        p={1.25}
        sx={{
          background: theme.palette.primary.dark,
          [theme.breakpoints.down('md')]: {
            p: 0.5,
            borderRadius: '5px',
            right: '-5px',
            svg: {
              width: '20px',
            },
          },
        }}
        onClick={handleOpen}
      >
        <svg
          width="30"
          height="30"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.1001 18.4L10.5001 10M10.5001 10L18.9001 1.59998M10.5001 10L2.1001 1.59998M10.5001 10L18.9001 18.4"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Fade in={open}>
          <Box
            width="100%"
            maxWidth="1000px"
            position="absolute"
            top="50%"
            left="50%"
            borderRadius="100px"
            p={7.5}
            sx={{
              transform: 'translate(-50%, -50%)',
              background: theme.palette.primary.light,
              [theme.breakpoints.down('md')]: {
                borderRadius: '30px',
                p: 2,
                width: '94%',
              },
            }}
          >
            <Box
              width={100}
              height={100}
              position="absolute"
              sx={{
                transform: 'translate(-50%, -50%)',
                top: '-20%',
                left: '50%',
                [theme.breakpoints.down('md')]: {
                  width: '46px',
                  height: '44px',
                  svg: {
                    width: 30,
                  },
                },
              }}
            >
              <PrimaryButton fullWidth onClick={handleClose}>
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 46L25.0001 25.0001M25.0001 25.0001L46 4M25.0001 25.0001L4 4M25.0001 25.0001L46 46"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </PrimaryButton>
            </Box>
            <Box maxWidth="730px" mx="auto">
              <Typography
                fontSize="40px"
                lineHeight="1.2"
                color="primary.dark"
                textAlign="center"
                mb={7.5}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: '20px',
                    mb: 2,
                  },
                }}
              >
                {t('titleModalDeleteItem')}
              </Typography>
            </Box>

            <Stack
              direction="column"
              gap={3.7}
              maxWidth="700px"
              mx="auto"
              sx={{
                [theme.breakpoints.down('md')]: {
                  gap: 2,
                },
              }}
            >
              <PrimaryButton fullWidth onClick={handleRemove}>
                {t('deleteItem')}
              </PrimaryButton>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </Box>
  )
}

export default CartCard
