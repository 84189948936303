import { Stack, useTheme } from '@mui/material'
import { useProcessTranslationObject } from '@/services/translation'
import type IChoice from '@/types/Choice'
import ChoiceCounter from './ChoiceCounter'
import ChoiceImage from './ChoiceImage'
import ChoicePrice from './ChoicePrice'
import ChoiceTitle from './ChoiceTitle'
import ChoiceWrapper from './ChoiceWrapper'

interface Props {
  choice: IChoice
  isDisabled: boolean
  showPrice: boolean
  onModify: (quantity: number) => void
}

const ChoiceWithCounter = ({
  choice,
  isDisabled,
  showPrice,
  onModify,
}: Props) => {
  const theme = useTheme()
  const { processTranslationObject } = useProcessTranslationObject()
  const isSelected = choice.quantity > 0
  const isFree = choice.freeQuantity > 0

  return (
    <ChoiceWrapper
      isFree={isFree}
      isSelected={isSelected}
      isDisabled={!isSelected && isDisabled}
    >
      <ChoiceImage url={choice.imageUrl} />

      <ChoiceTitle
        title={processTranslationObject(choice.name)}
        calories={choice.calories}
      />

      <Stack
        direction="row"
        alignItems="center"
        spacing={2.5}
        sx={{
          [theme.breakpoints.down('md')]: {
            ml: '16px !important',
          },
        }}
      >
        {!isFree && (showPrice || isSelected) && (
          <ChoicePrice price={choice.price} />
        )}

        <ChoiceCounter
          quantity={choice.quantity}
          isDisabled={isDisabled}
          onModify={(quantity) => onModify(quantity)}
        />
      </Stack>
    </ChoiceWrapper>
  )
}

export default ChoiceWithCounter
