import { useQuery } from '@tanstack/react-query'
import { getChanelId } from '@/services/auth'
import { getSettings } from '@/services/settings'
import type ISettings from '@/types/Settings'

interface IUseSettings {
  refetchInterval?: number
  enabled?: boolean
}

const useSettings = (config: IUseSettings = {}) => {
  const channelId = getChanelId()

  return useQuery<ISettings, Error>({
    queryKey: ['settings', channelId],
    queryFn: getSettings,
    staleTime: 60 * 1000,
    ...config,
  })
}

export default useSettings
