import { Checkbox, useTheme } from '@mui/material'

interface Props {
  isSelected: boolean
}

const ChoiceCheckbox = ({ isSelected = false }: Props) => {
  const theme = useTheme()

  return (
    <Checkbox
      icon={<span />}
      checkedIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
        >
          <path
            d="M1.7002 11.7801L6.72877 16.9001L19.3002 4.1001"
            stroke={theme.palette.primary.dark}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
      sx={{
        width: '50px',
        height: '50px',
        background: theme.palette.common.white,
        borderRadius: '8px',
        border: `2px solid ${theme.palette.primary.dark}`,
        pointerEvents: 'none',

        '&.Mui-checked': {
          color: theme.palette.common.white,
        },

        [theme.breakpoints.down('md')]: {
          width: 30,
          height: 30,
        },
      }}
      checked={isSelected}
    />
  )
}

export default ChoiceCheckbox
