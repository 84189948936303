import useMenu from '@/hooks/useMenu'

const useProduct = (productId: string | undefined) => {
  const { data: menu } = useMenu()

  if (!menu) return null

  const product = menu.categories
    .flatMap((category) => category.items)
    .find((product) => product.id === productId)

  return product || null
}

export default useProduct
