import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import type ICartStore from '@/types/CartStore'

const useCartStore = create<ICartStore>()(
  persist(
    (set, get) => ({
      customer: {
        name: '',
        phone: '',
        email: '',
        address: { address: '', entrance: '', floor: '', apartment: '' },
      },
      termsAccepted: false,
      cart: [],
      orderType: null,
      minimumOrderPrice: 0,
      paymentResult: {
        id: 0,
        amount: 0,
        transactionId: '',
      },
      index: 1,
      selectedDate: null,
      setOrderType: (orderType) => set({ orderType }),
      addToCart: (product) =>
        set((store) => ({
          cart: [
            ...store.cart,
            { ...product, uniqueId: `${product.id}-${store.index}` },
          ],
          index: store.index + 1,
        })),
      updateInCart: (product) =>
        set((store) => ({
          cart: store.cart.map((item) =>
            item.uniqueId === product.uniqueId ? { ...item, ...product } : item,
          ),
        })),
      removeFromCart: (uniqueId) =>
        set((store) => ({
          cart: store.cart.filter((item) => item.uniqueId !== uniqueId),
        })),
      setCustomer: (customer) => set({ customer }),
      setMinimumOrderPrice: (minimumOrderPrice) => set({ minimumOrderPrice }),
      setPaymentResult: (payment) => set({ paymentResult: payment }),
      setTermsAccepted: (accepted) => set({ termsAccepted: accepted }),
      clearCartStore: () =>
        set({
          customer: { name: '', phone: '', email: '' },
          cart: [],
          paymentResult: { id: 0, amount: 0, transactionId: '' },
          index: 1,
          termsAccepted: false,
        }),
      getCartTotalPrice: () => {
        const { cart } = get()

        return cart.reduce((acc, product) => {
          const optionsPrice = product.options.reduce(
            (acc1, option) =>
              acc1 +
              option.choices.reduce(
                (acc2, choice) =>
                  acc2 + choice.price * (choice.quantity - choice.freeQuantity),
                0,
              ),
            0,
          )

          return acc + (product.price + optionsPrice) * product.quantity
        }, 0)
      },
      setSelectedDate: (date: string | null) => set({ selectedDate: date }),
      clearSchedule: () => set({ selectedDate: null }),
    }),
    {
      name: 'cart-store',
    },
  ),
)

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('CartStore', useCartStore)
}

export default useCartStore
