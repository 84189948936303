import { Box, Grid, useTheme } from '@mui/material'
import useCategory from '../../hooks/useCategory'
import useCategoryStore from '../../store/useCategoryStore'
import ProductCard from './ProductCard'

const ProductList = () => {
  const theme = useTheme()
  const selectedCategoryId = useCategoryStore((s) => s.selectedCategoryId)
  const category = useCategory(selectedCategoryId)

  return (
    <Box
      className="hide-scroll"
      sx={{
        px: 11,
        pb: '500px',
        overflowY: 'auto',
        [theme.breakpoints.down('md')]: {
          mt: '0px !important',
          pl: '36px',
          pr: '10px',
          pt: '25px',
          pb: '160px',
        },
      }}
    >
      <Grid container rowSpacing={5} columnSpacing={5} justifyContent="center">
        {category?.items
          .filter((p) => p.visible)
          .map((product) => (
            <Grid
              key={product.id}
              item
              sx={{
                [theme.breakpoints.down('md')]: {
                  pl: '14px !important',
                  pt: '15px !important',
                },
              }}
            >
              <ProductCard product={product} />
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}

export default ProductList
