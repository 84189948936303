import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Button, useTheme } from '@mui/material'

interface Props {
  children: React.ReactNode
  to?: string
  fullWidth?: boolean
  disabled?: boolean
  size?: 'medium' | 'large'
  type?: 'button' | 'submit'
  form?: string
  onClick?: () => void
}

const sizeMap = {
  medium: {
    fontSize: '36px',
    padding: 2.5,
  },
  large: {
    fontSize: '48px',
    padding: 3.75,
  },
}

const PrimaryButton = ({
  children,
  to = '',
  fullWidth = false,
  disabled = false,
  size = 'medium',
  type = 'button',
  form,
  onClick,
}: Props) => {
  const theme = useTheme()

  return (
    <Button
      component={to ? RouterLink : 'button'}
      disabled={disabled}
      form={form}
      to={to}
      type={type}
      variant="contained"
      disableElevation
      fullWidth={fullWidth}
      sx={{
        p: sizeMap[size].padding,
        borderRadius: '20px',
        fontSize: sizeMap[size].fontSize,
        lineHeight: '1.2',
        fontWeight: 600,
        [theme.breakpoints.down('md')]: {
          p: '0 10px',
          height: 44,
          fontSize: 14,
          borderRadius: '10px',
        },
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export default PrimaryButton
