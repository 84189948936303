import { Grid, useTheme } from '@mui/material'
import ProductCard from '@/components/menu/ProductCard'
import useProduct from '@/hooks/useProduct'
import useCartStore from '@/store/useCartStore'
import Product from '@/types/Product'

interface Props {
  productId: string
  onAddToCart: () => void
  withoutBtn?: boolean
}

const UpsellProduct = ({ productId, onAddToCart, withoutBtn }: Props) => {
  const theme = useTheme()
  const product = useProduct(productId)
  const addToCart = useCartStore((s) => s.addToCart)

  const handleAddToCart = (product: Product) => {
    addToCart({ ...product, quantity: 1 })
    onAddToCart?.()
  }

  if (!product || !product.visible) return null

  return (
    <Grid
      key={product.id}
      item
      sx={{
        [theme.breakpoints.down('md')]: {
          pl: '14px !important',
          pt: '15px !important',
        },
      }}
    >
      <ProductCard
        upsell
        product={product}
        withoutBtn={withoutBtn}
        addToCart={() => handleAddToCart(product)}
      />
    </Grid>
  )
}

export default UpsellProduct
