import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Stack, useTheme } from '@mui/material'
import { usePaymentsList } from '@/utils/consts'
import AttachCard from '../../../components/payment/AttachCard'
import PayWithCreditCard from '../../../components/payment/PayWithCreditCard'
import PaymentLayout from '../../../layouts/PaymentLayout'
import useCartStore from '../../../store/useCartStore'
import usePaymentErrorStore from '../../../store/usePaymentErrorStore'

const CreditCard = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation('payment')

  const errorMessage = usePaymentErrorStore((s) => s.errorMessage)
  const throwError = usePaymentErrorStore((s) => s.throwError)
  const setPaymentResult = useCartStore((s) => s.setPaymentResult)

  const PAYMENTS_LIST = usePaymentsList()

  const handleSuccess = (
    transactionId: string,
    amount: number,
    acquirer: string,
    issuer: string,
    card: string,
    requestId: string,
  ) => {
    setPaymentResult({
      id: PAYMENTS_LIST.CREDIT_CARD.id,
      amount,
      transactionId,
      acquirer,
      issuer,
      card,
      requestId,
    })

    navigate('/payment/success')
  }

  return (
    <PaymentLayout
      title={t('creditCardTitle')}
      backTo="/payment"
      allowBack={false}
    >
      <Stack
        direction="column"
        justifyContent="flex-end"
        height="100%"
        sx={{
          [theme.breakpoints.down('md')]: {
            height: 'auto',
          },
        }}
      >
        {!errorMessage && (
          <>
            <PayWithCreditCard
              onSuccess={handleSuccess}
              onError={(error) => throwError('credit-card', error)}
            />
            <AttachCard />
          </>
        )}
      </Stack>
    </PaymentLayout>
  )
}

export default CreditCard
