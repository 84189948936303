import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import {
  AdvancedMarker,
  Map,
  Pin,
  useAdvancedMarkerRef,
  useApiIsLoaded,
  useMap,
} from '@vis.gl/react-google-maps'
import useDeliveryZone from '@/hooks/useDeliveryZone'
import useCartStore from '@/store/useCartStore'
import type IDeliveryArea from '@/types/DeliveryArea'
import PrimaryButton from '../shared/buttons/PrimaryButton'
import AddressForm from './AddressForm'

const center = {
  lat: 32.08893663850793,
  lng: 34.78121679687501,
}

interface MapHandlerProps {
  place: google.maps.places.PlaceResult | null
  marker: google.maps.marker.AdvancedMarkerElement | null
}

const MapHandler = ({ place, marker }: MapHandlerProps) => {
  const map = useMap()
  const customer = useCartStore((s) => s.customer)
  const setCustomer = useCartStore((s) => s.setCustomer)

  useEffect(() => {
    if (!map || !marker) return

    if (customer.address?.location) {
      map.setCenter(customer.address.location)
      marker.position = customer.address.location
      map.setZoom(15)
    }

    if (!place) return

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry?.viewport)
    }
    marker.position = place.geometry?.location

    setCustomer({
      ...customer,
      address: {
        address: place.name,
        location: place.geometry?.location
          ? {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng(),
            }
          : undefined,
      },
    })
  }, [map, place, marker])

  return null
}

const AddressWizard = () => {
  const theme = useTheme()
  const { t } = useTranslation('delivery')
  const customer = useCartStore((s) => s.customer)

  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null)
  const [markerRef, marker] = useAdvancedMarkerRef()

  const map = useMap()
  const apiIsLoaded = useApiIsLoaded()
  const { data: deliveryZone } = useDeliveryZone()

  useEffect(() => {
    if (!apiIsLoaded) return

    deliveryZone?.areas.forEach((area: IDeliveryArea) => {
      const polygon = new google.maps.Polygon({
        paths: area.area,
        strokeColor: '#321313',
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: '#9d00ff',
        fillOpacity: 0.3,
      })

      polygon.setMap(map)
    })
  }, [map, apiIsLoaded, deliveryZone])

  return (
    <Stack
      flex="1"
      direction="column"
      gap={7.5}
      sx={{
        [theme.breakpoints.down('md')]: {
          gap: 3,
        },
      }}
    >
      <Typography
        variant="body"
        textAlign="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: 16,
          },
        }}
      >
        {t('enterAddressText')}
      </Typography>

      <AddressForm onPlaceSelect={setSelectedPlace} />

      <Box
        height={customer.address?.address ? '560px' : '800px'}
        width="100%"
        bgcolor="#ccc"
        overflow="hidden"
        borderRadius="30px"
        border={`1px solid ${theme.palette.primary.midtone}`}
        sx={{
          [theme.breakpoints.down('md')]: {
            height: `${customer.address?.address ? '300px' : '400px'}`,
          },
        }}
      >
        <Map
          mapId="bf51a910020fa25b"
          defaultZoom={8}
          defaultCenter={center}
          gestureHandling="greedy"
          disableDefaultUI
        >
          <AdvancedMarker ref={markerRef} position={null}>
            <Pin scale={2} />
          </AdvancedMarker>
        </Map>

        <MapHandler place={selectedPlace} marker={marker} />
      </Box>

      <Box mb={3}>
        <PrimaryButton type="submit" form="address-form" fullWidth size="large">
          {t('confirm')}
        </PrimaryButton>
      </Box>
    </Stack>
  )
}

export default AddressWizard
