import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import DateSelect from '@/components/delayed-order/DateSelect'
import TimePicker from '@/components/delayed-order/TimePicker'
import useDelayedOrder from '@/hooks/useDelayedOrder'
import useSettings from '@/hooks/useSettings'
import { isKioskWorkingHours } from '@/services/working-hours'

const DeliveryTime = () => {
  const theme = useTheme()
  const { t } = useTranslation('delivery')
  const { data: settings } = useSettings()
  const {
    handleDateChange,
    handleTimeChange,
    openTime,
    closeTime,
    availableDates,
    selectedDate,
  } = useDelayedOrder()

  const [deliveryTimeType, setDeliveryTimeType] = useState<string>('ASAP')
  const [open, setOpen] = useState<boolean>(false)

  const onClick = () => {
    setDeliveryTimeType('Scheduled')
    setOpen(!open)
  }

  return (
    <>
      <Box borderBottom={`1px solid ${theme.palette.primary.dark}`} />

      <Typography variant="body" textAlign="center">
        {t('timeDeliveryText')}
      </Typography>

      <Stack
        direction="row"
        gap={5}
        px={2.5}
        py={1.5}
        border={`1.5px solid ${theme.palette.primary.midtone}`}
        borderRadius="30px"
        sx={{
          backgroundColor: theme.palette.primary.light,
        }}
      >
        {isKioskWorkingHours(settings?.schedule || null) && (
          <Box
            flex="1"
            borderRadius="20px"
            px={5}
            py={2.5}
            sx={{
              backgroundColor:
                deliveryTimeType === 'ASAP'
                  ? theme.palette.primary.midtone
                  : 'transparent',
            }}
            onClick={() => setDeliveryTimeType('ASAP')}
          >
            <Typography
              fontSize="36px"
              fontWeight="600"
              lineHeight="44px"
              textAlign="center"
            >
              {t('asap')}
            </Typography>
          </Box>
        )}

        <Box
          flex="1"
          borderRadius="20px"
          px={5}
          py={2.5}
          sx={{
            backgroundColor:
              deliveryTimeType === 'Scheduled'
                ? theme.palette.primary.midtone
                : 'transparent',
          }}
          onClick={onClick}
        >
          <Typography
            fontSize="36px"
            fontWeight="600"
            lineHeight="44px"
            textAlign="center"
          >
            {t('schedule')}
          </Typography>
        </Box>
      </Stack>
      {open && (
        <Box sx={{ display: 'flex', gap: '14px' }}>
          <DateSelect
            availableDates={availableDates}
            selectedDate={selectedDate}
            handleDateChange={handleDateChange}
          />
          <TimePicker
            value={selectedDate ? DateTime.fromISO(selectedDate) : null}
            disabled={!selectedDate}
            maxTime={closeTime}
            minTime={openTime}
            onChange={handleTimeChange}
          />
        </Box>
      )}
    </>
  )
}

export default DeliveryTime
