import { useTranslation } from 'react-i18next'
import { Stack, useTheme } from '@mui/material'
import PrimaryButton from '../shared/buttons/PrimaryButton'
import SecondaryButton from '../shared/buttons/SecondaryButton'

interface Props {
  productInCart: boolean
  onSuccess: () => void
}

const ProductActions = ({ productInCart, onSuccess }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation('product')

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing="30px"
      sx={{
        [theme.breakpoints.down('md')]: {
          mt: '16px !important',
          pl: 1,
          pr: 1,
        },
      }}
    >
      <SecondaryButton to="/menu">{t('cancel')}</SecondaryButton>

      <PrimaryButton fullWidth onClick={onSuccess}>
        {productInCart ? t('save') : t('addToCart')}
      </PrimaryButton>
    </Stack>
  )
}

export default ProductActions
