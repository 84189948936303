import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Button, Typography, useTheme } from '@mui/material'

interface Props {
  name: string
  icon: React.ReactNode
  to?: string
}

const PaymentOptionButton = ({ name, icon, to = '' }: Props) => {
  const theme = useTheme()

  return (
    <Button
      component={to ? RouterLink : 'button'}
      to={to}
      variant="text"
      fullWidth
      sx={{
        justifyContent: 'space-between',
        gap: 2.5,
        minHeight: '110px',
        borderRadius: '30px',
        bgcolor: theme.palette.primary.light,
        px: 5,
        py: 2.5,
        ':hover': {
          bgcolor: theme.palette.primary.light,
        },

        [theme.breakpoints.down('md')]: {
          p: 1,
          minHeight: '40px',
          borderRadius: '15px',
          svg: {
            height: 50,
          },
        },
      }}
    >
      <Typography
        variant="h3"
        sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: '18px',
          },
        }}
      >
        {name}
      </Typography>

      {icon}
    </Button>
  )
}

export default PaymentOptionButton
