import { useTranslation } from 'react-i18next'
import { Button, Stack, Typography, useTheme } from '@mui/material'
import useProductStore from '../../store/useProductStore'
import MinusIcon from '../icons/MinusIcon'
import PlusIcon from '../icons/PlusIcon'

const ProductQuantity = () => {
  const { t } = useTranslation('product')
  const theme = useTheme()

  const product = useProductStore((s) => s.product)
  const setProductQuantity = useProductStore((s) => s.setProductQuantity)

  const setQuantity = (quantity: number) => {
    setProductQuantity(quantity)
  }

  if (!product) return null

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={5}
      paddingX={2.5}
      sx={{
        [theme.breakpoints.down('md')]: {
          p: '0 8px 0 0',
          justifyContent: 'space-between',
          width: '100%',
        },
      }}
    >
      <Typography
        variant="body"
        fontWeight="600"
        textTransform="uppercase"
        sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: '16px',
          },
        }}
      >
        {t('totalQuantity')}
      </Typography>

      <Stack
        direction="row"
        alignItems="center"
        spacing={2.5}
        sx={{
          [theme.breakpoints.down('md')]: {
            m: '0 !important',
          },
        }}
      >
        <Button
          variant="outlined"
          sx={{
            borderColor: theme.palette.primary.dark,
            borderRadius: '12px',
            padding: '9px',
            ':hover': {
              borderColor: theme.palette.primary.dark,
            },
            [theme.breakpoints.down('md')]: {
              p: '5px',
              borderRadius: '6px',
              svg: {
                width: '18px',
              },
            },
          }}
          onClick={() => {
            if (product.quantity > 1) {
              setQuantity(product.quantity - 1)
            }
          }}
        >
          <MinusIcon />
        </Button>

        <Typography
          variant="body"
          fontWeight="600"
          p={1.25}
          sx={{
            [theme.breakpoints.down('md')]: {
              ml: '2px !important',
              fontSize: '16px',
            },
          }}
        >
          {product.quantity}x
        </Typography>

        <Button
          variant="contained"
          disableElevation
          sx={{
            borderRadius: '12px',
            padding: '10px',
            [theme.breakpoints.down('md')]: {
              p: '6px',
              borderRadius: '6px',
              ml: '0 !important',
              svg: {
                width: '18px',
              },
            },
          }}
          onClick={() => setQuantity(product.quantity + 1)}
        >
          <PlusIcon />
        </Button>
      </Stack>
    </Stack>
  )
}

export default ProductQuantity
