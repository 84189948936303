import { Box, useTheme } from '@mui/material'

interface Props {
  children: React.ReactNode
}

const OptionWrapper = ({ children }: Props) => {
  const theme = useTheme()
  return (
    <Box
      px={5}
      py={2.5}
      borderRadius="30px"
      mb={7.5}
      sx={{
        background: theme.palette.primary.midtone,
        [theme.breakpoints.down('md')]: {
          p: '10px',
          mb: 2,
        },
      }}
    >
      {children}
    </Box>
  )
}

export default OptionWrapper
