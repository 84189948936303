import { Box, Stack, Typography } from '@mui/material'
import { useProcessTranslationObject } from '@/services/translation'
import Option from '@/types/Option'

interface Props {
  option: Option
}

const MultipleChoiceOption = ({ option }: Props) => {
  const { processTranslationObject } = useProcessTranslationObject()

  return (
    <>
      <Box p={0.5} my={1} border="1px dashed #826363" borderRadius="2px">
        <Typography
          fontSize={{ xs: '16px' }}
          textTransform="uppercase"
          textAlign="center"
          color="#000"
        >
          {processTranslationObject(option.name)}
        </Typography>
      </Box>

      {option.choices.map((choice) => (
        <Stack
          key={choice.id}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          px={0.5}
          py={0.25}
          spacing={3}
          mb={0.25}
        >
          <Typography fontSize={{ xs: '14px' }}>
            <Box component="span" fontWeight="600" color="#000">
              x{choice.quantity}
            </Box>
            {processTranslationObject(choice.name)}
          </Typography>

          {choice.price > 0 && (
            <Typography fontSize={{ xs: '14px' }} color="#000" fontWeight="600">
              +{choice.price * choice.quantity} ₪
            </Typography>
          )}
        </Stack>
      ))}
    </>
  )
}

export default MultipleChoiceOption
