import { Box, Stack, Typography } from '@mui/material'
import { useProcessTranslationObject } from '@/services/translation'
import MultipleChoiceOption from './MultipleChoiceOption'
import SingleChoiceOption from './SingleChoiceOption'

interface Props {
  product: any
}

const Product = ({ product }: Props) => {
  const { processTranslationObject } = useProcessTranslationObject()

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
        mb={0.5}
      >
        <Typography
          fontSize={{ xs: '18px' }}
          textTransform="uppercase"
          fontWeight="600"
          color="#000"
        >
          x{product.quantity} {processTranslationObject(product.name)}
        </Typography>

        <Box flexShrink="0">
          <Typography
            fontSize={{ xs: '18px' }}
            textTransform="uppercase"
            fontWeight="600"
            color="#000"
          >
            {`${product.totalPrice * product.quantity} ₪`}
          </Typography>
        </Box>
      </Stack>

      <Box fontSize="0">
        {product.options
          .filter((o: any) => o.type === 'SINGLE_CHOICE')
          .map((option: any) => (
            <SingleChoiceOption key={option.id} option={option} />
          ))}
      </Box>

      {product.options
        .filter((o: any) => o.type === 'MULTIPLE_CHOICE')
        .map((option: any) => (
          <MultipleChoiceOption key={option.id} option={option} />
        ))}
    </>
  )
}

export default Product
