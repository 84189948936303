import { useEffect, useState } from 'react'
import { Box } from '@mui/material'

interface Props {
  initialSeconds: number
  onFinish: () => void
}

const CountdownTimer = ({ initialSeconds, onFinish }: Props) => {
  const [seconds, setSeconds] = useState(initialSeconds)

  useEffect(() => {
    if (seconds <= 0) {
      onFinish()
      return
    }

    const timer = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1)
    }, 1000)

    return () => clearInterval(timer)
  }, [seconds])

  return (
    <Box component="span" pl={1}>
      ({seconds})
    </Box>
  )
}

export default CountdownTimer
