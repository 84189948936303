import { createBrowserRouter } from 'react-router-dom'
import DelayedOrder from '@/pages/DelayedOrder'
import Delivery from '@/pages/delivery'
import Address from '@/pages/delivery/address'
import AddressDetails from '@/pages/delivery/address/AddressDetails'
import DeliveryDetails from '@/pages/delivery/DeliveryDetails'
import EditProduct from '../pages/EditProduct'
import Home from '../pages/Home'
import Login from '../pages/Login'
import Menu from '../pages/Menu'
import Payment from '../pages/payment'
import Balamut from '../pages/payment/balamut'
import Cash from '../pages/payment/cash'
import CashOnCloud from '../pages/payment/cashOnCloud/CashOnCloud'
import Cibus from '../pages/payment/cibus'
import CibusChooseCompany from '../pages/payment/cibus/ChooseCompany'
import CibusManualInput from '../pages/payment/cibus/ManualInput'
import CibusTerminal from '../pages/payment/cibus/Terminal'
import CreditCardTerminal from '../pages/payment/creditCard/CreditCard'
import Goodi from '../pages/payment/goodi/Goodi'
import GoodiManualInput from '../pages/payment/goodi/ManualInput'
import GoodiTerminal from '../pages/payment/goodi/Terminal'
import PaymentSuccess from '../pages/payment/PaymentSuccess'
import PaymentPeleCardSuccess from '../pages/payment/peleCard/PaymentPeleCardSuccess'
import PeleCardTerminal from '../pages/payment/peleCard/PeleCard'
import TenBis from '../pages/payment/tenbis'
import TenBisManualInput from '../pages/payment/tenbis/ManualInput'
import TenBisTerminal from '../pages/payment/tenbis/Terminal'
import Product from '../pages/Product'
import UnavailableKiosk from '../pages/UnavailableKiosk'
import WhereToEat from '../pages/WhereToEat'
import PrivateRoute from './PrivateRoute'

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <PrivateRoute>
        <Home />
      </PrivateRoute>
    ),
  },
  { path: '/login', element: <Login /> },
  {
    path: '/where-to-eat',
    element: (
      <PrivateRoute>
        <WhereToEat />
      </PrivateRoute>
    ),
  },
  {
    path: '/delivery',
    element: (
      <PrivateRoute>
        <Delivery />
      </PrivateRoute>
    ),
  },
  {
    path: '/delivery/address/',
    element: (
      <PrivateRoute>
        <Address />
      </PrivateRoute>
    ),
  },
  {
    path: '/delivery/address/details',
    element: (
      <PrivateRoute>
        <AddressDetails />
      </PrivateRoute>
    ),
  },
  {
    path: '/delivery/details',
    element: (
      <PrivateRoute>
        <DeliveryDetails />
      </PrivateRoute>
    ),
  },
  {
    path: '/menu',
    element: (
      <PrivateRoute>
        <Menu />
      </PrivateRoute>
    ),
  },
  {
    path: '/product/:id',
    element: (
      <PrivateRoute>
        <Product />
      </PrivateRoute>
    ),
  },
  {
    path: '/product/:uniqueId/edit',
    element: (
      <PrivateRoute>
        <EditProduct />
      </PrivateRoute>
    ),
  },
  {
    path: '/payment',
    element: (
      <PrivateRoute>
        <Payment />
      </PrivateRoute>
    ),
  },
  {
    path: '/payment/tenbis',
    element: (
      <PrivateRoute>
        <TenBis />
      </PrivateRoute>
    ),
  },
  {
    path: '/payment/tenbis/manual-input',
    element: (
      <PrivateRoute>
        <TenBisManualInput />
      </PrivateRoute>
    ),
  },
  {
    path: '/payment/tenbis/terminal',
    element: (
      <PrivateRoute>
        <TenBisTerminal />
      </PrivateRoute>
    ),
  },
  {
    path: '/payment/goodi',
    element: (
      <PrivateRoute>
        <Goodi />
      </PrivateRoute>
    ),
  },
  {
    path: '/payment/goodi/manual-input',
    element: (
      <PrivateRoute>
        <GoodiManualInput />
      </PrivateRoute>
    ),
  },
  {
    path: '/payment/goodi/terminal',
    element: (
      <PrivateRoute>
        <GoodiTerminal />
      </PrivateRoute>
    ),
  },
  {
    path: '/payment/cibus',
    element: (
      <PrivateRoute>
        <Cibus />
      </PrivateRoute>
    ),
  },
  {
    path: '/payment/cibus/terminal',
    element: (
      <PrivateRoute>
        <CibusTerminal />
      </PrivateRoute>
    ),
  },
  {
    path: '/payment/cibus/choose-company',
    element: (
      <PrivateRoute>
        <CibusChooseCompany />
      </PrivateRoute>
    ),
  },
  {
    path: '/payment/cibus/manual-input',
    element: (
      <PrivateRoute>
        <CibusManualInput />
      </PrivateRoute>
    ),
  },
  {
    path: '/payment/credit-card/terminal',
    element: (
      <PrivateRoute>
        <CreditCardTerminal />
      </PrivateRoute>
    ),
  },
  {
    path: '/payment/pelecard',
    element: (
      <PrivateRoute>
        <PeleCardTerminal />
      </PrivateRoute>
    ),
  },
  {
    path: '/payment/pelecard/success',
    element: (
      <PrivateRoute>
        <PaymentPeleCardSuccess />
      </PrivateRoute>
    ),
  },
  {
    path: '/payment/cash',
    element: (
      <PrivateRoute>
        <Cash />
      </PrivateRoute>
    ),
  },
  {
    path: '/payment/cash-on-cloud',
    element: (
      <PrivateRoute>
        <CashOnCloud />
      </PrivateRoute>
    ),
  },
  {
    path: '/payment/balamut',
    element: (
      <PrivateRoute>
        <Balamut />
      </PrivateRoute>
    ),
  },
  {
    path: '/payment/success',
    element: (
      <PrivateRoute>
        <PaymentSuccess />
      </PrivateRoute>
    ),
  },
  {
    path: '/unavailable-kiosk',
    element: (
      <PrivateRoute>
        <UnavailableKiosk />
      </PrivateRoute>
    ),
  },
  {
    path: '/delayed-order',
    element: (
      <PrivateRoute>
        <DelayedOrder />
      </PrivateRoute>
    ),
  },
])

export default router
