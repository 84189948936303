import { useTranslation } from 'react-i18next'
import { Box, Typography, useTheme } from '@mui/material'

interface Props {
  title: string
  calories: number
}

const ChoiceTitle = ({ title, calories }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation('product')

  return (
    <Box
      flex="1"
      sx={{
        [theme.breakpoints.down('md')]: {
          ml: '16px !important',
        },
      }}
    >
      <Typography
        fontSize="36px"
        lineHeight="1.2"
        sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: '14px',
          },
        }}
      >
        {title}
      </Typography>

      {calories > 0 && (
        <Typography
          variant="smallBody"
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '16px',
            },
          }}
        >
          {calories} {t('calories')}
        </Typography>
      )}
    </Box>
  )
}

export default ChoiceTitle
