import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Grid, Stack, useTheme } from '@mui/material'
import ScrollToBottom from '@/components/shared/ScrollToBottom'
import useCartStore from '../../store/useCartStore'
import useProductErrorStore from '../../store/useProductErrorStore'
import useProductStore from '../../store/useProductStore'
import ProductActions from './ProductActions'
import ProductCustomizations from './ProductCustomizations'
import ProductInfo from './ProductInfo'
import ProductPrice from './ProductPrice'
import ProductQuantity from './ProductQuantity'

const ProductCard = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const cart = useCartStore((s) => s.cart)
  const addToCart = useCartStore((s) => s.addToCart)
  const updateInCart = useCartStore((s) => s.updateInCart)

  const product = useProductStore((s) => s.product)
  const getRequiredOptions = useProductStore((s) => s.getRequiredOptions)
  const productInCart = cart.find((item) => item.uniqueId === product?.uniqueId)

  const throwOptionsError = useProductErrorStore((s) => s.throwOptionsError)
  const clearOptionsError = useProductErrorStore((s) => s.clearOptionsError)

  const handleSuccess = () => {
    if (!product) return

    const requiredOptions = getRequiredOptions()

    if (requiredOptions.length > 0) {
      const elem = document.getElementById(requiredOptions[0].id)
      elem?.scrollIntoView({ behavior: 'smooth' })

      throwOptionsError(requiredOptions)

      return
    }

    if (productInCart) {
      updateInCart(product)
    } else {
      addToCart(product)
    }

    navigate('/menu')
  }

  useEffect(() => clearOptionsError(), [])

  if (!product) return null

  return (
    <Stack
      direction="column"
      alignItems="center"
      height="100%"
      overflow="hidden"
    >
      <Box
        width="100%"
        mb={7.5}
        sx={{
          [theme.breakpoints.down('md')]: {
            mb: 0,
          },
        }}
      >
        <ProductInfo />
      </Box>

      <Stack
        direction="column"
        width="100%"
        flex={1}
        borderRadius="100px 100px 0 0"
        paddingX={6.25}
        paddingY={5}
        spacing={5}
        overflow="hidden"
        sx={{
          backgroundColor: theme.palette.primary.light,
          [theme.breakpoints.down('md')]: {
            borderRadius: '45px 45px 0 0',
            pl: 0,
            pr: 0,
            pt: 1,
            pb: 2,
          },
        }}
      >
        <Box
          flex="1"
          px={2.5}
          sx={{
            overflowY: 'auto',
            [theme.breakpoints.down('md')]: {
              pt: 0,
              pb: 0,
              pr: 1,
              pl: 1,
            },
          }}
        >
          <Box
            component="img"
            src={product?.imageUrl}
            width="100%"
            maxWidth={500}
            height="500px"
            borderRadius="50px"
            mx="auto"
            mb={7.5}
            sx={{
              objectFit: 'cover',
              [theme.breakpoints.down('md')]: {
                maxWidth: '240px',
                height: '25vh',
                mb: '40px',
              },
            }}
          />

          <ProductCustomizations />
        </Box>

        <Box
          paddingY={3}
          borderTop="1px solid #321313"
          borderBottom="1px solid #321313"
          marginBottom={5}
          sx={{
            [theme.breakpoints.down('md')]: {
              p: '5px 12px 5px 12px',
              mt: '0 !important',
              borderTop: `1px solid ${theme.palette.primary.dark}`,
              borderBottom: 0,
            },
          }}
        >
          <Grid
            container
            sx={{
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
                gap: 1,
              },
            }}
          >
            <Grid
              item
              xs={6}
              sx={{
                [theme.breakpoints.down('md')]: {
                  maxWidth: '100%',
                },
              }}
            >
              <ProductQuantity />
            </Grid>

            <Grid
              item
              xs={6}
              borderLeft="1px solid #321313"
              sx={{
                [theme.breakpoints.down('md')]: {
                  borderLeft: 0,
                  maxWidth: '100%',
                  display: 'contents',
                },
              }}
            >
              <ProductPrice />
            </Grid>
          </Grid>
        </Box>

        <ProductActions
          productInCart={!!productInCart}
          onSuccess={handleSuccess}
        />
      </Stack>

      <ScrollToBottom />
    </Stack>
  )
}

export default ProductCard
