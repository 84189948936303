import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography } from '@mui/material'
import { useOrderTypesList } from '@/utils/consts'
import type OrderType from '@/types/OrderType'

interface Props {
  phoneNumber: string
  clientName: string
  orderType: OrderType
}

const Header = ({ phoneNumber, clientName, orderType }: Props) => {
  const { t } = useTranslation('receipt')
  const ORDER_TYPES_LIST = useOrderTypesList()

  return (
    <Box pb={1} borderBottom="1px solid #000" mb={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          fontSize={{ xs: '16px' }}
          color="#000"
          textTransform="uppercase"
        >
          {t('client')}
        </Typography>
        <Typography fontSize={{ xs: '16px' }} color="#000" fontWeight="600">
          {clientName}
        </Typography>
      </Stack>

      {phoneNumber && phoneNumber !== '0500000000' && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={0.5}
        >
          <Typography
            fontSize={{ xs: '16px' }}
            color="#000"
            textTransform="uppercase"
          >
            {t('phone')}
          </Typography>
          <Typography fontSize={{ xs: '16px' }} color="#000" fontWeight="600">
            {phoneNumber}
          </Typography>
        </Stack>
      )}

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={0.5}
      >
        <Typography
          fontSize={{ xs: '16px' }}
          color="#000"
          textTransform="uppercase"
        >
          {t('orderType')}
        </Typography>
        <Typography fontSize={{ xs: '16px' }} color="#000" fontWeight="600">
          {ORDER_TYPES_LIST[orderType].name}
        </Typography>
      </Stack>
    </Box>
  )
}

export default Header
