import { useEffect, useRef } from 'react'
import { Box } from '@mui/material'

const ScrollToBottom = () => {
  const bottomRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [])

  return <Box ref={bottomRef} />
}

export default ScrollToBottom
