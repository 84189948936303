/* eslint-disable react/no-unescaped-entities */
import { Box, SxProps, Typography } from '@mui/material'
import {
  Block,
  Container,
  Li,
  LiDef,
  Ol,
  Ul,
} from '@/components/home/documents/styled'

const PrivacyPolicyHe = ({ sx }: { sx: SxProps }) => {
  return (
    <Box sx={sx} dir="RTL">
      <Typography variant="h6" component="h1" gutterBottom>
        מדיניות פרטיות –tap2dine
      </Typography>

      <Typography paragraph>תקף מתאריך: 09/07/2024</Typography>

      <Typography paragraph>
        מדיניות פרטיות זו (להלן:
        <strong>"מדיניות הפרטיות"</strong>) מהווה חלק בלתי נפרד מתנאי השימוש ויש
        לקוראה יחד עם תנאי השימוש, ולעשות שימוש בהגדרות המופיעות בתנאי השימוש.
      </Typography>

      <Typography paragraph>
        המפעילה מחויבת לכבד את פרטיות המשתמשים באתר. על מנת לשפר את ההגנה על
        פרטיות המשתמש מפרסמת המפעילה מדיניות פרטיות זו ומספקת את המידע המצוי בה
        אודות האפשרויות העומדות בפני המשתמש בעת שימושו באתר ובנוגע להתנהלות
        המפעילה בעניין איסוף מידע מהאתר.
      </Typography>

      <Container>
        <Block>
          <strong>כללי</strong>

          <Ol>
            <Li>
              <strong>1.1. </strong>
              כחלק מהשימוש בשירותי האתר, נדרש המשתמש להעביר פרטים אישיים לידי
              המפעילה ובהם שם, כתובת ופרטי התקשרות. פרטים אלה נשמרים במאגר המידע
              של המפעילה ומשמש, בין השאר, לצרכים פנימיים ותקשורת עתידית עם
              הלקוחות. יובהר כי המפעילה אינה שומרת ו/או אוספת פרטי כרטיס אשראי.
            </Li>

            <Li>
              <strong>1.2. </strong>
              בעת השימוש באתר יתכן ויצטברו נתונים אודות אופן השימוש של המשתמש
              באתר, ובהם שירותים בהם התעניין המשתמש, עמודים בהם צפה, מסעדות מהן
              הזמין ועוד. זהו מידע סטטיסטי שאינו מזהה את המשתמש באופן אישי.
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong>מאגר המידע והשימוש בו</strong>

          <Ol>
            <Li>
              <strong>2.1. </strong>
              הנתונים הנאספים אודות המשתמש שצוינו לעיל נשמרים במאגר המידע של
              המפעילה. המפעילה מתחייבת לעשות שימוש במידע האמור על פי מדיניות
              פרטיות זו ו/או על פי הוראות כל דין ולשם המטרות הבאות:
              <Ul>
                <LiDef> לאפשר שימוש יעיל ונוח למשתמש;</LiDef>

                <LiDef>לשפר ולהעשיר את השירותים והתכנים המוצעים באתר;</LiDef>

                <LiDef> לשנות או לבטל שירותים ותכנים קיימים;</LiDef>

                <LiDef>
                  לנתח ולמסור מידע סטטיסטי לצדדים שלישיים, לרבות מפרסמים (במקרה
                  שיועבר מידע זה לצדדים שלישיים, לא יזהה את המשתמש אישית);
                </LiDef>

                <LiDef> אכיפת תנאי השימוש של המפעילה;</LiDef>

                <LiDef> הפעלת חשבון המשתמש;</LiDef>

                <LiDef>
                  לכל מטרה אחרת המפורטת בתנאי השימוש ובמדיניות פרטיות זו.
                </LiDef>
              </Ul>
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong>שימוש במידע ודיוור ישיר</strong>

          <Ol>
            <Li>
              <strong>3.1. </strong>
              פרטי משתמש אשר יימסרו על ידו יהיו כפופים למדיניות הפרטיות של
              המפעילה, והמשתמש מאשר כי המידע שנמסר על-ידו ו/או ייאסף אודותיו
              יישמר במאגר המידע של המפעילה לצרכים הבאים:
              <Ul>
                <LiDef>
                  שיווק, פרסום, קידום מכירות ולמטרת פניה למשתמש בכל דרך לרבות
                  בדרך של דיוור ישיר בכל אמצעי תקשורת שתמצא לנכון (לרבות בכתב,
                  בדפוס, בטלפון, בפקסימיליה, בדרך ממוחשבת או באמצעי אחר);
                </LiDef>

                <LiDef>
                  עידוד נאמנות, ניתוח ומחקר סטטיסטי, עריכת סקרים וכל שימוש אחר
                  בקשר להרשמתו באתר;
                </LiDef>

                <LiDef>
                  צרכים פנימיים, כגון צרכי תחקור תלונות ו/או ביקורות;
                </LiDef>

                <LiDef>
                  צרכים תפעוליים, שיווקיים וסטטיסטיים, לרבות עיבוד המידע ודיוור
                  ישיר לצורך מימוש מטרות אלו;
                </LiDef>

                <LiDef>
                  מתן שירותים למשתמש והבטחת פעילות מסחר הוגנת על פי חוק.
                </LiDef>
              </Ul>
            </Li>

            <Li>
              <strong>3.2. </strong>
              ידוע למשתמש כי לא חלה עליו חובה חוקית למסור את המידע ומסירתו הינה
              מרצונו ובהסכמתו. כמו כן המפעילה תהא רשאית, למטרות המנויות לעיל,
              להעביר מעת לעת מידע בלתי מזוהה, אנונימי או אגרגטיבי, בנוגע לפעילות
              המשתמש במסגרת האתר, לגופים השותפים לפעילות המפעילה ולכל גורם
              שיעניק שירותים והטבות למשתמשים על פי שיקול דעתה של המפעילה.
            </Li>

            <Li>
              <strong>3.3. </strong>
              בהתאם להסכמת המשתמש כדין, המפעילה תהא רשאית לפנות אליו מעת לעת
              בדיוור ישיר לצורך קבלת הצעות לשירותים שונים של המפעילה אשר ניתנים
              במסגרת שימושו של המשתמש בשירותי המפעילה באמצעות דואר אלקטרוני,
              מסרונים (SMS), פקסימיליה, ברשתות החברתיות, מערכת חיוג אוטומטית
              ו/או בטלפון, וזאת כל עוד לא נתקבלה הודעה אחרת מהמשתמש בכל עת.
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong>מסירת מידע לצד שלישי</strong>
          <Ol>
            <Li>
              להלן מצבים בגינם עשויה המפעילה לחלוק את המידע של המשתמש עם צדדים
              שלישיים:
              <Ul>
                <LiDef>
                  כאשר המשתמש אישר למפעילה להעביר את פרטיו האישיים לצדדים
                  שלישיים לרבות מסעדות לצורך אספקת השירות;
                </LiDef>

                <LiDef>
                  במקרה בו יפר המשתמש את תנאי השימוש באתר או ינסה לבצע פעולות
                  המנוגדות לדין;
                </LiDef>

                <LiDef>
                  על מנת לאכוף את תנאי השימוש או הסכמים אחרים או מדיניות אחרת;
                </LiDef>

                <LiDef>
                  בתגובה לזימון או דרישת חקירה אחרת, צו בימ"ש, או בקשה לשיתוף
                  פעולה מאת רשות אכיפה או רשות ממשלתית אחרת;
                </LiDef>

                <LiDef>
                  על מנת לקבוע או לממש את זכויותיה המשפטיות של המפעילה;
                </LiDef>

                <LiDef>
                  על מנת להתגונן מפני תביעות משפטיות כפי שיידרש לפי דין. במקרים
                  כאמור, המפעילה עשויה להעלות או לוותר על התנגדות או זכות העומדת
                  לרשותה, לפי שיקול דעתה הבלעדי;
                </LiDef>

                <LiDef>
                  כאשר המפעילה מאמינה כי גילוי הינו ראוי בקשר למאמצים לחקור,
                  למנוע, לדווח או לנקוט בפעולה אחרת בנוגע לפעילות בלתי חוקית,
                  חשד לתרמית או עוולה אחרת;
                </LiDef>

                <LiDef>
                  על מנת להגן על הזכויות, הרכוש או הבטיחות של המפעילה, עובדיה,
                  משתמשי האתר או אחרים;
                </LiDef>

                <LiDef>
                  לבקשת מסעדה ו/או בית עסק ו/או משתמש אחר במקרה של מחלוקת או כל
                  בעיה אחרת שאירעה עם מי ממשתמשי האתר;
                </LiDef>

                <LiDef>
                  בקשר לעסקה תאגידית מהותית, כגון מכירת עסקי המפעילה, הסרת
                  השקעות, מיזוג, איחוד, או מכירת נכסים, או במקרה של פירוק.
                </LiDef>
              </Ul>
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong> מידע מצטבר ובלתי אישי</strong>

          <Ol>
            <Li>
              המפעילה עשויה לעשות שימוש במידע מצטבר ובלתי אישי שהיא אספה בכל אחת
              מהנסיבות כאמור לעיל. המפעילה עשויה לשלב מידע בלתי-אישי שהיא אספה
              עם מידע בלתי אישי נוסף שנאסף ממקורות אחרים. כמו כן, המפעילה רשאית
              לחלוק מידע מצטבר עם צדדים שלישיים לרבות יועצים, מפרסמים ומשקיעים,
              למטרת ביצוע ניתוח עסקי כללי. למשל, המפעילה רשאית לדווח למפרסמים
              שלה אודות מספר המבקרים באתר והמאפיינים או השירות הפופולאריים
              ביותר. מידע כאמור אינו כולל כל מידע אישי וניתן יהא לעשות בו שימוש
              על מנת לפתח תוכן ושירות שיסייעו למשתמש ועל מנת להתאים תוכן ופרסום.
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong> צדדים שלישיים</strong>

          <Ol>
            <Li>
              <strong>6.1. </strong>
              ישנם מספר מקומות באתר שבהם יכול המשתמש להקליק על קישור לשם גישה
              לקישורים לאתרים שאינם כפופים למדיניות פרטיות זו ואינם קשורים
              למפעילה לרבות, אך לא רק, קישורים המובילים לאתרים של מסעדות.
              בקישורים הללו עשויים לבקש מהמשתמש ו/או לאסוף מידע עליו באופן עצמאי
              לרבות מידע אישי מהמשתמש ובמקרים מסוימים לספק למפעילה מידע אודות
              פעילות המשתמש בקישורים הללו.
            </Li>

            <Li>
              <strong>6.2. </strong>
              כל צד שלישי אשר עשויה המפעילה לגלות לו מידע אישי בכפוף למדיניות
              פרטיות זו, עשוי להיות בעל מדיניות פרטיות משלו אשר מתארת כיצד הינו
              עושה שימוש ומגלה מידע אישי. המדיניות כאמור תסדיר את השימוש, הטיפול
              והגילוי של המידע האישי של המשתמש לאחר שהמפעילה חלקה אותו עם צדדים
              שלישיים כאמור במדיניות פרטיות זו. אם ברצונך לקבל מידע נוסף אודות
              מדיניות הפרטיות של אותם צדדים שלישיים, המפעילה מעודדת אותך לבקר
              בקישורים של צדדים שלישיים כאמור.
            </Li>

            <Li>
              <strong>6.3. </strong>
              המפעילה עשויה להרשות לצדדים שלישיים, לרבות ספקי השירות המורשים
              שלה, חברות פרסום ורשתות פרסום להציג פרסומות באתר. החברות הללו
              עשויות לעשות שימוש בטכנולוגיות מעקב, כגון "Cookies", על מנת לאסוף
              מידע אודות משתמשים אשר צופים או מבצעים אינטראקציה עם הפרסומות
              שלהן. השימוש שחברות אלו עושות ב"Cookies" כפוף למדיניות הפרטיות
              שלהן ולא למדיניות הפרטיות של המפעילה.
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong>בחירות המשתמש אודות איסוף ושימוש במידע</strong>

          <Ol>
            <Li>
              <strong>7.1. </strong>
              המשתמש רשאי לבחור שלא לספק למפעילה מידע מסוים, אולם הדבר יגרום לכך
              שלא יוכל לעשות שימוש במאפיינים מסוימים באתר מכיוון שמידע כאמור
              נדרש על מנת שירשם לשירותי המפעילה לרבות לצורך התקשרות עם מסעדות.
            </Li>

            <Li>
              <strong>7.2. </strong>
              כאשר המשתמש נרשם לשירות ואישר קבלת דיוור ישיר כמפורט בתנאי השימוש,
              תוכל המפעילה בכפוף לכל דין לשלוח לו דיוורים ישירים לרבות, אך לא
              רק, הודעות דוא"ל ו/או עלונים אודות עדכוני שירות, שיפורים, הצעות
              מיוחדות, או תוכן מיוחד. המפעילה עשויה לשלוח למשתמש סוגים אחרים של
              הודעות דוא"ל, כגון הודעות שירות, הודעות פרסום, וסקרים. אם כבר מסרה
              המפעילה את המידע אודות המשתמש לצד שלישי לפני ששינה את ההעדפות שלו
              או עדכן את המידע שלו, ייתכן ויהא המשתמש מחויב לשנות את ההעדפות שלו
              במישרין מול צד שלישי כאמור.
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong>שימוש בטכנולוגיות איסוף מידע</strong>

          <Ol>
            <Li>
              <strong>8.1. </strong>
              המפעילה עשויה לעשות שימוש בטכנולוגיות שונות על מנת לאסוף מידע
              מהמכשיר של המשתמש ואודות פעילות המשתמש באתר.
            </Li>

            <Li>
              <strong>8.2. </strong>
              המפעילה עשויה לעשות שימוש בטכנולוגיית אינטרנט סטנדרטית, כגון
              אלומות אינטרנט (web beacons), אנאליטיקס וטכנולוגיות דומות, על מנת
              לעקוב אחר שימוש המשתמש באתר. כמו כן, המפעילה עשויה לכלול את האמור
              לעיל בהודעות דוא"ל או עלונים לקידום מכירות, על מנת לקבוע האם
              ההודעות נפתחו והאם ננקטה פעולה בעקבותיהם. המידע שתשיג המפעילה
              באופן זה, יאפשר לה להתאים את השירותים שהיא מציעה למשתמש, על מנת
              לפרסם פרסומים מותאמים אישית ולמדוד את האפקטיביות הכוללת של פרסום
              מקוון, תוכן, תכנות או פעילויות אחרות שלה.
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong>אבטחת מידע</strong>

          <Ol>
            <Li>
              <strong>9.1. </strong>
              המפעילה מיישמת באתר מערכות ונהלים עדכניים לאבטחת מידע. מערכות
              ונהלים אלה מצמצמים את הסיכונים לחדירה בלתי מורשית, אך אין הם
              מעניקים ביטחון מוחלט. המפעילה אינה מתחייבת ששירותיה יהיו חסינים
              באופן מוחלט מפני גישה בלתי מורשית למידע המאוחסן באתר.
            </Li>

            <Li>
              <strong>9.2. </strong>
              המפעילה בוחנת מעת לעת את אבטחת המידע של האתר ומבצעת שינויים
              ושדרוגים בהתאם לצורך, על מנת לשמור על רמת אבטחת המידע של האתר. עם
              זאת, המפעילה לא תהא אחראית לכל נזק, ישיר או עקיף, אשר יגרם למשתמש
              במקרה של חשיפת המידע עקב חדירה בלתי מורשית של צדדים שלישיים או
              כתוצאה ממעשה או מחדל שאינם בשליטת המפעילה.
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong>זכות לעיין במידע ולעדכנו</strong>

          <Ol>
            <Li>
              <Typography paragraph>
                <strong>10.1. </strong>
                על פי חוק הגנת הפרטיות, תשמ"א-1981, לכל אדם הזכות לעיין במידע
                המוחזק עליו במאגר המידע של המפעילה אשר נמצא בהליכי רישום. משתמש
                שעיין במידע עליו ומצא שהמידע אינו נכון, שלם, ברור או מעודכן,
                רשאי לפנות לבעל מאגר המידע בבקשה לתקן ו/או לעדכן ו/או למחוק את
                המידע המוחזק עליו כאמור לעיל.
              </Typography>
              <Typography>
                פנייה כזאת יש להפנות למפעילה בדוא"ל שכתובתו היא
                INFO@TAP2DINE.COM. מובהר בזאת כי ייתכנו שינויים בפרטי הקשר אשר
                צוינו לעיל ובמועדי זמינות המפעילה על פי שיקול דעתה הבלעדי של
                המפעילה וללא מתן הודעה מוקדמת ולמשתמש לא יהיו טענות נגדה בשל כך.
              </Typography>
            </Li>

            <Li>
              <strong>10.2. </strong>
              אם המידע שבמאגר המידע של המפעילה משמש לצורך פניה אישית למשתמש, הוא
              רשאי על פי חוק לדרוש בכתב כי המידע המתייחס אליו יימחק ממאגר המידע.
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong>שינויים במדיניות הפרטיות</strong>

          <Ol>
            <Li>
              <strong>11.1. </strong>
              המפעילה רשאית לשנות מעת לעת את הוראות מדיניות פרטיות זו. אם יבוצעו
              שינויים מהותיים בהוראות שעניינן השימוש במידע אישי שמסר המשתמש,
              תפורסם על כך הודעה באתר המפעילה.
            </Li>
          </Ol>
        </Block>
      </Container>
    </Box>
  )
}

export default PrivacyPolicyHe
