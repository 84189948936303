import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import InfoIcon from '@/components/icons/InfoIcon'
import type IOption from '@/types/Option'

interface Props {
  option: IOption
}

const OptionError = ({ option }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation('product')

  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="center"
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      borderRadius="30px"
      zIndex="3"
      sx={{
        background: 'rgba(50, 19, 19, 0.60)',
        backdropFilter: 'blur(2.5px)',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={2.5}
        borderRadius="0px 0px 10px 10px"
        px={5}
        py={2.5}
        sx={{
          background: theme.palette.primary.midtone,
          [theme.breakpoints.down('md')]: {
            p: 2.1,
          },
        }}
      >
        <Box
          flexShrink="0"
          sx={{
            [theme.breakpoints.down('md')]: {
              svg: {
                width: 30,
              },
            },
          }}
        >
          <InfoIcon />
        </Box>

        <Typography
          variant="body"
          fontWeight="600"
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '16px',
            },
          }}
        >
          {t('minChoiceError').replace(
            '%min',
            option.minimumChoices.toString(),
          )}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default OptionError
