interface Props {
  size?: number
}

const OrganicIcon = ({ size = 24 }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect width="24" height="24" rx="5" fill="#4CAF50" />
      <path
        d="M8.06467 19C8.06467 19 8.4541 15.3583 11.1801 12.1213M17.5122 5.43844L17.9748 10.4356C18.2661 13.5804 15.9965 16.3804 12.9707 16.6831C10.0009 16.9801 7.31074 14.7247 7.0249 11.639C6.88754 10.1572 7.32233 8.67939 8.23362 7.53068C9.14491 6.38197 10.458 5.65644 11.8842 5.5137L17.002 5.00225C17.0631 4.99611 17.1248 5.00255 17.1835 5.02119C17.2423 5.03984 17.2969 5.07032 17.3443 5.11091C17.3916 5.15149 17.4308 5.20138 17.4597 5.25772C17.4885 5.31406 17.5063 5.37493 17.5122 5.43844Z"
        stroke="#FEFEFE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default OrganicIcon
