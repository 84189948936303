import { useProcessTranslationObject } from '@/services/translation'
import type ChoiceType from '@/types/Choice'
import ChoicePrice from './ChoicePrice'
import ChoiceTitle from './ChoiceTitle'
import ChoiceWrapper from './ChoiceWrapper'

interface Props {
  choice: ChoiceType
  onSelect: () => void
}

const Choice = ({ choice, onSelect }: Props) => {
  const { processTranslationObject } = useProcessTranslationObject()
  const isSelected = choice.quantity > 0

  return (
    <ChoiceWrapper isSelected={isSelected} onSelect={onSelect}>
      <ChoiceTitle
        title={processTranslationObject(choice.name)}
        isSelected={isSelected}
      />

      <ChoicePrice price={choice.price} isSelected={isSelected} />
    </ChoiceWrapper>
  )
}

export default Choice
