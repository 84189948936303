import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Checkbox, Link, Stack, useTheme } from '@mui/material'
import InfoModal from '@/components/home/InfoModal'
import useCartStore from '@/store/useCartStore'
import { usePaymentsList } from '@/utils/consts'
import PaymentOptionButton from '../../components/payment/PaymentOptionButton'
import useSettings from '../../hooks/useSettings'
import PaymentLayout from '../../layouts/PaymentLayout'

const Payment = () => {
  const theme = useTheme()
  const { t } = useTranslation('payment')

  const { data: settings } = useSettings()

  const PAYMENTS_LIST = usePaymentsList()
  const { termsAccepted, setTermsAccepted, orderType } = useCartStore((s) => s)
  const isDelivery = orderType === 'DELIVERY'

  const [open, setOpen] = useState<boolean>(false)
  const [step, setStep] = useState<number | null>(null)

  const getPath = (paymentMethod: string) => {
    return settings?.type === 'KIOSK' ||
      (settings?.type === 'PORTAL' && termsAccepted)
      ? PAYMENTS_LIST[paymentMethod].url
      : ''
  }

  const getBtnStyle = () => {
    return settings?.type === 'PORTAL'
      ? { transition: '.3s', opacity: termsAccepted ? 1 : 0.5 }
      : {}
  }

  const openPolicy = (step: number) => {
    setStep(step)
    setOpen(true)
  }

  return (
    <PaymentLayout
      title={t('generalTitle')}
      subTitle={t('generalSubTitle')}
      backTo="/menu"
      showDeliverySteps
      deliveryStep={3}
      allowBack={!isDelivery}
    >
      <Stack
        direction="column"
        width="100%"
        maxWidth="700px"
        mx="auto"
        gap={7.7}
        sx={{
          [theme.breakpoints.down('md')]: {
            gap: '10px',
          },
        }}
      >
        {settings?.type === 'PORTAL' && (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            py={2}
            px={1}
            gap="10px"
            sx={{
              border: '1px solid #fff',
              borderRadius:
                settings?.type === 'PORTAL' && !termsAccepted
                  ? '10px'
                  : undefined,
              transition: '.3s',
              borderColor:
                settings?.type === 'PORTAL' && !termsAccepted ? 'red' : '#fff',
            }}
          >
            <Checkbox
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
            />
            <Box width="80%" fontSize={14}>
              {t('generalAgreeText')}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link onClick={() => openPolicy(3)}>
                {t('termsOfServiceTitle')}
              </Link>
              {t('generaAgreeAnd')}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link onClick={() => openPolicy(2)}>
                {t('privatePolicyTitle')}
              </Link>
            </Box>
          </Stack>
        )}

        {settings?.paymentMethods.map((paymentMethod, index) => {
          const paymentMethodInfo = PAYMENTS_LIST[paymentMethod]

          if (paymentMethodInfo) {
            const PaymentMethodIcon = PAYMENTS_LIST[paymentMethod].icon

            return (
              <Box key={paymentMethodInfo.name + index} sx={getBtnStyle()}>
                <PaymentOptionButton
                  to={getPath(paymentMethod)}
                  name={PAYMENTS_LIST[paymentMethod].name}
                  icon={
                    <PaymentMethodIcon color={theme.palette.primary.dark} />
                  }
                />
              </Box>
            )
          }
          return null
        })}
      </Stack>

      <InfoModal open={open} setOpen={setOpen} currentStep={step} />
    </PaymentLayout>
  )
}

export default Payment
