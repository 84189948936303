/* eslint-disable react/no-unescaped-entities */
import { Box, SxProps, Typography } from '@mui/material'
import { Block, Container, Li, Ol } from '@/components/home/documents/styled'

const TermsOfServiceHe = ({ sx }: { sx: SxProps }) => {
  return (
    <Box sx={sx} dir="RTL">
      <Typography variant="h6" component="h1" gutterBottom>
        תנאי שימוש – tap2dine
      </Typography>

      <Typography paragraph>
        עודכן לאחרונה בתאריך: ______09/07/2024___
      </Typography>

      <Container>
        <Block>
          <strong>מבוא</strong>

          <Ol>
            <Li>
              <strong>1.1. </strong>
              ברוכים הבאים לאתר ו/או לאפליקציה " tap2dine" (להלן: "
              <strong>האתר</strong>" או "<strong>השירות</strong>
              ") המנוהל ומופעל על ידי "<strong>דמקה מערכות בע"מ</strong>
              ", ח.פ. 516749520 (להלן: "<strong>המפעילה</strong>
              ") המשמש בין היתר להזמנה ואיסוף של משלוחים ממסעדות ו/או עסקים
              שונים המציעים הזמנות באמצעות שליח באתר (להלן: "
              <strong>המסעדות</strong>" או "<strong>העסקים</strong>
              ").
            </Li>

            <Li>
              <strong>1.2. </strong> השימוש באתר הינו למטרות חוקיות בלבד ובכפוף
              לתנאי השימוש המפורטים להלן (להלן: "<strong>תנאי השימוש</strong>
              ") לרבות האמור במדיניות הפרטיות ובכפוף לכל דין ואלה מהווים את
              הבסיס המשפטי לכל התקשרות או פעולה שתיעשה במסגרת זו. השימוש באתר
              לרבות גלישה ו/או ביקור באתר ו/או הזמנה באמצעות האתר מהווים הסכמה
              מפורשת מצדך המשתמש לתנאים כאמור.
            </Li>

            <Li>
              <strong>1.3. </strong>
              המפעילה שומרת לעצמה את הזכות להפסיק את שימושך באתר אם לא תעמוד
              באחד או יותר מתנאי השימוש המפורטים להלן, ושומרת לעצמה את הזכות
              לעדכן את תנאי השימוש מעת לעת, לפי שיקול דעתה הבלעדי וללא צורך
              במסירת הודעה כלשהי מראש. ויובהר, נוסח תנאי השימוש המחייב והקובע
              הינו הנוסח המפורסם באתר.
            </Li>

            <Li>
              <strong>1.4. </strong>
              טרם ביצוע פעולה כלשהי באתר מתבקש המשתמש לקרוא, בעיון רב, את תנאי
              השימוש לרבות מדיניות הפרטיות. אם אינך מסכים לכל תנאי השימוש – נא
              הימנע מכל שימוש באתר.
            </Li>

            <Li>
              <strong>1.5. </strong>
              בתנאי השימוש ובכל הטקסטים המופיעים באתר, לשון יחיד משמעו גם לשון
              רבים ולהיפך, ולשון זכר משמעו לשון נקבה ולהיפך, הכל לפי ההקשר. תנאי
              השימוש מנוסחים בלשון זכר מטעמי נוחות בלבד ואין בהם משום פגיעה ו/או
              אפליה מכל סוג שהוא.
            </Li>

            <Li>
              <strong>1.6. </strong>
              ייתכן והאתר יהיה מושבת עקב תקלות שאינן בשליטת המפעילה אשר עלולות
              להתרחש בכל עת ו/או עקב עבודות תחזוקה שונות ו/או מסיבות אחרות,
              ולמשתמש לא יהיו טענות נגד המפעילה בשל כך.
            </Li>

            <Li>
              <strong>1.7. </strong>
              מובהר בזאת כי המפעילה רשאית לשנות ו/או להפסיק בכל עת את פעילות
              האתר במלואו או בחלקו לפי שיקול דעתה הבלעדי וללא כל צורך בהודעה
              מוקדמת על כך מבלי שלמשתמש יהיו טענות נגדה בשל כך.
            </Li>

            <Li>
              <strong>1.8. </strong>
              בכל שאלה, הבהרה, הערה או בעיה הנוגעים לאתר, ניתן ליצור קשר עם
              המפעילה, הכל בהתאם לשעות הפעילות של המפעילה ובדרכים אשר ייקבעו מעת
              לעת על פי שיקול דעתה.
            </Li>

            <Li>
              <strong>1.9. </strong> מובהר בזאת, כי רק האמור בהסכם זה יחייב את
              המפעילה. כל תוכן המופיע מטעם מסעדות באתר לרבות, אך לא רק,
              התפריטים, התמונות, המוצרים ותיאוריהם אינו מהווה המלצה או הבעת עמדה
              של המפעילה לגבי נכונות או כדאיות ההזמנה ממסעדות.
            </Li>

            <Li>
              <strong>1.10. </strong>
              תנאי שימוש אלה מהווים את כלל ההסכם בין הצדדים וגוברים על כל הסכם,
              הצעה או מצג שנעשו בעבר, בכתב או בעל פה, בנוגע לנושאו. בכל מקרה של
              סתירה ו/או אי התאמה מכל סוג שהוא בין תוכן האתר לבין הוראות תנאי
              השימוש, יגברו ויחולו הוראות תנאי השימוש.
            </Li>

            <Li>
              <strong>1.11. </strong>
              <strong>
                המפעילה אינה גובה תשלומים ואינה מספקת את המוצרים. כל תשלום מתבצע
                ישירות מול המסעדות והאספקה מתבצעת על ידי המסעדות בלבד. המפעילה
                משמשת כפלטפורמה לחיבור בין משתמשים למסעדות ואינה אחראית על
                איכות, טריות או כל פרט אחר הקשור למוצרים שהוזמנו דרך המסעדות. כל
                התשלומים עבור המוצרים יתבצעו ישירות מול המסעדות, והמפעילה אינה
                גובה תשלום עבור שירותיה (אך תהיה רשאית לגבות בעתיד בהתאם לשיקול
                דעתה).
              </strong>
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong>הגדרות</strong>

          <Ol>
            <Li>
              <strong>"התוכן"</strong> משמעותו האתר ותכנים המועלים ומוצגים על
              ידי המסעדות לרבות, אך לא רק, תפריטים, תמונות, מחירים ומבצעים,
              מוצרים ומרכיביהם, הדפים המקוונים הכלולים בהם, הסיווג, הסידור
              וההצגה של המידע וכל צורת המחשה אחרת הכלולים בהם, בדוורים
              ובמסרונים.
            </Li>

            <Li>
              <strong>"השירות"</strong> משמעותו האתר, לרבות הוראות והנחיות
              נלוות, מוצרים ושירותים קשורים אחרים, שירותי הזמנה דרך האתר, שירותי
              תמיכה אישית נלווית, אם וככל שיינתנו, וכל שינוי ו/או תיקון של האמור
              לעיל כפי שעשויים להיות מושלמים על ידי המפעילה מעת לעת לפי שיקול
              דעתה הבלעדי.
            </Li>

            <Li>
              <strong>"המסעדות" או "העסקים"</strong> משמעותם כל עסק ו/או מסעדה
              אשר מהם ניתן לרכוש ו/או להזמין באתר.
            </Li>

            <Li>
              <strong>"החשבון"</strong> משמעותו החשבון שנפתח במסגרת השירות על
              שמך, או כל שימוש שנעשה בשירות שלנו ללא פתיחת חשבון.
            </Li>

            <Li>
              <strong>"אתה" ו"המשתמש" ו"המזמין"</strong>, משמעותם, בין היתר, כל
              משתמש באתר וכן יחידים וישויות המורשים על ידי המפעילה לעשות שימוש
              בשירות וכן העובדים, היועצים, הקבלנים והסוכנים שלך ו/או צדדים
              שלישיים מטעמך.
            </Li>

            <Li>
              <strong>"הנתונים שלך"</strong> משמעותם כל הנתונים ו/או המידע
              האלקטרוניים המוגשים על ידך ו/או הנאספים על ידי המפעילה במהלך
              השימוש באתר.
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong>אחריות ואי נטילת אחריות</strong>

          <Ol>
            <Li>
              <strong>3.1. </strong>
              <strong>
                באחריותו הבלעדית של המשתמש לוודא את נכונות מצגי המסעדות בנוגע
                לאיכות ו/או לסוג המזון המוצע על ידם לרבות, אך לא רק, טריות
                המזון, מרכיבי המזון, כשרות המזון, מידע על אורגניות המזון ו/או
                מידע על אלרגנים ורגישויות שונות הנוגעות למרכיבים שונים במזון
                (לרבות, אך לא רק, הכללתו של גלוטן, סוכר, לקטוז, אגוזים ועוד
                במזון). ויודגש – המפעילה אינה מפקחת על מצגי המסעדות כאמור לעיל
                ואינה אחראית לכל שגיאה ו/או מצג שווא בנוגע לתוכן המוצג על ידי
                המסעדות.
              </strong>
            </Li>

            <Li>
              <strong>3.2. </strong>
              <strong>
                המפעילה אינה אחראית לכל נזק שייגרם למזמין ו/או לצדדים שלישיים
                כתוצאה משימוש במוצרים שהוזמנו לרבות מקרים בהם המוצר היה פגום עקב
                רשלנות של המסעדה ו/או מקרים בהם המוצר היה לא טרי ו/או מקולקל
                ו/או לא תואם את שביעות רצונו של המזמין.
              </strong>
            </Li>

            <Li>
              <strong>3.3. </strong>
              <strong>
                בכל שאלה ו/או הבהרה בנוגע למידע על מזון יש ליצור קשר ישירות עם
                המסעדה המציעה את שירותיה על גבי האתר, אשר משמשת בין השאר
                פלטפורמה בלבד להזמנת מוצרי מזון ממסעדות.
              </strong>
            </Li>

            <Li>
              <strong>3.4. </strong>
              התוכן המופיע ומועלה על ידי מסעדות האתר נערך על ידי מסעדות המשתמשים
              בשירותי המפעילה. יובהר כי אין למפעילה שליטה על מקורות המידע והתוכן
              ולפיכך המפעילה אינה נושאת באחריות כלשהי למידע ולתוכן המתפרסמים על
              ידי מסעדות באתר ואינה ערבה לטיבם, נכונותכם, תקפותם, שלמותם, דיוקם
              ו/או התאמתם לכל מטרה שהיא.
            </Li>

            <Li>
              <strong>3.5. </strong>
              המפעילה לא תישא באחריות לכל נזק או פגיעה, ישירים או עקיפים, מכל
              סוג שהוא, שיגרמו למשתמש ו/או לצדדים שלישיים כתוצאה משימוש
              ומהסתמכות על התוכן והשירות המוצעים על ידי מסעדות באתר ו/או מכל
              שימוש של המשתמש במוצרים שהוזמנו לרבות צריכתם. למשתמש לא תהיה כל
              טענה בגין הסתמכותו על המידע והתוכן שפורסמו על ידי מסעדות באתר ועל
              כך כי כפועל יוצא מכך לא עשה שימוש בשירותים אחרים. למשתמש לא תהיה
              כל טענה לגבי מידע ותוכן שפורסמו באתר, והמפעילה לא תישא באחריות
              כלפי המשתמש בנוגע למידע ותוכן שפורסמו באתר ו/או מידע שהגיע לצד
              שלישי דרך האתר.
            </Li>

            <Li>
              <strong>3.6. </strong>
              המפעילה אינה מתחייבת כי הקישורים המצויים באתר ו/או במסגרת הדיוורים
              ו/או המסרונים יהיו תקינים ויובילו לאתר פעיל. הימצאותו של קישור
              לאתר של צד שלישי באתר אין משמעותו כי תוכן האתר של הצד השלישי הינו
              מהימן ו/או מלא ו/או עדכני, והמפעילה לא תישא בכל אחריות בקשר לכך.
              מבלי לגרוע מהאמור לעיל, המפעילה אינה אחראית לכל נזק, עקיף או ישיר,
              שיגרם למשתמש ו/או לרכושו כתוצאה משימוש ו/או הסתמכות על המידע
              והתכנים המופיעים באתרים אליהם יגיע באמצעות או דרך שימוש או קישור
              הקיים האתר.
            </Li>

            <Li>
              <strong>3.7. </strong>
              יתכן כי השירותים באתר יופרעו ו/או ילוו בשגיאות ו/או לא יינתנו
              כסדרם ללא הפסקות והפרעות. המפעילה אינה מתחייבת לכך שהאתר יהיה חסין
              מפני גישה לא חוקית למחשבי המפעילה, נזקים, קלקולים, תקלות, כשלים
              בחומרה, תוכנה או בקווי התקשורת אצל המפעילה או מי מספקיה או ייפגעו
              מכל סיבה אחרת. המפעילה לא תישא בכל אחריות, מכל סוג, לכל נזק, ישיר
              או עקיף, שייגרמו למשתמש ו/או לצד שלישי כלשהו, או לרכושם עקב כך.
            </Li>

            <Li>
              <strong>3.8. </strong>
              המידע והשירותים הזמינים במסגרת השירות הניתן באתר עשויים לכלול
              אי-דיוקים או טעויות סופר. המפעילה אינה מתחייבת ואינה מצהירה כי
              השירות יהיה ללא הפרעות או ללא תקלות, או כי תקלות תתוקנה, או כי
              השירות או השרת המספק את השירות יהיו נקיים מווירוסים או מרכיבים
              מזיקים אחרים. המפעילה אינה מתחייבת ו/או מצהירה כי השימוש או תוצאות
              השימוש בשירות או בחומרים המסופקים באמצעות השירות יהיו מדויקים,
              נכונים, מהימנים או אמינים בכל דרך.
            </Li>

            <Li>
              <strong>3.9. </strong>
              המשתמש הינו האחראי הבלעדי לכל התקשרות עם צדדים שלישיים, לרבות אך
              לא רק שיחה ו/או התקשרות חוזית עם המסעדה. המשתמש מצהיר כי ידוע לו
              כי המפעילה אינה בהכרח מאמתת את כלל המידע המופיע על המסעדה באתר ולא
              יהיו לו טענות נגד המפעילה בשל כל אי התאמה אשר תוצאתה במידע כוזב
              אשר התקבל מכל מסעדה באתר. אין באמור כדי לגרוע מזכותה של המפעילה
              לערוך אימות, בדיקה ו/או סינון של תכנים המועלים על ידי המסעדה ככל
              שתמצא לנכון לעשות כן.
            </Li>

            <Li>
              <strong>3.10. </strong>
              אין המפעילה אחראית על תוכנן של מודעות הפרסום או הצעות מסחריות של
              צדדים שלישיים המופיעים באתר לרבות כל מודעה ו/או הצעה מסחרית
              המועלים על ידי מסעדות המופיעים באתר. כל טענה, תביעה או דרישה מצד
              משתמש בגין הצעות, מוצרים או שירותים כאמור, קבלתם או תוצאותיהם,
              יופנו על ידו במישרין אל הצד השלישי לרבות המסעדות שמהן רכש ו/או
              קיבל הצעות רכישה, ולא תהיה לו בגינן כל תביעה או דרישה כלפי המפעילה
              ו/או מי מטעמה.
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong>הזמנות באתר</strong>

          <Ol>
            <Li>
              <strong>4.1. </strong>
              באתר ניתן לגשת לאתרים של מסעדות המציעים הזמנות כפי שהינם מפורטים
              ומופיעים באתר. עם זאת המפעילה רשאית בכל עת לגרוע או להפסיק את
              אפשרות ההזמנה ממסעדות מסוימות וכן להוסיף אפשרות הזמנה ממסעדות
              חדשות ו/או לשנות את מגוון מסעדות שמהם ניתן להזמין באתר, הכל על פי
              שיקול דעתה הבלעדי ובלא מתן הודעה מוקדמת, ומבלי שלמשתמש יהיו טענות
              נגדה בשל שינוי כאמור לעיל.
            </Li>

            <Li>
              <strong>4.2.</strong>
              יובהר בזאת כי תמונות המוצרים באתר מועלות על ידי מסעדות, ובכל מקרה
              הינן לצרכי המחשה בלבד אלא אם צוין אחרת בדף המוצר ואינן מחייבות
              באופן כלשהו את המפעילה.
            </Li>

            <Li>
              <strong>4.3. </strong>
              חלק מהמוצרים אשר ניתנים להזמנה באתר מכילים אלכוהול לצריכה. מובהר
              בזאת כי מכירת מוצרים אלו מותרת אך ורק למשתמשים בגירים אשר גילם הוא
              18 שנים ומעלה ובהתאם לדין הישראלי. בעת הזמנת מוצרים אלו המשתמש
              מצהיר כי הינו בגיר מעל גיל 18 הרשאי לרכוש מוצרים אלו על פי חוק.
              כמו כן לא ניתן לספק משקאות אלכוהוליים בין השעות 23:00 ל-06:00.
            </Li>

            <Li>
              <strong>4.4. </strong>
              פרסום ו/או הצגת המוצרים המוצעים למכירה באתר אינם מהווים המלצה ו/או
              עידוד לרכישתם והמפעילה אינה אחראית לכל שימוש של המזמינים במוצרים
              שיירכשו.
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong>ביצוע הזמנות, תשלום, אספקת המוצרים ומשלוחים</strong>
          <Ol>
            <Li>
              <strong>5.1. </strong>
              התשלום עבור ההזמנה מתבצע באמצעות כרטיס אשראי בכפוף לאישור חברת
              האשראי ומשולם ישירות למסעדות. יובהר כי אופן התשלום נקבע על ידי
              המסעדות בלבד, וכל מסעדה רשאית לשנות את דרכי ו/או אמצעי התשלום ו/או
              את דרכי ביצוע ההזמנה בכל עת לפי שיקול דעתה הבלעדי ובלא מתן הודעה
              מוקדמת מבלי שלמשתמש יהיו טענות בשל כך. אספקת המוצרים אשר נרכשו
              ואשר התמורה המלאה בגינם שולמה תיעשה על ידי המסעדות עצמן באמצעות
              שירות שליחים או איסוף עצמי.
            </Li>

            <Li>
              <strong>5.2. </strong>
              אספקת המוצרים אשר נרכשו ואשר התמורה המלאה בגינם שולמה תיעשה
              באמצעות שירות שליחים של כל אחת מהמסעדות לאחת הכתובות אשר ניתן
              לבחור באתר. יובהר כי ייתכן וכתובות המשלוח ישתנו מעת לעת על פי
              שיקול דעתה הבלעדי של המסעדות ובלא מתן הודעה מוקדמת ולמשתמש לא יהיו
              טענות נגד המפעילה בשל כך. לחלופין המשתמש יוכל לבחור באיסוף עצמי
              ו/או הושבה במקום.
            </Li>

            <Li>
              <strong>5.3. </strong>
              במידה ועל ההזמנה יחולו דמי משלוח, המשתמש יחויב בדמי משלוח עם הזמנת
              המוצרים בעלות אשר מופיעה באתר שהינם בנוסף לעלות ההזמנה הכוללת של
              המוצרים. דמי המשלוח נקבעים על ידי מסעדות ו/או המפעילה אשר עלולים
              לעדכנם מעת לעת על פי שיקול דעתם הבלעדי ובלא מתן הודעה מוקדמת מבלי
              שלמשתמש יהיו טענות נגד המפעילה בשל כך.
            </Li>

            <Li>
              <strong>5.4. </strong>
              יובהר כי מועדי האספקה נקבעים על ידי מסעדות אשר ביכולתם לעדכן את
              מועדי האספקה מעת לעת על פי שיקול דעתם הבלעדי ובלא מתן הודעה
              מוקדמת, ולמשתמש יהיו טענות נגד המפעילה בשל כך.
            </Li>

            <Li>
              <strong>5.5. </strong>
              ייתכנו עיכובים במועד ההזמנה עקב סיבות שאינן תלויות במפעילה ואינן
              בשליטתה לרבות, אך לא רק, כוח עליון, מצב בטחוני רגיש באזור המשלוח,
              מזג אוויר קיצוני, מפגעי טבע (רעידת אדמה, שיטפון וכו') ומפגעים
              אחרים (כגון: שריפה, חסימות כבישים וכו') ולמזמין לא יהיו טענות נגד
              המפעילה ו/או מי מטעמה בשל עיכוב כאמור.
            </Li>

            <Li>
              <strong>5.6. </strong>
              אופן אריזת המוצרים ייעשה לפי שיקול דעתם של המסעדה, ולמזמין לא תהיה
              כל טענה נגד המפעילה בשל כך.
            </Li>

            <Li>
              <strong>5.7. </strong>
              יובהר שוב כי המפעילה משמשת כפלטפורמה לחיבור בין משתמשים למסעדות
              ואינה אחראית על אספקת המזון, גביית התשלומים או כל היבט אחר של
              השירותים הניתנים על ידי המסעדות.
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong>שינוי וביטול הזמנה, ביטול עסקה</strong>
          <Ol>
            <Li>
              <strong>6.1. </strong>
              מדיניות החזרת המוצרים וביטול העסקאות בשירות הינה בכפוף להוראות חוק
              הגנת הצרכן תשמ"א-1981, והמזמין רשאי לבטל את הזמנתו ו/או להחזיר את
              המוצרים שהגיעו אליו בכפוף להוראות שלהלן.
              <strong>
                {' '}
                יובהר כבר עתה כי על אף האמור לעיל ובהתאם להוראות הדין, לא ניתן
                להחזיר טובין פסידים לרבות מוצרי מזון ו/או מוצר אשר נעשה בו שימוש
                ו/או מוצר אשר חלף תאריך תפוגתו.
              </strong>
            </Li>

            <Li>
              <strong>6.2. </strong>
              לאור האמור לעיל, כל ביטול של עסקה, לאחר הזמנתה, הינו לשיקול דעת
              המסעדה בלבד אשר תהא רשאית לסרב לבטל הזמנה ו/או ליתן החזר כספי
              כלשהו בגין הזמנה שבוצעה.
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong>הצטרפות מסעדות</strong>

          <Ol>
            <Li>
              <strong>7.1. </strong>
              על מנת להצטרף כמסעדה לשירותי המפעילה, על המשתמש למלא פרטים מסוימים
              כפי שייקבעו על ידי המפעילה, כאשר יובהר כי המפעילה רשאית לעדכן ו/או
              לשנות את הפרטים אשר יתבקשו על ידה בכל עת ועל פי שיקול דעתה. יובהר
              כי המפעילה שומרת על זכותה לדחות בקשה להירשם כמסעדה באתר מכל סיבה
              שהיא על פי שיקול דעתה הבלעדי ומבלי שתחול עליה החובה לנמק את
              סירובה.
            </Li>

            <Li>
              <strong>7.2. </strong>
              מובהר כי המסעדה מתחייבת כי כל המידע שיימסר על ידה וכל תשובותיה
              יכללו מידע מהימן ומדויק, והיא יודעת כי מסירת פרטים שגויים ו/או
              כוזבים תביא, בין השאר, לחסימתה מהשירות מבלי שלמסעדה תהא טענה נגד
              המפעילה בשל האמור. ככל ויחול שינוי בפרט כלשהו מהפרטים אשר נמסרו
              למפעילה על ידי המסעדה, עליה לעדכן את המפעילה בדבר שינוי כאמור
              לאלתר.
            </Li>

            <Li>
              <strong>7.3. </strong>
              למען הסר ספק יובהר כי סירוב המפעילה לאשר בקשה של מסעדה יכול וייעשה
              מכל סיבה שהיא וללא כל הנמקה. החלטת המפעילה לסרב לבקשה תהיינה סופית
              ללא מתן אפשרות ערעור, ולמסעדה לא יהיו כל טענות נגד המפעילה בשל
              סירובה כאמור.
            </Li>

            <Li>
              <strong>7.4. </strong>
              כל עסקה דרך האתר תבוצע ישירות בין המסעדה למשתמש, ובאחרית המסעדה
              להסדיר כל מחלוקת שתהיה לה, ככל ותהיה כזו, ישירות מול המשתמש,
              והמפעילה לא תהיה צד למחלוקות כאמור.
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong>קניין רוחני</strong>
          <Ol>
            <Li>
              <strong>8.1. </strong>
              כל זכויות הקניין הרוחני, לרבות הזכויות המוסריות, הזכויות הכלכליות,
              זכויות היוצרים, סימני המסחר, שמות מסחר, פטנטים, מדגמים, סודות
              מסחריים וכל זכות אחרת ביחס לאתר לרבות, אך לא רק, התוכן, העיצוב,
              העריכה וכן אופן הצגתו ועריכתו של המידע המופיע באתר והאתר עצמו
              שמורות באופן בלעדי המפעילה.
            </Li>

            <Li>
              <strong>8.2. </strong>
              מבלי לגרוע מהאמור לעיל, האתר, הדפים המקוונים הכלולים בו, הסיווג,
              הסידור וההצגה של המידע, המודעות והדיוורים שנשלחים על ידי המפעילה,
              לרבות סיווג, סידור וההצגה של המידע והמודעות המופיעים בהם וכל צורת
              המחשה אחרת הכלולים באתר ובדיוורים כאמור הנם בבעלותה הבלעדית של
              המפעילה.
            </Li>

            <Li>
              <strong>8.3. </strong>
              הבעלות וזכויות הקניין הרוחני בתכנים של צדדים שלישיים, לרבות תמונות
              אשר מופיעות באתר, הינם הרכוש של בעלי התכנים, ועשויים להיות כפופים
              לתנאי שימוש של צדדים שלישיים שהעניקו המפעילה רישיון כדין לגבי תוכן
              כאמור (להלן: <strong>"הסכמי צדדים שלישיים"</strong>
              ). המפעילה אינה טוענת לזכויות בעלות ואינה נוטלת כל אחריות לגבי כל
              סוג של תוכן מסוג זה המוצג באתר. המשתמש מאשר כי התנאים של כאמור
              יחייבו אותו בקשר עם שימוש שיעשה בשירות, לרבות כל שינויים עתידיים
              בהסכמי הצדדים השלישיים.
            </Li>

            <Li>
              <strong>8.4. </strong>
              הבעלות וזכויות הקניין הרוחני בתכנים של צדדים שלישיים, לרבות תמונות
              אשר מופיעות באתר, הינם הרכוש של בעלי התכנים, ועשויים להיות כפופים
              לתנאי שימוש של צדדים שלישיים שהעניקו המפעילה רישיון כדין לגבי תוכן
              כאמור (להלן: "הסכמי צדדים שלישיים"). המפעילה אינה טוענת לזכויות
              בעלות ואינה נוטלת כל אחריות לגבי כל סוג של תוכן מסוג זה המוצג
              באתר. המשתמש מאשר כי התנאים של הסכמי צדדים שלישיים כאמור יחייבו
              אותו בקשר עם שימוש שיעשה בשירות, לרבות כל שינויים עתידיים בהסכמי
              הצדדים השלישיים.
            </Li>

            <Li>
              <strong>8.5. </strong>
              המפעילה מכבדת את זכויותיהם של צדדים שלישיים ונוקטת בצעדים הנדרשים
              על מנת שלא להפר כל זכות של צדדים שלישיים. ככל ונפגעו זכויותיו של
              צד שלישי בתום לב, ניתן לדווח המפעילה על הפרת זכויות קניין רוחני
              בטלפון ובדוא"ל אשר פרטיהם מופיעים בתנאי שימוש אלה.
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong>אבטחה</strong>
          <Ol>
            <Li>
              <strong>9.1. </strong>
              האתר של המפעילה מאובטחת באמצעות שימוש באמצעי אבטחה מתקדמים אשר
              מטרתם להבטיח שימוש תקין וגלישה בטוחה באתר וכן על מנת לשמור על
              פרטיות המשתמשים. כל המשתמש באתר ובשירותיו מתחייב כי לא יעשה כל
              פעילות שיש בה כדי לשבש את פעילות האתר לרבות, אך לא רק, גניבת מידע
              על משתמשים ופריצה של מנגנוני האבטחה של האתר. המפעילה תפעל נגד כל
              פעילות כאמור בכל דרך חוקית אשר תעמוד לרשותה לרבות חסימת המשתמש
              מגישה לאתר ונקיטת הליכים משפטיים נגד המשתמש באם יפעל כאמור.
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong>תקופה וסיום</strong>
          <Ol>
            <Li>
              <strong>10.1. </strong>
              הסכם זה ייכנס לתוקף ביום בו יעשה המשתמש שימוש כלשהו באתר, ויימשך
              עד לסיום השימוש בשירות על ידי המשתמש .
            </Li>

            <Li>
              <strong>10.2. </strong>
              המפעילה רשאית בכל עת לסיים הסכם זה, ולרבות במקרים הבאים: (1)
              המשתמש הפר הוראה מהוראות הסכם זה (או פעל באופן אשר מוכיח בבירור כי
              הוא מתכוון לעשות זאת או כי אינו מסוגל לקיים את הוראות ההסכם); (2)
              המפעילה תידרש לעשות כן על פי דין (למשל, כאשר מתן השירות הינו או
              יהא בלתי חוקי); (3) מתן השירות אינו כדאי עוד מבחינה כלכלית. במקרים
              כאמור המפעילה תמסור למשתמש, ככל הניתן, הודעה סבירה מראש אודות סיום
              ההסכם.
            </Li>

            <Li>
              <strong>10.3. </strong>
              המפעילה תוכל לשנות תנאים אלה מעת לעת על פי שיקול דעתה הבלעדי.
              האחריות להתעדכן בשינויים כאמור מוטלת על המשתמש באמצעות כניסה לתנאי
              שימוש אלה מפעם לפעם. שימוש בשירות בכל עת כמוהו כהסכמה לתנאים
              המעודכנים והמתוקנים, כפי שהיו באותה עת.
            </Li>

            <Li>
              <strong>10.4. </strong>
              המפעילה שומרת לעצמה את הזכות לשנות או להפסיק את השירות או חלק ממנו
              באתר, ללא מתן הודעה מראש. במקרה של הפסקת השירות, המפעילה לא תהא
              אחראית כלפי המשתמש או כלפי צד שלישי כלשהו בגין נזק או אובדן.
            </Li>

            <Li>
              <strong>10.5. </strong>
              ההוראות בדבר קניין רוחני ואי נטילת אחריות, תוקפן יימשך לאחר סיום
              ההסכם.
            </Li>
          </Ol>
        </Block>

        <Block>
          <strong>סמכות שיפוט והודעות</strong>
          <Ol>
            <Li>
              <strong>11.1. </strong>
              על תנאי שימוש אלה יחולו אך ורק דיני מדינת ישראל. מקום השיפוט
              הבלעדי לכל עניין בנוגע לתנאי שימוש אלה והשימוש באתר הינו בבתי
              המשפט המוסמכים בעיר תל אביב-יפו בלבד.
            </Li>

            <Li>
              <strong>11.2. </strong>
              כל מכתב שישלח למפעילה יחשב כמכתב שהגיע למענו תוך עם שליחתו בדואר
              אלקטרוני כאשר בידי השולח ישנו אישור מסירה מצד המפעילה.
            </Li>
          </Ol>
        </Block>
      </Container>
    </Box>
  )
}

export default TermsOfServiceHe
