import { Box, useTheme } from '@mui/material'

interface Props {
  children: React.ReactNode
  isSelected: boolean
  onSelect: () => void
}

const ChoiceWrapper = ({ isSelected, children, onSelect }: Props) => {
  const theme = useTheme()

  return (
    <Box
      minWidth="100px"
      px={2}
      py={3.5}
      borderRadius="20px"
      sx={{
        background: isSelected
          ? theme.palette.primary.main
          : theme.palette.common.white,
        [theme.breakpoints.down('md')]: {
          fontSize: '14px',
          p: 1,
          ml: '0 !important',
          width: '40%',
        },
      }}
      onClick={onSelect}
    >
      {children}
    </Box>
  )
}

export default ChoiceWrapper
