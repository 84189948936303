import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import useSettings from '@/hooks/useSettings'
import { isKioskWorkingHours } from '@/services/working-hours'
import useSettingsStore from '@/store/useSettinsStore'
import useCartStore from '../../store/useCartStore'
import useCategoryStore from '../../store/useCategoryStore'
import PrimaryButton from '../shared/buttons/PrimaryButton'
import CountdownTimer from '../shared/CountdownTimer'

interface Props {
  number: number
  price: number
}

const OrderSummary = ({ number, price }: Props) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation('finalScreen')
  const { data: settings } = useSettings()

  const clearCartStore = useCartStore((s) => s.clearCartStore)
  const clearCategoryStore = useCategoryStore((s) => s.clearCategoryStore)
  const clearSettingsStore = useSettingsStore((s) => s.clearSettingsStore)
  const selectedDate = useCartStore((s) => s.selectedDate)
  const orderDate = DateTime.fromISO(selectedDate as string).toFormat(
    'dd.LL.yy HH:mm',
  )

  const resetApp = () => {
    clearCartStore()
    clearCategoryStore()
    clearSettingsStore()

    if (window.navigator.onLine) {
      window.location.href = '/'
    } else {
      navigate('/')
    }
  }

  return (
    <Box
      width="100%"
      borderRadius="30px"
      px={5}
      py={7.5}
      sx={{
        background: theme.palette.primary.light,
        p: 2,
      }}
    >
      <Typography
        fontSize="96px"
        lineHeight="1.2"
        fontWeight="600"
        color="primary.dark"
        textAlign="center"
        mb={5}
        sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: '32px',
            mb: 1,
          },
        }}
      >
        {t('thankYou')}
      </Typography>

      <Typography
        variant="body"
        fontSize="40px"
        textAlign="center"
        mb={7.5}
        sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: '22px',
            mb: 2,
          },
        }}
      >
        {!isKioskWorkingHours(settings?.schedule || null)
          ? `Your order will be ready on ${orderDate}`
          : t('processingOrder')}
      </Typography>

      <Box
        borderTop={`1px solid ${theme.palette.primary.dark}`}
        borderBottom={`1px solid ${theme.palette.primary.dark}`}
        py={2.25}
        mb={7.5}
        sx={{
          [theme.breakpoints.down('md')]: {
            mb: 2,
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={2.25}
        >
          <Typography
            variant="body"
            fontWeight="600"
            textTransform="uppercase"
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: '18px',
              },
            }}
          >
            {t('orderNumber')}
          </Typography>

          <Typography
            variant="summary"
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: '22px',
              },
            }}
          >
            {number}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="body"
            fontWeight="600"
            textTransform="uppercase"
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: '18px',
              },
            }}
          >
            {t('totalPrice')}
          </Typography>

          <Typography
            variant="summary"
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: '22px',
              },
            }}
          >
            {price} ₪
          </Typography>
        </Stack>
      </Box>

      <Stack direction="column" spacing={5}>
        {/* <SecondaryButton to="/">Order one more time</SecondaryButton> */}
        <PrimaryButton onClick={resetApp}>
          {t('backToHome')}
          <CountdownTimer initialSeconds={20} onFinish={resetApp} />
        </PrimaryButton>
      </Stack>
    </Box>
  )
}

export default OrderSummary
