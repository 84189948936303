interface Props {
  color: string
}

const CompanyIcon = ({ color }: Props) => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 64.1668V11.6668C17.5 10.1197 18.1146 8.636 19.2085 7.54204C20.3025 6.44808 21.7862 5.8335 23.3333 5.8335H46.6667C48.2138 5.8335 49.6975 6.44808 50.7915 7.54204C51.8854 8.636 52.5 10.1197 52.5 11.6668V64.1668H17.5Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5007 35H11.6673C10.1202 35 8.63649 35.6146 7.54253 36.7085C6.44857 37.8025 5.83398 39.2862 5.83398 40.8333V58.3333C5.83398 59.8804 6.44857 61.3642 7.54253 62.4581C8.63649 63.5521 10.1202 64.1667 11.6673 64.1667H17.5007"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.5 26.25H58.3333C59.8804 26.25 61.3642 26.8646 62.4581 27.9585C63.5521 29.0525 64.1667 30.5362 64.1667 32.0833V58.3333C64.1667 59.8804 63.5521 61.3642 62.4581 62.4581C61.3642 63.5521 59.8804 64.1667 58.3333 64.1667H52.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.166 17.5H40.8327"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.166 29.1665H40.8327"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.166 40.8335H40.8327"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.166 52.5H40.8327"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CompanyIcon
