import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import {
  AdvancedMarker,
  APIProvider,
  Map,
  useApiIsLoaded,
  useMap,
} from '@vis.gl/react-google-maps'
import DeliveryInformation from '@/components/delivery/DeliveryInformation'
import DeliveryTime from '@/components/delivery/DeliveryTime'
import PrimaryButton from '@/components/shared/buttons/PrimaryButton'
import CustomInput from '@/components/shared/form/CustomInput'
import useDeliveryZone from '@/hooks/useDeliveryZone'
import useSettings from '@/hooks/useSettings'
import MainLayout from '@/layouts/MainLayout'
import { getDeliveryInfo } from '@/services/delivery'
import { isKioskWorkingHours } from '@/services/working-hours'
import useCartStore from '@/store/useCartStore'
import IDeliveryArea from '@/types/DeliveryArea'
import IDeliveryDetail from '@/types/DeliveryDetail'
import IMapCoordinates from '@/types/MapCoordinates'

interface DeliveryMapProps {
  location: IMapCoordinates
}

const DeliveryMap = ({ location }: DeliveryMapProps) => {
  const map = useMap()
  const apiIsLoaded = useApiIsLoaded()
  const { data: deliveryZone } = useDeliveryZone()

  useEffect(() => {
    if (!apiIsLoaded) return

    deliveryZone?.areas.forEach((area: IDeliveryArea) => {
      const polygon = new google.maps.Polygon({
        paths: area.area,
        strokeColor: '#321313',
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: '#9d00ff',
        fillOpacity: 0.3,
      })

      polygon.setMap(map)
    })
  }, [map, apiIsLoaded, deliveryZone])

  return (
    <>
      <Map
        mapId="bf51a910020fa25b"
        defaultZoom={14}
        defaultCenter={location}
        gestureHandling="greedy"
        disableDefaultUI
      />

      <AdvancedMarker position={location} />
    </>
  )
}

const daysOfWeek = [
  'SUNDAY',
  'MONDAY',
  'THURSDAY',
  'WEDNESDAY',
  'TUESDAY',
  'FRIDAY',
  'SATURDAY',
]

const AddressDetails = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation('delivery')
  const customer = useCartStore((s) => s.customer)
  const { data: settings } = useSettings()
  const { setMinimumOrderPrice } = useCartStore()

  const location = {
    lat: customer.address?.location?.lat || 0,
    lng: customer.address?.location?.lng || 0,
  }
  const [deliveryInfo, setDeliveryInfo] = useState<IDeliveryDetail[] | null>(
    null,
  )
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getDeliveryInfo(location).then((info) => {
      const currentDay = settings?.schedule?.[daysOfWeek[new Date().getDay()]]

      setLoading(false)

      if (!info) return

      setMinimumOrderPrice(info.minimumOrderPrice)

      setDeliveryInfo([
        {
          title: t('deliveryFree'),
          value: `${info.deliveryFee} ₪`,
        },
        {
          title: t('deliveryTime'),
          value: isKioskWorkingHours(settings?.schedule || null)
            ? `${info.maxDeliveryTime / 60 / 60} min`
            : '-',
        },
        {
          title: t('minimumSum'),
          value: `${info.minimumOrderPrice} ₪`,
        },
        {
          title: t('workHours'),
          value: `${currentDay?.open} - ${currentDay?.close}`,
        },
      ])
    })
  }, [])

  return (
    <MainLayout>
      <Stack
        direction="column"
        height="100%"
        px="78px"
        py={10}
        gap={20}
        sx={{
          [theme.breakpoints.down('md')]: {
            px: 2,
            py: 3,
          },
        }}
      >
        <Stack
          flex="1"
          direction="column"
          gap={7.5}
          sx={{
            [theme.breakpoints.down('md')]: {
              gap: 3,
            },
          }}
        >
          <Typography
            variant="body"
            textAlign="center"
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: 22,
              },
            }}
          >
            {t('checkDemandsText')}
          </Typography>

          <Box>
            <Typography
              variant="h3"
              textAlign="center"
              mb={2.5}
              sx={{
                [theme.breakpoints.down('md')]: {
                  fontSize: 20,
                  mb: 1,
                },
              }}
            >
              {t('address')}
            </Typography>

            <Box onClick={() => navigate('/delivery/address')}>
              <Box sx={{ pointerEvents: 'none' }}>
                <CustomInput
                  type="text"
                  name="address"
                  placeholder="Type to show options"
                  disabled
                  value={customer.address?.address || ''}
                />
              </Box>
            </Box>
          </Box>

          <Stack
            direction="row"
            borderRadius="30px"
            overflow="hidden"
            border={`1px solid ${theme.palette.primary.midtone}`}
            sx={{
              [theme.breakpoints.down('md')]: {
                flexDirection: 'column',
              },
            }}
          >
            <Box
              height="530px"
              width="400px"
              bgcolor="#ccc"
              overflow="hidden"
              sx={{
                [theme.breakpoints.down('md')]: {
                  height: 300,
                },
              }}
            >
              <APIProvider apiKey="AIzaSyApxk5ddNc67g6YhqzKtTqdvI20NOWbvZg">
                <DeliveryMap location={location} />
              </APIProvider>
            </Box>

            <Box flex="1">
              <DeliveryInformation
                deliveryInfo={deliveryInfo}
                loading={loading}
              />
            </Box>
          </Stack>

          {deliveryInfo && (
            <>
              {!isKioskWorkingHours(settings?.schedule || null) && (
                <DeliveryTime />
              )}

              <Box mb={3}>
                <PrimaryButton
                  type="button"
                  fullWidth
                  size="large"
                  onClick={() => navigate('/menu')}
                >
                  {t('confirm')}
                </PrimaryButton>
              </Box>
            </>
          )}
        </Stack>
      </Stack>
    </MainLayout>
  )
}

export default AddressDetails
