import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { DateTime } from 'luxon'
import DateSelect from '@/components/delayed-order/DateSelect'
import TimePicker from '@/components/delayed-order/TimePicker'
import BackButton from '@/components/shared/BackButton'
import PrimaryButton from '@/components/shared/buttons/PrimaryButton'
import useDelayedOrder from '@/hooks/useDelayedOrder'
import MainLayout from '@/layouts/MainLayout'

const DelayedOrder = () => {
  const { t } = useTranslation('delayedOrder')
  const theme = useTheme()

  const {
    handleDateChange,
    handleTimeChange,
    openTime,
    closeTime,
    availableDates,
    selectedDate,
  } = useDelayedOrder()

  return (
    <MainLayout showDeliverySteps={false}>
      <Stack
        direction="column"
        alignItems="center"
        sx={{
          flex: 1,
          py: 10,
          [theme.breakpoints.down('md')]: {
            py: 1,
            justifyContent: 'center',
            height: '100%',
          },
        }}
      >
        <Box
          mb={5}
          sx={{
            [theme.breakpoints.down('md')]: {
              mb: 1.5,
              display: 'none',
            },
          }}
        >
          <BackButton to="/where-to-eat" />
        </Box>

        <Box
          sx={{
            width: '1px',
            height: '100px',
            background: theme.palette.primary.dark,
            mb: 5,
            [theme.breakpoints.down('md')]: {
              mb: 1,
              height: '40px',
              display: 'none',
            },
          }}
        />
        <Typography
          variant="body"
          textAlign="center"
          mb={7.5}
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '16px',
              mb: 1,
            },
          }}
        >
          {t('chooseDateOrder')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            width: '80%',
            flexDirection: 'column',
            gap: 5,
            mt: '280px',
            [theme.breakpoints.down('md')]: {
              gap: 2,
              mt: '30px',
              alignItems: 'center',
            },
          }}
        >
          <DateSelect
            availableDates={availableDates}
            selectedDate={selectedDate}
            handleDateChange={handleDateChange}
          />
          <TimePicker
            value={selectedDate ? DateTime.fromISO(selectedDate) : null}
            disabled={!selectedDate}
            maxTime={closeTime}
            minTime={openTime}
            onChange={handleTimeChange}
          />
          <PrimaryButton fullWidth to="/menu">
            {t('makeOrder')}
          </PrimaryButton>
        </Box>
        <Box />
      </Stack>
    </MainLayout>
  )
}

export default DelayedOrder
