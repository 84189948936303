import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ProductCard from '@/components/product/ProductCard'
import MainLayout from '@/layouts/MainLayout'
import useCartStore from '@/store/useCartStore'
import useProductStore from '@/store/useProductStore'
import type IProduct from '@/types/Product'

const EditProduct = () => {
  const params = useParams()
  const cart = useCartStore((s) => s.cart)
  const product: IProduct | undefined = cart.find(
    (item) => item.uniqueId === params.uniqueId,
  )

  const setProduct = useProductStore((s) => s.setProduct)
  const removeProduct = useProductStore((s) => s.removeProduct)

  const navigate = useNavigate()
  const orderType = useCartStore((s) => s.orderType)

  useEffect(() => {
    if (!orderType) {
      navigate('/where-to-eat')
    }

    if (product) {
      setProduct(product)
    }

    return () => {
      removeProduct()
    }
  }, [product, orderType])

  return (
    <MainLayout deliveryStep={1}>
      <ProductCard />
    </MainLayout>
  )
}

export default EditProduct
