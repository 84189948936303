import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import type IOption from '@/types/Option'

interface Props {
  option: IOption
}

const OptionLimits = ({ option }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation('product')

  const limitsList = [
    {
      id: 1,
      title: t('minChoices'),
      color: theme.palette.primary.dark,
      value: option.minimumChoices,
    },
    {
      id: 2,
      title: t('freeChoices'),
      color: '#1E8133',
      value: option.freeChoices,
    },
    {
      id: 3,
      title: t('maxChoices'),
      color: '#F4991A',
      value: option.maximumChoices,
    },
  ]

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      mb={5}
      sx={{
        [theme.breakpoints.down('md')]: {
          mb: 2,
        },
      }}
    >
      {limitsList
        .filter((limit) => limit.value > 0)
        .map((limit) => (
          <Stack
            key={limit.id}
            direction="row"
            borderRadius="10px"
            border={`1px solid ${limit.color}`}
            overflow="hidden"
            flex="1"
            maxWidth="300px"
          >
            <Box
              flex="1"
              px={2}
              py={1.25}
              sx={{
                [theme.breakpoints.down('md')]: {
                  pl: 1,
                  pr: 0,
                },
              }}
            >
              <Typography
                variant="smallBody"
                fontWeight="600"
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: '14px',
                  },
                }}
              >
                {limit.title}
              </Typography>
            </Box>
            <Box
              minWidth="56px"
              flexShrink="0"
              px={2}
              py={1.25}
              sx={{
                [theme.breakpoints.down('md')]: {
                  minWidth: '25px',
                  pl: 0,
                  pr: 1,
                },
              }}
            >
              <Typography
                variant="smallBody"
                fontWeight="600"
                textAlign="center"
                color={limit.color}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: '14px',
                  },
                }}
              >
                {`x${limit.value}`}
              </Typography>
            </Box>
          </Stack>
        ))}
    </Stack>
  )
}

export default OptionLimits
