import { Box } from '@mui/material'
import { useProcessTranslationObject } from '@/services/translation'
import useProductStore from '@/store/useProductStore'
import type OptionType from '@/types/Option'
import Choice from '../choice'
import OptionError from './OptionError'
import OptionLimits from './OptionLimits'
import OptionTitle from './OptionTitle'
import OptionWrapper from './OptionWrapper'

interface Props {
  option: OptionType
  error: boolean
}

const Option = ({ option, error }: Props) => {
  const selectMultipleChoice = useProductStore((s) => s.selectMultipleChoice)
  const { processTranslationObject } = useProcessTranslationObject()

  const selectedChoicesQuantity = option.choices.reduce(
    (acc, choice) => acc + choice.quantity,
    0,
  )
  const freeChoicesQuantity = option.choices.reduce(
    (acc, choice) => acc + choice.freeQuantity,
    0,
  )

  return (
    <OptionWrapper>
      <OptionTitle title={processTranslationObject(option.name)} />

      <OptionLimits option={option} />

      <Box position="relative">
        {error && <OptionError option={option} />}

        {option.choices
          .filter((choice) => choice.visible)
          .map((choice, index) => {
            return (
              <Choice
                key={`${choice.id}-${index}`}
                choice={choice}
                type={
                  // eslint-disable-next-line no-nested-ternary
                  option.type === 'MULTIPLE_CHOICE_PIZZA'
                    ? 'PIZZA'
                    : option.showQuantity
                      ? 'COUNTER'
                      : 'CHECKBOX'
                }
                isDisabled={selectedChoicesQuantity >= option.maximumChoices}
                showPrice={freeChoicesQuantity >= option.freeChoices}
                onModify={(quantity, options) =>
                  selectMultipleChoice(option.id, choice.uid, quantity, options)
                }
              />
            )
          })}
      </Box>
    </OptionWrapper>
  )
}

export default Option
