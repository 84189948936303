const FullPizzaIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
    >
      <circle
        cx="21.5"
        cy="21"
        r="20"
        transform="rotate(-90 21.5 21)"
        fill="#321313"
        stroke="#321313"
        strokeWidth="2"
      />
    </svg>
  )
}

export default FullPizzaIcon
