import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'
import type IOption from '@/types/Option'
import type IProduct from '@/types/Product'

interface IProductStore {
  product: IProduct | null
  setProduct: (product: IProduct) => void
  setProductQuantity: (quantity: number) => void
  selectSingleChoice: (optionId: string, choiceId: string) => void
  selectMultipleChoice: (
    optionId: string,
    choiceId: string,
    quantity: number,
    options?: { pizzaPart?: string },
  ) => void
  removeProduct: () => void
  getTotalPrice: () => number
  getRequiredOptions: () => IOption[]
}

const useProductStore = create<IProductStore>((set, get) => ({
  product: null,
  setProduct: (product) => {
    const prodcutClone: IProduct = JSON.parse(JSON.stringify(product))
    const options = prodcutClone.options
      .filter((o) => o !== null)
      .map((option) => {
        if (option.type === 'SINGLE_CHOICE') {
          const hasSelecedChoice = option.choices.some(
            (choice) => choice.quantity > 0,
          )

          return {
            ...option,
            choices: option.choices.map((choice, index) => ({
              ...choice,
              // eslint-disable-next-line no-nested-ternary
              quantity: hasSelecedChoice
                ? choice.quantity > 0
                  ? 1
                  : 0
                : index === 0
                  ? 1
                  : 0,
            })),
          }
        }

        return option
      })

    set({
      product: {
        ...prodcutClone,
        quantity: prodcutClone.quantity || 1,
        options: options.map((option) => {
          return {
            ...option,
            choices: option.choices.map((choice) => {
              if (option.dependsOn) {
                const dependsOnOption = options.find(
                  (o) => o.id === option.dependsOn,
                )
                const selectedDependsOnChoice = dependsOnOption?.choices.find(
                  (c) => c.quantity > 0,
                )

                return {
                  ...choice,
                  uid: choice.uid ?? choice.id,
                  quantity: choice.quantity || 0,
                  freeQuantity: choice.freeQuantity || 0,
                  price: selectedDependsOnChoice
                    ? option.choicePrices[choice.id][selectedDependsOnChoice.id]
                    : option.choicePrices[choice.id][choice.id],
                }
              }

              return {
                ...choice,
                uid: choice.uid ?? choice.id,
                quantity: choice.quantity || 0,
                freeQuantity: choice.freeQuantity || 0,
                price: option.choicePrices[choice.id]
                  ? option.choicePrices[choice.id][choice.id]
                  : 0,
              }
            }),
          }
        }),
      },
    })
  },
  setProductQuantity: (quantity) => {
    set((state) => ({
      product: { ...state.product!, quantity },
    }))
  },
  removeProduct: () => set({ product: null }),
  selectSingleChoice: (optionId: string, choiceId: string) => {
    set((state) => {
      const product: IProduct = JSON.parse(JSON.stringify(state.product))
      const option = product.options.find((o) => o.id === optionId)!

      const selectedChoice = option.choices.filter((c) => c.quantity > 0)[0]
      if (selectedChoice) selectedChoice.quantity = 0

      const choice = option.choices.find((c) => c.id === choiceId)!
      choice.quantity = choice.quantity === 0 ? 1 : 0
      choice.price = option.choicePrices[choice.id][choice.id]

      // update dependant options
      product.options = product.options.map((o) => {
        return {
          ...o,
          choices: o.choices.map((c) => {
            if (o.dependsOn === optionId) {
              c.price = o.choicePrices[c.id]
                ? o.choicePrices[c.id][choice.id]
                : 0
            }

            return c
          }),
        }
      })

      return { product }
    })
  },
  selectMultipleChoice: (
    optionId: string,
    choiceId: string,
    quantity: number,
    options?: { pizzaPart?: string },
  ) => {
    set((state) => {
      const product: IProduct = JSON.parse(JSON.stringify(state.product))
      const option = product.options.find((o) => o.id === optionId)!

      const choiceIndex = option.choices.findIndex((c) => c.uid === choiceId)
      const choice = option.choices[choiceIndex]

      const freeChoicesQuantity = option.choices.reduce(
        (acc, choice) => acc + Math.ceil(choice.freeQuantity),
        0,
      )

      if (quantity === 0) {
        if (choice.isDuplicate) {
          const originalChoice = option.choices.find(
            (c) => c.duplicateId === choice.uid,
          )

          if (originalChoice?.duplicateId) {
            delete originalChoice.duplicateId
          }

          option.choices.splice(choiceIndex, 1)
        } else {
          choice.quantity = 0
          choice.freeQuantity = 0
          choice.pizzaPart = ''
        }

        return { product }
      }

      if (
        choice.freeQuantity > 0 &&
        option.freeChoices === freeChoicesQuantity
      ) {
        if (quantity > choice.quantity) {
          if (choice.duplicateId) {
            return { product }
          }

          const paidChoice = JSON.parse(JSON.stringify(choice))

          paidChoice.freeQuantity = 0
          paidChoice.quantity = 1
          paidChoice.uid = `${choice.id}-paid`
          paidChoice.isDuplicate = true

          option.choices.splice(choiceIndex + 1, 0, paidChoice)

          choice.duplicateId = paidChoice.uid

          return { product }
        }

        choice.quantity = quantity
        choice.freeQuantity = quantity

        if (options && options.pizzaPart) {
          if (choice.pizzaPart === options.pizzaPart) {
            choice.pizzaPart = ''
            choice.quantity = 0
            choice.freeQuantity = 0
          } else {
            choice.pizzaPart = options.pizzaPart
          }
        }

        return { product }
      }

      const freeChoicesLeft = option.freeChoices - freeChoicesQuantity

      if (choice.quantity > choice.freeQuantity) {
        choice.freeQuantity = 0
      } else {
        choice.freeQuantity =
          freeChoicesLeft > 0 ? quantity : choice.freeQuantity
      }

      choice.quantity = quantity

      if (options && options.pizzaPart) {
        if (choice.pizzaPart === options.pizzaPart) {
          choice.pizzaPart = ''
          choice.quantity = 0
          choice.freeQuantity = 0
        } else {
          choice.pizzaPart = options.pizzaPart
        }
      }

      return { product }
    })
  },
  getTotalPrice: () => {
    const { product } = get()
    if (!product) return 0

    const optionsPrice = product.options.reduce(
      (acc1, option) =>
        acc1 +
        option.choices.reduce(
          (acc2, choice) =>
            acc2 + choice.price * (choice.quantity - choice.freeQuantity),
          0,
        ),
      0,
    )

    return (product.price + optionsPrice) * product.quantity
  },
  getRequiredOptions: () => {
    const { product } = get()
    if (!product) return []

    return product.options.filter((option) => {
      const selectedChoicesQuantity = option.choices.reduce(
        (acc, choice) => acc + choice.quantity,
        0,
      )

      return selectedChoicesQuantity < option.minimumChoices
    })
  },
}))

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('ProductStore', useProductStore)
}

export default useProductStore
