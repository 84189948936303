import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CircularProgress, Stack, useTheme } from '@mui/material'
import Cart from '@/components/cart'
import CategoryList from '@/components/menu/CategoryList'
import MenuHeader from '@/components/menu/MenuHeader'
import ProductList from '@/components/menu/ProductList'
import EntranceUpsell from '@/components/upsell/EntranceUpsell'
import useMenu from '@/hooks/useMenu'
import MainLayout from '@/layouts/MainLayout'
import useCartStore from '@/store/useCartStore'
import useCategoryStore from '@/store/useCategoryStore'

const Menu = () => {
  const theme = useTheme()
  const { data: menu, isLoading } = useMenu()
  const selectedCategoryId = useCategoryStore((s) => s.selectedCategoryId)
  const setSelectedCategoryId = useCategoryStore((s) => s.setSelectedCategoryId)

  const navigate = useNavigate()
  const orderType = useCartStore((s) => s.orderType)

  useEffect(() => {
    if (!orderType) {
      navigate('/where-to-eat')
    }

    if (!selectedCategoryId && menu?.categories?.length) {
      setSelectedCategoryId(menu.categories.filter((c) => c.visible)[0].id)
    }
  }, [menu?.categories, orderType])

  if (isLoading)
    return (
      <Stack
        height="100%"
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="primary" size="100px" />
      </Stack>
    )

  return (
    <MainLayout deliveryStep={1}>
      <Stack direction="column" height="100%">
        <Stack direction="row" flex="1" overflow="auto">
          <Stack
            direction="column"
            spacing={7.5}
            flex="1"
            sx={{
              [theme.breakpoints.down('md')]: {
                mt: 0,
              },
            }}
          >
            <MenuHeader />
            <ProductList />
          </Stack>

          <CategoryList />
        </Stack>

        <Cart />
        <EntranceUpsell />
      </Stack>
    </MainLayout>
  )
}

export default Menu
