import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'
import type IOption from '@/types/Option'

interface IProductErrorStore {
  optionsWithError: IOption[]
  throwOptionsError: (optionsWithError: IOption[]) => void
  clearOptionsError: () => void
}

const useProductErrorStore = create<IProductErrorStore>((set) => ({
  optionsWithError: [],
  throwOptionsError: (optionsWithError) => {
    set({ optionsWithError })
  },
  clearOptionsError: () => {
    set({ optionsWithError: [] })
  },
}))

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('ProductErrorStore', useProductErrorStore)
}

export default useProductErrorStore
