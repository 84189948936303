import { useCallback, useEffect } from 'react'
import { useAuthUser, useSignIn, useSignOut } from 'react-auth-kit'
import axios, { InternalAxiosRequestConfig } from 'axios'
import { axiosInstance } from '@/services/api-client'
import { isTokenExpires } from '@/services/auth'
import { refreshToken } from '@/services/refresh-token'
import decodeToken from '@/utils/decode-jwt'
import getCookie from '@/utils/get-cookie'
import { jsonParser } from '@/utils/json-parser'

const useAuth = () => {
  const signOut = useSignOut()
  const authUser = useAuthUser()
  const signIn = useSignIn()

  const refreshAuthToken = async () => {
    try {
      const { refreshToken: refresh } =
        jsonParser<{ refreshToken: string }>(getCookie('_auth_state')) || {}

      const expIn = getCookie('_auth_storage')

      const data = await refreshToken(refresh)
      const newToken = data.accessToken

      const { exp = 0 } = decodeToken(newToken) || {}

      signIn({
        token: newToken,
        tokenType: 'Bearer',
        authState: {
          ...(authUser() ?? {}),
          tokenExpireIn: new Date(Date.now() + exp),
        },
        expiresIn:
          (new Date(expIn as string).getTime() - Date.now()) / 1000 / 60,
      })

      return newToken
    } catch (error) {
      console.error('Failed to refresh token', error)
      return null
    }
  }

  const interceptorsRequest = useCallback(
    async (config: InternalAxiosRequestConfig & { withoutToken?: boolean }) => {
      if (config?.withoutToken || config.headers.Authorization) {
        return config
      }

      const token = getCookie('_auth')

      const { tokenExpireIn } =
        jsonParser<{ tokenExpireIn: string }>(getCookie('_auth_state')) || {}

      const isTValid = isTokenExpires(tokenExpireIn)

      if (!isTValid) {
        const newToken = await refreshAuthToken()

        if (newToken) {
          config.headers.Authorization = `Bearer ${newToken}`
          return config
        }
        signOut()
      }

      config.headers.Authorization = `Bearer ${token}`

      return config
    },
    [],
  )
  /* eslint-disable */
  const interceptorsResponse = useCallback(async (error: any) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true

      const newToken = await refreshAuthToken()
      if (newToken) {
        axios.defaults.headers.common.Authorization = `Bearer ${newToken}`
        originalRequest.headers.Authorization = `Bearer ${newToken}`
        return axiosInstance(originalRequest)
      }
      signOut()
    }

    return Promise.reject(error)
  }, [])

  useEffect(() => {
    const req = axiosInstance.interceptors.request.use(
      interceptorsRequest,
      (error) => {
        return Promise.reject(error)
      },
    )
    const res = axiosInstance.interceptors.response.use(
      (response) => response,
      interceptorsResponse,
    )
    return () => {
      axios.interceptors.request.eject(req)
      axios.interceptors.response.eject(res)
    }
  }, [interceptorsRequest, interceptorsResponse])

  return axiosInstance
}

export default useAuth
