import axios from 'axios'
import { axiosInstance } from '@/services/api-client'

interface PaymentResponse {
  status: boolean
  transactionId: string
  errorCode: string
  errorDescription: string
}

interface TerminalResponse {
  status: boolean
  code: string
  message: string
  card: string
}

export const getCardNumberFromTerminal = async () => {
  const result = await axiosInstance.get<TerminalResponse>(
    '/payments/card/swipe',
  )

  return result.data
}

// 8550625198016493
export const payWithTenbis = async (sum: number, magneticCode: string) => {
  const result = await axiosInstance.post<PaymentResponse>('/payments/tenbis', {
    sum,
    magneticCode,
    operationType: 'Auto',
  })

  return result.data
}

interface CreditCardResponse {
  status: boolean
  transactionId: string
  message: string
  acquirer: string
  issuer: string
  card: string
  requestId: string
}

export const payWithCreditCard = async (amount: number) => {
  const result = await axiosInstance.post<CreditCardResponse>(
    'payments/card/transaction',
    { amount },
  )

  return result.data
}

export const payWithCibus = async (
  sum: number,
  companyCard: string,
  companyCode: number,
) => {
  const result = await axiosInstance.post<PaymentResponse>('/payments/cibus', {
    sum,
    companyCard,
    companyCode,
  })

  return result.data
}

export const payWithGoodi = async (sum: number, cardNumber: string) => {
  const result = await axiosInstance.post<PaymentResponse>('/payments/goodi', {
    sum,
    cardNumber,
    operationType: 'Auto',
  })

  return result.data
}

interface CashOnCloudResponse {
  status: boolean
  transactionId: string
  message: string
}

export const payWithCashOnCloud = async (amount: number) => {
  const result = await axiosInstance.post<CashOnCloudResponse>(
    '/payments/coc',
    { amount },
  )

  return result.data
}

interface PeleCardResponse {
  errorCode: string
  errorDescription: string
  status: boolean
  url: string
}

export const payWithPeleCard = async (
  amount: number,
  name: string,
  email: string,
) => {
  const result = await axiosInstance.post<PeleCardResponse>(
    '/payments/pelecard/init',
    { amount, name, email },
  )

  return result.data
}

interface PeleCardTransactionResponse {
  transactionId: string
  requestId: string
  acquirer: string
  issuer: string
  card: string
  message: string
  rrn: string
  status: boolean
  transactionCode: string
  uid: string
  validationCode: string
}

export const getPeleCardTransaction = async (id: string) => {
  const result = await axiosInstance.get<PeleCardTransactionResponse>(
    `/payments/pelecard/transaction/${id}`,
  )

  return result.data
}

interface TerminalStatusResponse {
  status: boolean
  code: string
  message: string
}

export const checkTerminalStatus = async () => {
  const result = await axiosInstance.get<TerminalStatusResponse>(
    '/payments/terminal/status',
  )

  return result.data
}

const CASH_MACHINE_BASE_URL = 'http://localhost:3000/'

// interface PayWithCashMachineResponse {
//   Status: number
//   StatusDesc: string
//   DispenceNotes: number
//   DispenceCoins: number
// }

export const payWithCashMachine = (
  amount: number,
  controller: AbortController,
) => {
  const axiosInstance = axios.create({
    baseURL: CASH_MACHINE_BASE_URL,
  })

  return axiosInstance.post<any>(
    '/RestWCFServiceLibrary/Cash/Pay/',
    { amount, timeoutSec: 60 },
    {
      signal: controller.signal,
    },
  )
}

// interface CashMachineStatusResponse {
//   GetCurrentAmountResult: {
//     Status: number
//     StatusDesc: string
//     CurrentAmount: number
//   }
// }

export const getCurrentAmountFromCashMachine = (
  controller: AbortController,
) => {
  const axiosInstance = axios.create({
    baseURL: CASH_MACHINE_BASE_URL,
  })

  return axiosInstance.get<any>(
    '/RestWCFServiceLibrary/Cash/GetCurrentAmount/',
    {
      signal: controller.signal,
    },
  )
}

interface CashMachineCancelResponse {
  CancelPayResult: {
    Status: number
    StatusDesc: string
  }
}

export const cancelCashMachinePayment = () => {
  const axiosInstance = axios.create({
    baseURL: CASH_MACHINE_BASE_URL,
  })

  return axiosInstance.get<CashMachineCancelResponse>(
    '/RestWCFServiceLibrary/Cash/CancelPay/',
  )
}
