import { Link as RouterLink } from 'react-router-dom'
import { Button, useTheme } from '@mui/material'
import BigLeftArrowIcon from '../icons/BigLeftArrowIcon'

interface Props {
  to: string
}

const BackButton = ({ to }: Props) => {
  const theme = useTheme()

  return (
    <Button
      variant="outlined"
      component={RouterLink}
      to={to}
      sx={{
        borderWidth: '2px',
        borderColor: theme.palette.primary.main,
        borderRadius: '20px',
        padding: '23px',
        ':hover': {
          borderWidth: '2px',
          borderColor: theme.palette.primary.main,
        },
        [theme.breakpoints.down('md')]: {
          padding: '2px',
          borderRadius: '15px',
          svg: {
            width: '40px',
            p: 0.8,
          },
        },
      }}
    >
      <BigLeftArrowIcon paletteColor={theme.palette.primary} />
    </Button>
  )
}

export default BackButton
