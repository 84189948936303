import { Box, Stack, Typography, useTheme } from '@mui/material'
import { useProcessTranslationObject } from '@/services/translation'
import useCategory from '../../hooks/useCategory'
import useSettings from '../../hooks/useSettings'
import useCategoryStore from '../../store/useCategoryStore'
import BackButton from '../shared/BackButton'

const MenuHeader = () => {
  const theme = useTheme()
  const selectedCategoryId = useCategoryStore((s) => s.selectedCategoryId)
  const category = useCategory(selectedCategoryId)
  const { data: settings } = useSettings()
  const { processTranslationObject } = useProcessTranslationObject()

  const orderTypes = settings?.orderTypes
  const backPath = orderTypes && orderTypes.length < 2 ? '/' : '/where-to-eat'

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={5}
      sx={{
        px: 11,
        pt: 10,
        [theme.breakpoints.down('md')]: {
          pt: '22px',
          pl: 1,
          pb: '22px',
          pr: 2,
          justifyContent: 'center',
        },
      }}
    >
      <Box
        flexShrink="0"
        sx={{
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        }}
      >
        <BackButton to={backPath} />
      </Box>

      <Box
        sx={{
          width: '100%',
          height: '1px',
          backgroundColor: theme.palette.primary.dark,
          flex: 1,
          [theme.breakpoints.down('md')]: {
            display: 'none',
          },
        }}
      />

      <Typography
        variant="h1"
        textAlign="right"
        fontSize={46}
        sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: '18px',
          },
        }}
      >
        {processTranslationObject(category?.name)}
      </Typography>
    </Stack>
  )
}

export default MenuHeader
