import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ProductCard from '../components/product/ProductCard'
import useProduct from '../hooks/useProduct'
import MainLayout from '../layouts/MainLayout'
import useCartStore from '../store/useCartStore'
import useProductStore from '../store/useProductStore'

const Product = () => {
  const params = useParams()
  const product = useProduct(params.id)

  const setProduct = useProductStore((s) => s.setProduct)
  const removeProduct = useProductStore((s) => s.removeProduct)

  const navigate = useNavigate()
  const orderType = useCartStore((s) => s.orderType)

  useEffect(() => {
    if (!orderType) {
      navigate('/where-to-eat')
    }

    if (product) {
      setProduct(product)
    } else {
      navigate('/menu')
    }

    return () => {
      removeProduct()
    }
  }, [product, orderType])

  if (!product) return null

  return (
    <MainLayout deliveryStep={1}>
      <ProductCard />
    </MainLayout>
  )
}

export default Product
