import { useTranslation } from 'react-i18next'
import { Stack, Typography, useTheme } from '@mui/material'
import { useProcessTranslationObject } from '@/services/translation'
import type IChoice from '@/types/Choice'
import ChoiceImage from './ChoiceImage'
import ChoicePizzaParts from './ChoicePizzaParts'
import ChoicePrice from './ChoicePrice'
import ChoiceTitle from './ChoiceTitle'
import ChoiceWrapper from './ChoiceWrapper'

interface Props {
  choice: IChoice
  isDisabled: boolean
  showPrice: boolean
  onModify: (quantity: number, options: { pizzaPart: string }) => void
}

const ChoiceWithPizza = ({
  choice,
  isDisabled,
  showPrice,
  onModify,
}: Props) => {
  const { t } = useTranslation('product')
  const theme = useTheme()
  const { processTranslationObject } = useProcessTranslationObject()
  const isSelected = choice.quantity > 0
  const isFree = choice.freeQuantity > 0

  return (
    <ChoiceWrapper
      isFree={choice.freeQuantity > 0}
      isSelected={isSelected}
      isDisabled={!isSelected && isDisabled}
    >
      <ChoiceImage url={choice.imageUrl} />

      <ChoiceTitle
        title={processTranslationObject(choice.name)}
        calories={choice.calories}
      />

      <Stack
        direction="row"
        alignItems="center"
        gap={2.5}
        sx={{
          [theme.breakpoints.down('md')]: {
            gap: 0,
            ml: '10px !important',
          },
        }}
      >
        {!isFree && (showPrice || isSelected) && (
          <ChoicePrice price={choice.price} />
        )}

        <Stack
          direction="row"
          gap={2.5}
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
              alignItems: 'center',
              gap: 0,
            },
          }}
        >
          <Typography
            display="none"
            sx={{
              [theme.breakpoints.down('md')]: {
                display: 'flex',
              },
            }}
          >
            {t('titlePizzaPart')}
          </Typography>
          <Stack direction="row">
            <ChoicePizzaParts
              selectedPart={choice.pizzaPart}
              onModify={(pizzaPart, quantity) =>
                onModify(quantity, { pizzaPart })
              }
            />
          </Stack>
        </Stack>
      </Stack>
    </ChoiceWrapper>
  )
}

export default ChoiceWithPizza
