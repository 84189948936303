import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Input, Stack, useTheme } from '@mui/material'
import useSettings from '../../hooks/useSettings'
import { ONLY_NUMBER } from '../shared/keyboard/languages/Languages'

interface Props {
  onSubmit: (cardNumber: string) => void
}

const CardForm = ({ onSubmit }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation('payment')
  const { data: settings } = useSettings()

  const cardRef = useRef<HTMLInputElement>(null)
  const [isOpenedKeypad, setIsOpenedKeypad] = useState(true)
  const [inputValue, setInputValue] = useState<string>('')

  const validateInput = (value: string): boolean => {
    return /^\d+$/.test(value)
  }
  const handleButtonClick = (value: number | string) => {
    const newValue = inputValue + value.toString()
    if (value === '⌫') setInputValue((prevValue) => prevValue.slice(0, -1))
    if (!validateInput(newValue)) return
    setInputValue(newValue)
  }

  const hideKeypad = () => {
    return {
      width: 50,
      height: 50,
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='50' height='50' viewBox='0 0 50 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 15L25 36L46 15' stroke='%23${theme.palette.primary.dark.slice(
        1,
      )}' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")`,
    }
  }

  return (
    <Stack
      component="form"
      direction="row"
      alignItems="stretch"
      width="100%"
      maxWidth="800px"
      mx="auto"
      spacing={2.25}
      onSubmit={(e) => {
        e.preventDefault()
        if (cardRef.current) {
          onSubmit(cardRef.current.value)
        }
      }}
    >
      <Input
        inputRef={cardRef}
        disableUnderline
        fullWidth
        value={inputValue}
        sx={{
          flex: 1,
          fontSize: '36px',
          lineHeight: 1.2,
          fontWeight: 600,
          px: 5,
          py: 3.25,
          borderRadius: '30px',
          border: `2px solid ${theme.palette.primary.main}`,
          [theme.breakpoints.down('md')]: {
            p: '5px',
            fontSize: '16px',
            borderRadius: '15px',
          },
        }}
        onFocus={() => setIsOpenedKeypad(true)}
        onChange={(e) => setInputValue(e.target.value)}
      />

      <Button
        type="submit"
        variant="contained"
        disableElevation
        // disabled
        sx={{
          px: 5,
          py: 3.75,
          borderRadius: '30px',
          fontSize: '48px',
          lineHeight: '1.2',
          fontWeight: 600,
          [theme.breakpoints.down('md')]: {
            fontSize: '16px',
            borderRadius: '15px',
            p: 2,
          },
        }}
      >
        {t('pay')}
      </Button>

      {settings?.type === 'KIOSK' && (
        <Stack
          position="absolute"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          bottom={0}
          left={0}
          ml="0 !important"
          width="100%"
          sx={{
            visibility: isOpenedKeypad ? 'visible' : 'hidden',
            height: isOpenedKeypad ? '700px' : '0px',
            overflow: 'hidden',
            bgcolor: theme.palette.primary.midtone,
            borderTopLeftRadius: 100,
            borderTopRightRadius: 100,
            transition: 'height .5s ease, visibility .5s ease',
          }}
        >
          <Box
            display="flex"
            flexWrap="wrap"
            gap={2}
            width={730}
            m="60px 60px 60px 50px"
          >
            {ONLY_NUMBER.map((rowButtons, rowButtonsIndex) => (
              <Box key={rowButtonsIndex} width="100%">
                {rowButtons.map((buttons, buttonsIndex) => (
                  <Box
                    key={buttonsIndex}
                    display="flex"
                    justifyContent="space-evenly"
                    width="100%"
                    margin="0"
                  >
                    {buttons.split(' ').map((button, index) => (
                      <Button
                        key={index}
                        onClick={() => handleButtonClick(button)}
                        sx={{
                          width: button === '' ? 5000 : '100%',
                          height: 133,
                          backgroundColor: isNaN(+button)
                            ? theme.palette.primary.light
                            : theme.palette.primary.dark,
                          color: isNaN(+button)
                            ? theme.palette.primary.dark
                            : theme.palette.primary.light,
                          fontSize: 40,
                          borderRadius: 5,
                          margin: '0 5px',
                          padding: '6px 10px',
                          '&:hover': {
                            backgroundColor: isNaN(+button)
                              ? theme.palette.primary.light
                              : theme.palette.primary.dark,
                            color: isNaN(+button)
                              ? theme.palette.primary.dark
                              : theme.palette.primary.light,
                          },
                        }}
                      >
                        {button}
                      </Button>
                    ))}
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
          <Box>
            <Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontWeight={600}
                fontSize={64}
                width={190}
                height={430}
                borderRadius="20px"
                m="60px 50px 20px 0"
                sx={{
                  color: theme.palette.primary.contrastText,
                  bgcolor: `${theme.palette.primary.main}`,
                  transition: '.3s',
                }}
                onClick={() => setIsOpenedKeypad(false)}
              >
                {t('ok')}
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={190}
                height={130}
                borderRadius="20px"
                sx={{
                  bgcolor: theme.palette.primary.light,
                  transition: '.3s',
                }}
                onClick={() => setIsOpenedKeypad(false)}
              >
                <Box sx={hideKeypad} />
              </Box>
            </Box>
          </Box>
        </Stack>
      )}
    </Stack>
  )
}

export default CardForm
