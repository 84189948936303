interface Props {
  size?: number
}

const VegetarianIcon = ({ size = 24 }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect width="24" height="24" rx="5" fill="#4CAF50" />
      <path
        d="M17.3321 5.14859C17.0451 5.23135 16.4389 5.38111 15.987 5.47963C14.8356 5.72791 14.6526 5.80279 14.1397 6.22053C13.4116 6.81561 12.9022 7.96636 12.9058 9.01071C12.9094 9.40875 12.9632 9.69249 13.0242 9.61761C13.0385 9.59791 13.0887 9.48362 13.1318 9.3654C13.3506 8.78608 13.9855 7.90331 14.5271 7.42645C14.8033 7.18212 15.3593 6.94172 15.3593 7.06783C15.3593 7.11118 15.1692 7.37128 14.9432 7.63138C14.7316 7.87966 14.6598 7.98213 14.2617 8.61662C13.8492 9.28264 13.3111 10.4728 12.9488 11.5329C12.5973 12.5615 12.3391 13.3812 12.1669 13.996C11.8763 15.0482 11.6145 15.8798 11.5679 15.8955C11.5105 15.9192 11.3526 15.3556 11.0226 13.9566C10.5097 11.7654 10.2909 11.0127 9.76721 9.64126C9.40852 8.70726 8.93504 7.95848 7.97374 6.81167C7.44645 6.17718 6.69678 5.57027 6.24482 5.40475C5.95069 5.29441 5.92558 5.34958 6.14798 5.62151C7.19537 6.91807 7.80156 7.87966 8.44363 9.24717C8.98526 10.4097 9.3583 11.5644 10.0362 14.2127C10.2084 14.8906 10.5886 16.6167 10.7106 17.2867C10.8469 18.0315 10.9975 18.7606 11.037 18.8749C11.0513 18.9103 11.1051 18.9576 11.159 18.9734C11.2809 19.0089 11.8692 19.0089 11.9947 18.9734C12.1812 18.9143 12.2171 18.7527 12.4754 16.8926C12.6475 15.6354 12.7946 14.8827 13.0887 13.7595C13.5873 11.8442 14.1182 10.4413 14.441 10.2127C14.4876 10.1812 14.7172 10.1181 14.9575 10.0748C15.8901 9.9053 16.6398 9.4521 17.1707 8.7309C17.5258 8.25011 17.6191 7.89543 17.7087 6.71315C17.7554 6.08654 17.7841 5.90919 17.8917 5.5348C17.9598 5.29047 18.0064 5.06977 17.9993 5.04219C17.9742 4.97125 17.9168 4.97913 17.3321 5.14859Z"
        fill="#FEFEFE"
      />
    </svg>
  )
}

export default VegetarianIcon
