import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useReactToPrint } from 'react-to-print'
import { Box, Typography, useTheme } from '@mui/material'
import useSettings from '../../hooks/useSettings'
import Footer from './Footer'
import Header from './Header'
import Product from './Product'

interface Props {
  order: any
}

const Receipt = ({ order }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation('receipt')
  const printRef = useRef(null)

  const { data: settings } = useSettings()

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })

  useEffect(() => {
    if (settings?.type === 'KIOSK') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      handlePrint()
    }
  }, [settings])

  return (
    <Box style={{ display: 'none' }}>
      <Box ref={printRef} width="300px" p={2.5}>
        <Header
          phoneNumber={order.customers[0].phone}
          clientName={order.customers[0].name}
          orderType={order.type}
        />

        <Box>
          <Typography
            fontSize={{ xs: '18px' }}
            textTransform="uppercase"
            mb={2}
          >
            {t('items')}
          </Typography>

          {order.customers[0].items.map((product: any) => (
            <Box
              key={product.id}
              pb={1}
              borderBottom={`1px solid ${theme.palette.primary.dark}`}
              mb={2}
              sx={{ '&:last-child': { borderBottom: 'none' } }}
            >
              <Product product={product} />
            </Box>
          ))}
        </Box>

        <Footer
          total={order.totals.total}
          paymentId={order.payments[0].id}
          cardNumber={order.payments[0].card}
        />
      </Box>
    </Box>
  )
}

export default Receipt
