import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Stack, useTheme } from '@mui/material'
import { payWithGoodi } from '@/services/payment'
import { usePaymentsList } from '@/utils/consts'
import ReadCard from '../../../components/payment/ReadCard'
import PaymentLayout from '../../../layouts/PaymentLayout'
import useCartStore from '../../../store/useCartStore'
import usePaymentErrorStore from '../../../store/usePaymentErrorStore'

const Terminal = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation('payment')

  const errorMessage = usePaymentErrorStore((s) => s.errorMessage)
  const throwError = usePaymentErrorStore((s) => s.throwError)
  const setPaymentResult = useCartStore((s) => s.setPaymentResult)
  const getCartTotalPrice = useCartStore((s) => s.getCartTotalPrice)
  const price = getCartTotalPrice()

  const PAYMENTS_LIST = usePaymentsList()

  const makePayment = (cardNumber: string) => {
    payWithGoodi(price, cardNumber)
      .then((data) => {
        if (data.status) {
          setPaymentResult({
            id: PAYMENTS_LIST.GOODI.id,
            amount: price,
            transactionId: data.transactionId,
            card: cardNumber,
          })

          navigate('/payment/success')
        } else {
          throwError('goodi', data.errorDescription)
        }
      })
      .catch((err) => {
        console.log(err)
        throwError('goodi', t('generalError'))
      })
  }

  return (
    <PaymentLayout
      title={t('goodiTitle')}
      backTo="/payment/goodi"
      allowBack={false}
    >
      <Stack
        direction="column"
        justifyContent="flex-end"
        height="100%"
        sx={{
          [theme.breakpoints.down('md')]: {
            m: '0 10px !important',
            height: 'auto',
          },
        }}
      >
        {!errorMessage && (
          <ReadCard
            onCardRead={makePayment}
            onError={(errorMessage) => throwError('goodi', errorMessage)}
          />
        )}
      </Stack>
    </PaymentLayout>
  )
}

export default Terminal
