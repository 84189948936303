import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Link, List, ListItem, useTheme } from '@mui/material'
import useCibusCompanies from '@/hooks/useCibusCompanies'
import PaymentLayout from '@/layouts/PaymentLayout'
import type ICibusCompany from '@/types/CibusCompany'

const ChooseCompany = () => {
  const theme = useTheme()
  const { t } = useTranslation('payment')

  const { data: companies } = useCibusCompanies() as {
    data: { companyCodes: ICibusCompany[] }
  }

  return (
    <PaymentLayout
      title={t('cibusTitle')}
      subTitle={t('chooseCompanyLong')}
      backTo="/payment/cibus"
    >
      <Box maxWidth="785px" mx="auto" maxHeight="100%" overflow="auto">
        <List
          sx={{
            [theme.breakpoints.down('md')]: {
              ml: 1,
              mr: 1,
            },
          }}
        >
          {companies?.companyCodes.map(({ name, code }) => (
            <ListItem
              key={code}
              disablePadding
              sx={{
                borderRadius: '30px',
                bgcolor: theme.palette.primary.light,
                mb: 2.25,
                [theme.breakpoints.down('md')]: {
                  borderRadius: '15px',
                },
              }}
            >
              <Link
                variant="h3"
                component={RouterLink}
                to={`/payment/cibus/terminal?company=${name}`}
                underline="none"
                width="100%"
                px={5}
                py={3}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: '18px',
                  },
                }}
              >
                {name}
              </Link>
            </ListItem>
          ))}
        </List>
      </Box>
    </PaymentLayout>
  )
}

export default ChooseCompany
