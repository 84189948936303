import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Fade, Modal, Stack, Typography, useTheme } from '@mui/material'
import ExitIcon from '@/components/icons/ExitIcon'
import PrimaryButton from '@/components/shared/buttons/PrimaryButton'
import UpsellProduct from '@/components/upsell/UpsellProduct'
import useUpsells from '@/hooks/useUpsells'
import { useProcessTranslationObject } from '@/services/translation'
import useUpsellStore from '@/store/useUpsellStore'

const EntranceUpsell = () => {
  const theme = useTheme()
  const { t } = useTranslation('upsell')
  const { processTranslationObject } = useProcessTranslationObject()

  const { data: upsells } = useUpsells()

  const upsell = upsells?.entrance?.bunches[0]
  const backgroundImg = `${upsells?.domainName}/${upsell?.marketingImageUrl}`

  const entranceUpsellVisibility = useUpsellStore(
    (s) => s.entranceUpsellVisibility,
  )
  const showEntranceUpsell = useUpsellStore((s) => s.showEntranceUpsell)
  const hideEntranceUpsell = useUpsellStore((s) => s.hideEntranceUpsell)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (upsell) {
        showEntranceUpsell()
      }
    }, 500)

    return () => {
      hideEntranceUpsell()
      clearTimeout(timeout)
    }
  }, [upsell, showEntranceUpsell, hideEntranceUpsell])

  const handleProceed = () => {
    hideEntranceUpsell()
  }

  return (
    <Modal open={entranceUpsellVisibility} onClose={() => hideEntranceUpsell()}>
      <Fade in={entranceUpsellVisibility}>
        <Box
          width="100%"
          maxWidth="1000px"
          position="absolute"
          top="50%"
          left="50%"
          borderRadius="100px"
          pt={13}
          px={7.5}
          pb={7.5}
          sx={{
            transform: 'translate(-50%, -50%)',
            background: upsell?.marketingImageUrl
              ? `url(${backgroundImg}) center / cover no-repeat`
              : theme.palette.primary.light,
            [theme.breakpoints.down('md')]: {
              top: '50%',
              borderRadius: '30px',
              p: 2,
              width: '94%',
              height: '80%',
            },
          }}
        >
          <Box
            width={100}
            height={100}
            position="absolute"
            sx={{
              transform: 'translate(-50%, -50%)',
              top: '-7%',
              left: '50%',
              [theme.breakpoints.down('md')]: {
                width: '46px',
                height: '44px',
                svg: {
                  width: 30,
                },
              },
            }}
          >
            <PrimaryButton fullWidth onClick={() => hideEntranceUpsell()}>
              <ExitIcon />
            </PrimaryButton>
          </Box>

          <Stack
            direction="column"
            maxWidth="700px"
            height="1260px"
            mx="auto"
            sx={{
              [theme.breakpoints.down('md')]: {
                height: '100%',
              },
            }}
          >
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              flex="1"
            >
              <Typography
                fontSize="96px"
                fontWeight="600"
                lineHeight="1"
                color="#F2EAD3"
                textAlign="center"
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: 34,
                  },
                }}
              >
                {processTranslationObject(upsell?.name)}
              </Typography>
            </Stack>

            <Stack
              direction="column"
              alignItems="center"
              flexShrink="0"
              borderRadius="60px"
              padding={5}
              gap={5}
              sx={{
                backdropFilter: 'blur(1.2px)',
                background: 'rgba(50, 19, 19, 0.60)',
                [theme.breakpoints.down('md')]: {
                  gap: 2.5,
                },
              }}
            >
              <UpsellProduct
                productId={upsell?.item}
                onAddToCart={handleProceed}
                withoutBtn={false}
              />

              <Typography
                fontSize="36px"
                color="#F9F5F0"
                textAlign="center"
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: 24,
                  },
                }}
              >
                {processTranslationObject(upsell?.description)}
              </Typography>

              <Stack direction="row" gap={5} alignSelf="stretch">
                <PrimaryButton fullWidth onClick={() => hideEntranceUpsell()}>
                  {t('later')}
                </PrimaryButton>
                <PrimaryButton to={`/product/${upsell?.item}`} fullWidth>
                  {t('orderNow')}
                </PrimaryButton>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Fade>
    </Modal>
  )
}

export default EntranceUpsell
