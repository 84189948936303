export const jsonParser = <TJson>(json: string | null): TJson | null => {
  if (!json) {
    return null
  }

  try {
    return JSON.parse(json) as TJson
  } catch (e) {
    return null
  }
}
