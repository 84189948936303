import { DateTime } from 'luxon'
import useSettings from '@/hooks/useSettings'
import type ISchedule from '@/types/Schedule'
import type IScheduleDay from '@/types/ScheduleDay'

const getPrevDayCloseTime = (
  prevDaySchedule: IScheduleDay,
  orderTime?: string | null,
) => {
  if (!prevDaySchedule) return null

  const date = orderTime ? DateTime.fromISO(orderTime) : DateTime.now()

  const prevDayOpenTime = DateTime.fromFormat(prevDaySchedule.open, 'HH:mm')
    .set({
      year: date.year,
      month: date.month,
      day: date.day,
    })
    .minus({ days: 1 })

  let prevDayCloseTime = DateTime.fromFormat(prevDaySchedule.close, 'HH:mm')
    .set({
      year: date.year,
      month: date.month,
      day: date.day,
    })
    .minus({ days: 1 })

  if (prevDayCloseTime <= prevDayOpenTime) {
    prevDayCloseTime = prevDayCloseTime.plus({ days: 1 })
  }

  return prevDayCloseTime
}

const getCurrentDaySchedule = (
  currentDaySchedule: IScheduleDay,
  orderTime?: string | null,
) => {
  const date = orderTime ? DateTime.fromISO(orderTime) : DateTime.now()

  const currentDayOpenTime = DateTime.fromFormat(
    currentDaySchedule.open,
    'HH:mm',
  ).set({
    year: date.year,
    month: date.month,
    day: date.day,
  })

  let currentDayCloseTime = DateTime.fromFormat(
    currentDaySchedule.close,
    'HH:mm',
  ).set({
    year: date.year,
    month: date.month,
    day: date.day,
  })

  if (currentDayCloseTime <= currentDayOpenTime) {
    currentDayCloseTime = currentDayCloseTime.plus({ days: 1 })
  }

  return { currentDayOpenTime, currentDayCloseTime }
}

const getDateTimeObjects = (orderTime?: string | null) => {
  const date = orderTime ? DateTime.fromISO(orderTime) : DateTime.now()

  const currentDay = date.toFormat('cccc').toUpperCase()
  const prevDay = date.minus({ days: 1 }).toFormat('cccc').toUpperCase()

  return { currentDay, prevDay, currentTime: date }
}

export const isKioskWorkingHours = (
  schedule: ISchedule | null,
  orderTime?: string | null,
) => {
  if (!schedule) return false

  const { currentDay, prevDay, currentTime } = getDateTimeObjects(orderTime)

  const prevDayCloseTime = getPrevDayCloseTime(schedule[prevDay])
  if (prevDayCloseTime && currentTime <= prevDayCloseTime) return true

  const currentDaySchedule = schedule[currentDay]
  if (!currentDaySchedule) return false

  // check if working all day
  if (
    currentDaySchedule.open === '00:00' &&
    currentDaySchedule.close === '00:00'
  )
    return true

  const { currentDayOpenTime, currentDayCloseTime } = getCurrentDaySchedule(
    schedule[currentDay],
    orderTime,
  )

  const greaterThanOpenTime = currentTime >= currentDayOpenTime
  const lessThanCloseTime = currentTime <= currentDayCloseTime

  return greaterThanOpenTime && lessThanCloseTime
}

export const isCategoryWorkingHours = (
  schedule: ISchedule | null,
  orderTime?: string | null,
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: settings } = useSettings()

  if (!settings) return false

  if (!schedule || !Object.keys(schedule).length) {
    return isKioskWorkingHours(settings.schedule, orderTime)
  }

  const { currentDay, prevDay, currentTime } = getDateTimeObjects(orderTime)

  const prevDayCloseTime = getPrevDayCloseTime(schedule[prevDay])

  if (prevDayCloseTime && currentTime <= prevDayCloseTime) return true

  const currentDaySchedule = schedule[currentDay]

  if (!currentDaySchedule) {
    return isKioskWorkingHours(settings.schedule, orderTime)
  }

  // check if working all day
  if (
    currentDaySchedule.open === '00:00' &&
    currentDaySchedule.close === '00:00'
  )
    return true

  const { currentDayOpenTime, currentDayCloseTime } = getCurrentDaySchedule(
    schedule[currentDay],
    orderTime,
  )

  const greaterThanOpenTime = currentTime >= currentDayOpenTime
  const lessThanCloseTime = currentTime <= currentDayCloseTime
  return greaterThanOpenTime && lessThanCloseTime
}
