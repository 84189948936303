import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'

interface ICategoryStore {
  selectedCategoryId: string | null
  setSelectedCategoryId: (id: string) => void
  clearCategoryStore: () => void
}

const useCategoryStore = create<ICategoryStore>((set) => ({
  selectedCategoryId: null,
  setSelectedCategoryId: (id) => set({ selectedCategoryId: id }),
  clearCategoryStore: () => set({ selectedCategoryId: null }),
}))

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('CategoryStore', useCategoryStore)
}

export default useCategoryStore
