import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Fade, Modal, Stack, Typography, useTheme } from '@mui/material'
import CrossIcon from '@/components/icons/CrossIcon'
import PrimaryButton from '@/components/shared/buttons/PrimaryButton'
import useCartStore from '@/store/useCartStore'
import useCategoryStore from '@/store/useCategoryStore'
import useSettingsStore from '@/store/useSettinsStore'

const ResetAppBanner = () => {
  const { t } = useTranslation('global')
  const theme = useTheme()
  const navigate = useNavigate()
  const clearCartStore = useCartStore((s) => s.clearCartStore)
  const clearCategoryStore = useCategoryStore((s) => s.clearCategoryStore)
  const clearSettingsStore = useSettingsStore((s) => s.clearSettingsStore)

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const resetApp = () => {
    handleClose()
    clearCartStore()
    clearCategoryStore()
    clearSettingsStore()

    if (window.navigator.onLine) {
      window.location.href = '/'
    } else {
      navigate('/')
    }
  }

  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        py={1.25}
        position="relative"
        sx={{
          bgcolor: theme.palette.primary.midtone,
          [theme.breakpoints.down('md')]: {
            ml: 0,
            p: '5px 0',
            svg: {
              width: '15px',
            },
          },
        }}
        onClick={handleOpen}
      >
        <Box
          p={1.25}
          borderRadius="10px"
          border={`2px solid ${theme.palette.primary.main}`}
          sx={{
            bgcolor: theme.palette.primary.midtone,
            [theme.breakpoints.down('md')]: {
              p: 0.8,
            },
          }}
        >
          <CrossIcon paletteColor={theme.palette.primary} />
        </Box>

        <Typography
          variant="smallBody"
          fontWeight="600"
          ml={3}
          sx={{
            [theme.breakpoints.down('md')]: {
              ml: 2,
              fontSize: '14px',
            },
          }}
        >
          {t('resetAppBannerText')}
        </Typography>
      </Stack>

      <Modal open={open} onClose={handleClose}>
        <Fade in={open}>
          <Box
            width="100%"
            maxWidth="1000px"
            position="absolute"
            top="50%"
            left="50%"
            borderRadius="100px"
            p={7.5}
            sx={{
              transform: 'translate(-50%, -50%)',
              background: theme.palette.primary.light,
              [theme.breakpoints.down('md')]: {
                borderRadius: '30px',
                p: 2,
                width: '94%',
              },
            }}
          >
            <Box
              width={100}
              height={100}
              position="absolute"
              sx={{
                transform: 'translate(-50%, -50%)',
                top: '-20%',
                left: '50%',
                [theme.breakpoints.down('md')]: {
                  width: '46px',
                  height: '44px',
                  svg: {
                    width: 30,
                  },
                },
              }}
            >
              <PrimaryButton fullWidth onClick={handleClose}>
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 46L25.0001 25.0001M25.0001 25.0001L46 4M25.0001 25.0001L4 4M25.0001 25.0001L46 46"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </PrimaryButton>
            </Box>
            <Box maxWidth="730px" mx="auto">
              <Typography
                fontSize="40px"
                lineHeight="1.2"
                color="primary.dark"
                textAlign="center"
                mb={7.5}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: '20px',
                    mb: 2,
                  },
                }}
              >
                {t('resetModalTitle')}
              </Typography>
            </Box>

            <Stack
              direction="column"
              gap={3.7}
              maxWidth="700px"
              mx="auto"
              sx={{
                [theme.breakpoints.down('md')]: {
                  gap: 2,
                },
              }}
            >
              <PrimaryButton fullWidth onClick={resetApp}>
                {t('resetModalAcceptButton')}
              </PrimaryButton>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

export default ResetAppBanner
