import { Fragment, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Fade,
  Link,
  Modal,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import BigLeftArrowIcon from '@/components/icons/BigLeftArrowIcon'
import ExitIcon from '@/components/icons/ExitIcon'
import LocationIcon from '@/components/icons/home/LocationIcon'
import PhoneIcon from '@/components/icons/home/PhoneIcon'
import CompanyLogo from '@/components/menu/CompanyLogo'
import PrimaryButton from '@/components/shared/buttons/PrimaryButton'
import SecondaryButton from '@/components/shared/buttons/SecondaryButton'
import useSettings from '@/hooks/useSettings'
// import { useTranslation } from "react-i18next";
import { useDocumentsList } from '@/utils/consts'

interface Props {
  open: boolean
  setOpen: (e: boolean) => void
  currentStep?: number | null
}

const InfoModal = ({ open, setOpen, currentStep }: Props) => {
  const theme = useTheme()
  // const { i18n } = useTranslation();
  const DOCUMENTS_LIST = useDocumentsList()

  const { data: settings } = useSettings()

  const [step, setStep] = useState<number>(0)

  useEffect(() => {
    if (currentStep) {
      setStep(currentStep)
    }
  }, [currentStep])

  const handleClose = () => setOpen(false)

  return (
    <Modal open={open} onClose={handleClose}>
      <Fade in={open}>
        <Box
          sx={{
            width: '94%',
            height: '78%',
            position: 'absolute',
            top: '53%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: theme.palette.primary.light,
            borderRadius: '30px',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              top: '-40px',
              left: '50%',
              width: '46px',
              svg: {
                width: 30,
              },
            }}
          >
            <PrimaryButton fullWidth onClick={handleClose}>
              <ExitIcon />
            </PrimaryButton>
          </Box>

          {step === 0 ? (
            <Stack
              height="100%"
              direction="column"
              justifyContent="space-between"
            >
              <Stack alignItems="center" gap={3} p="24px 16px">
                <CompanyLogo />

                <Stack width="100%" alignItems="flex-start">
                  {DOCUMENTS_LIST.map((item, index) => {
                    const DocumentIcon = item.icon
                    const isLast = index === DOCUMENTS_LIST.length - 1

                    return (
                      <Button
                        key={item.id}
                        sx={{
                          width: '100%',
                          justifyContent: 'flex-start',
                          gap: 1,
                          color: theme.palette.primary.dark,
                          fontSize: 20,
                          borderBottom: isLast
                            ? 'none'
                            : `1px solid ${theme.palette.primary.midtone}`,
                        }}
                        onClick={() => setStep(item.id)}
                      >
                        <DocumentIcon />
                        {item.name}
                      </Button>
                    )
                  })}
                </Stack>
              </Stack>

              <Stack
                gap={1}
                p="16px"
                sx={{
                  borderRadius: '30px',
                  backgroundColor: 'white',
                }}
              >
                <Typography fontSize={14} fontWeight={600}>
                  {settings?.locationName}
                </Typography>

                {settings?.phone && (
                  <Stack direction="row" gap={1}>
                    <PhoneIcon paletteColor={theme.palette.primary} />

                    <Link
                      color={theme.palette.primary.dark}
                      href={`tel: ${settings?.phone}`}
                      width="100%"
                      fontSize={16}
                    >
                      {settings?.phone}
                    </Link>
                  </Stack>
                )}

                {settings?.address?.city && (
                  <Stack direction="row" gap={1}>
                    <LocationIcon paletteColor={theme.palette.primary} />

                    <Box
                      component="address"
                      color={theme.palette.primary.dark}
                      width="100%"
                      fontSize={16}
                    >
                      {settings?.address?.city}
                    </Box>
                  </Stack>
                )}
              </Stack>
            </Stack>
          ) : (
            <Box
              sx={{
                height: '100%',
                overflow: 'hidden',
                p: '24px 16px',
              }}
            >
              {DOCUMENTS_LIST.map((item) => {
                // const DocumentComponent = item.components[i18n.language];
                const DocumentComponent = item.components.he // now only HE
                if (item.id === step) {
                  return (
                    <Fragment key={item.id}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        p="0 5px"
                      >
                        <Box
                          sx={{
                            width: '46px',
                            svg: {
                              width: 30,
                            },
                          }}
                        >
                          <SecondaryButton onClick={() => setStep(0)}>
                            <BigLeftArrowIcon
                              paletteColor={theme.palette.primary}
                            />
                          </SecondaryButton>
                        </Box>

                        <Typography variant="h6" fontWeight={600}>
                          {item.name}
                        </Typography>
                      </Stack>

                      <DocumentComponent
                        sx={{
                          height: '96%',
                          overflowX: 'scroll',
                          paddingBottom: '20px',
                          pt: 2,
                        }}
                      />
                    </Fragment>
                  )
                }
                return null
              })}
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>
  )
}

export default InfoModal
