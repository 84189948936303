import { useTheme } from '@mui/material'

const PrivacyPolicyIcon = () => {
  const theme = useTheme()

  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.045 21.077C10.0228 20.4765 8.34302 19.2517 7.00581 17.4026C5.6686 15.5534 5 13.4698 5 11.1516V5.70884L12.045 3.07703L19.09 5.70884V11.1516C19.09 12.2808 18.9146 13.3876 18.5637 14.4718C18.2127 15.5568 17.6683 16.5957 16.9302 17.5887L13.8254 14.4849C13.5751 14.6822 13.2973 14.8301 12.9921 14.9287C12.6868 15.0274 12.3711 15.0767 12.045 15.0767C11.215 15.0767 10.5042 14.7811 9.91238 14.19C9.3206 13.5989 9.02505 12.888 9.02572 12.0574C9.02639 11.2268 9.32195 10.5159 9.91238 9.92478C10.5028 9.33367 11.2137 9.03811 12.045 9.03811C12.8763 9.03811 13.5872 9.33367 14.1776 9.92478C14.7681 10.5159 15.0636 11.2268 15.0643 12.0574C15.0643 12.3681 15.0183 12.668 14.9264 12.9571C14.8338 13.2463 14.7084 13.5181 14.55 13.7724L16.7944 16.0167C17.1815 15.2907 17.4932 14.5279 17.7293 13.7281C17.9655 12.9276 18.0836 12.0688 18.0836 11.1516V6.39623L12.045 4.15189L6.00643 6.39623V11.1516C6.00643 13.1812 6.57674 15.0264 7.71736 16.687C8.85798 18.3476 10.3005 19.4547 12.045 20.0082C12.4811 19.874 12.8964 19.7022 13.291 19.4929C13.6855 19.2836 14.0753 19.036 14.4604 18.7502L15.0945 19.501C14.5933 19.8633 14.1068 20.1706 13.6352 20.4228C13.1635 20.6745 12.6334 20.8925 12.045 21.077ZM12.045 14.0703C12.5985 14.0703 13.0726 13.8733 13.4671 13.4795C13.8616 13.0856 14.0585 12.6116 14.0579 12.0574C14.0572 11.5032 13.8603 11.0295 13.4671 10.6363C13.0739 10.2431 12.5999 10.0459 12.045 10.0445C11.4901 10.0432 11.0164 10.2405 10.6239 10.6363C10.2314 11.0322 10.0342 11.5059 10.0321 12.0574C10.0301 12.6089 10.2274 13.083 10.6239 13.4795C11.0205 13.876 11.4942 14.0729 12.045 14.0703Z"
        fill={theme.palette.primary.dark}
      />
    </svg>
  )
}

export default PrivacyPolicyIcon
