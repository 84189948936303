import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import PaymentErrorModal from '@/components/payment/PaymentErrorModal'
import PayWithCash from '@/components/payment/PayWithCash'
import usePaymentErrorStore from '@/store/usePaymentErrorStore'

const Balamut = () => {
  const theme = useTheme()
  const { t } = useTranslation('payment')
  const errorMessage = usePaymentErrorStore((s) => s.errorMessage)

  return (
    <>
      <Stack height="100%" direction="column" alignItems="center" pb={10}>
        <Box
          width="100%"
          px={10}
          pt={10}
          pb={5}
          borderBottom={`1px solid ${theme.palette.primary.dark}`}
        >
          <Typography variant="h1" textAlign="center">
            {t('payHere')}
          </Typography>
        </Box>

        {!errorMessage && <PayWithCash />}
      </Stack>

      <PaymentErrorModal />
    </>
  )
}

export default Balamut
