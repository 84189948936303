const AttentionIcon = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M83.5121 87.5001H16.4877C10.0793 87.5001 6.0691 80.5685 9.26352 75.0131L42.7759 16.7307C45.98 11.1582 54.02 11.1581 57.2242 16.7307L90.7367 75.0131C93.9308 80.5685 89.9208 87.5001 83.5121 87.5001Z"
        stroke="#F4991A"
        strokeWidth="7"
        strokeLinecap="round"
      />
      <path
        d="M50 37.5V54.1667"
        stroke="#F4991A"
        strokeWidth="7"
        strokeLinecap="round"
      />
      <path
        d="M50 70.8749L50.0417 70.8286"
        stroke="#F4991A"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default AttentionIcon
