import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Stack, useTheme } from '@mui/material'
import CompanyIcon from '@/components/icons/payment/CompanyIcon'
import PaymentOptionButton from '@/components/payment/PaymentOptionButton'
import ReadCard from '@/components/payment/ReadCard'
import useCibusCompanies from '@/hooks/useCibusCompanies'
import PaymentLayout from '@/layouts/PaymentLayout'
import { payWithCibus } from '@/services/payment'
import useCartStore from '@/store/useCartStore'
import usePaymentErrorStore from '@/store/usePaymentErrorStore'
import { usePaymentsList } from '@/utils/consts'
import type ICibusCompany from '@/types/CibusCompany'

const Terminal = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation('payment')

  const [searchParams] = useSearchParams()
  const company = searchParams.get('company')

  const getCartTotalPrice = useCartStore((s) => s.getCartTotalPrice)
  const price = getCartTotalPrice()

  const { data: companies } = useCibusCompanies() as {
    data: { companyCodes?: ICibusCompany[] }
  }
  const errorMessage = usePaymentErrorStore((s) => s.errorMessage)
  const throwError = usePaymentErrorStore((s) => s.throwError)

  const setPaymentResult = useCartStore((s) => s.setPaymentResult)
  const foundCompany = companies?.companyCodes?.find(
    (item) => item.name === company,
  )

  const PAYMENTS_LIST = usePaymentsList()

  const makePayment = (cardNumber: string) => {
    payWithCibus(price, cardNumber, foundCompany?.code || 0)
      .then((data) => {
        if (data.status) {
          setPaymentResult({
            id: PAYMENTS_LIST.CIBUS.id,
            amount: price,
            transactionId: data.transactionId,
            card: cardNumber,
          })
          navigate('/payment/success')
        } else {
          throwError('cibus', data.errorDescription)
        }
      })
      .catch((err) => {
        console.log(err)
        throwError('cibus', t('generalError'))
      })
  }

  return (
    <PaymentLayout
      title={t('cibusTitle')}
      subTitle={company ? t('choosenCompanyIs') : ''}
      backTo="/payment/cibus"
      allowBack={false}
    >
      <Stack
        direction="column"
        justifyContent="flex-end"
        height="100%"
        sx={{
          [theme.breakpoints.down('md')]: {
            m: '0 10px !important',
            height: 'auto',
          },
        }}
      >
        {company ? (
          <Box
            width="100%"
            maxWidth="785px"
            mx="auto"
            mb="auto"
            sx={{
              [theme.breakpoints.down('md')]: {
                m: '0 0 !important',
              },
            }}
          >
            <PaymentOptionButton
              name={company}
              icon={<CompanyIcon color={theme.palette.primary.dark} />}
            />
          </Box>
        ) : null}

        {!errorMessage && (
          <ReadCard
            onCardRead={makePayment}
            onError={(errorMessage) => throwError('cibus', errorMessage)}
          />
        )}
      </Stack>
    </PaymentLayout>
  )
}

export default Terminal
