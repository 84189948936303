import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import IDeliveryDetail from '@/types/DeliveryDetail'
import PrimaryButton from '../shared/buttons/PrimaryButton'

interface Props {
  deliveryInfo: IDeliveryDetail[] | null
  loading: boolean
}

const DeliveryInformation = ({ deliveryInfo, loading }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation('delivery')
  const navigate = useNavigate()

  if (loading) return null

  if (!deliveryInfo)
    return (
      <Stack
        height="100%"
        direction="column"
        alignItems="center"
        justifyContent="center"
        px={8}
        borderLeft={`1px solid ${theme.palette.primary.midtone}`}
        sx={{
          [theme.breakpoints.down('md')]: {
            p: '20px 0',
          },
        }}
      >
        <Typography
          fontSize="32px"
          textAlign="center"
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: 18,
              mb: '10px',
            },
          }}
        >
          {t('deliveryNotAvailableText')}
        </Typography>

        <PrimaryButton onClick={() => navigate('/delivery/address')}>
          {t('changeAddress')}
        </PrimaryButton>
      </Stack>
    )

  return (
    <>
      {deliveryInfo.map((item, index) => (
        <Box
          key={index}
          borderBottom={
            index !== deliveryInfo.length - 1
              ? `2px solid ${theme.palette.primary.midtone}`
              : 'none'
          }
          borderLeft={`1px solid ${theme.palette.primary.midtone}`}
          py={2.5}
          px={5}
          sx={{
            [theme.breakpoints.down('md')]: {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 2,
            },
          }}
        >
          <Typography
            variant="body"
            fontWeight="600"
            mb={2.5}
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: 16,
                mb: 0,
              },
            }}
          >
            {item.title}
          </Typography>

          <Typography
            fontSize="36px"
            fontWeight="600"
            lineHeight="42px"
            color="primary.main"
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: 16,
              },
            }}
          >
            {item.value}
          </Typography>
        </Box>
      ))}
    </>
  )
}

export default DeliveryInformation
