import { Stack } from '@mui/material'
import { useProcessTranslationObject } from '@/services/translation'
import type IChoice from '@/types/Choice'
import ChoiceCheckbox from './ChoiceCheckbox'
import ChoiceImage from './ChoiceImage'
import ChoicePrice from './ChoicePrice'
import ChoiceTitle from './ChoiceTitle'
import ChoiceWrapper from './ChoiceWrapper'

interface Props {
  choice: IChoice
  isDisabled: boolean
  showPrice: boolean
  onModify: (quantity: number) => void
}

const ChoiceWithCheckbox = ({
  choice,
  isDisabled,
  showPrice,
  onModify,
}: Props) => {
  const { processTranslationObject } = useProcessTranslationObject()
  const isSelected = choice.quantity > 0
  const isFree = choice.freeQuantity > 0

  return (
    <ChoiceWrapper
      isFree={choice.freeQuantity > 0}
      isSelected={isSelected}
      isDisabled={!isSelected && isDisabled}
      onClick={() => onModify(choice.quantity === 0 ? 1 : 0)}
    >
      <ChoiceImage url={choice.imageUrl} />

      <ChoiceTitle
        title={processTranslationObject(choice.name)}
        calories={choice.calories}
      />

      <Stack direction="row" alignItems="center" spacing={2.5}>
        {!isFree && (showPrice || isSelected) && (
          <ChoicePrice price={choice.price} />
        )}

        <ChoiceCheckbox isSelected={isSelected} />
      </Stack>
    </ChoiceWrapper>
  )
}

export default ChoiceWithCheckbox
