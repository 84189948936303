import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import PrimaryButton from '@/components/shared/buttons/PrimaryButton'
import { isKioskWorkingHours } from '@/services/working-hours'
import AttentionIcon from '../components/icons/AttentionIcon'
import CompanyLogo from '../components/menu/CompanyLogo'
import useSettings from '../hooks/useSettings'

const UnavailableKiosk = () => {
  const theme = useTheme()
  const { t } = useTranslation('unavailableKiosk')
  const [searchParams] = useSearchParams()
  const reason = searchParams.get('reason')

  const { data: settings, dataUpdatedAt } = useSettings({
    refetchInterval: 60 * 1000,
  }) // 1 minute
  const navigate = useNavigate()

  useEffect(() => {
    if (settings && settings.active && isKioskWorkingHours(settings.schedule)) {
      navigate('/')
    }
  }, [dataUpdatedAt])

  return (
    <Stack
      position="relative"
      width="100%"
      height="100%"
      sx={{
        background: `url(${settings?.backgroundImageUrl}) center / cover no-repeat`,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        margin="0 auto"
        width="100%"
        height="100%"
        sx={{
          backgroundColor: 'rgba(211,211,211,0.55)',
          backdropFilter: 'blur(5px)',
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          width={300}
          height={200}
          padding="30px"
          borderRadius="0 0 30px 30px"
          boxShadow="10px 10px 20px 0px rgba(50, 19, 19, 0.40)"
          sx={{
            background: `${theme.palette.primary.light}`,
            [theme.breakpoints.down('md')]: {
              p: 2,
              width: '90%',
              height: 'auto',
            },
          }}
        >
          <CompanyLogo />
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Box
            width={1000}
            height={430}
            borderRadius="30px"
            textAlign="center"
            boxShadow="10px 10px 20px 0px rgba(50, 19, 19, 0.40)"
            sx={{
              background: `${theme.palette.primary.light}`,
              padding: '10px',
              [theme.breakpoints.down('md')]: {
                width: '90%',
                height: 'auto',
                pb: 2,
              },
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              m="70px 0 40px"
              sx={{
                [theme.breakpoints.down('md')]: {
                  m: 2,
                  svg: {
                    width: 50,
                  },
                },
              }}
            >
              <AttentionIcon />
            </Box>

            {reason === 'not-working-hours' ? (
              <Box>
                <Typography
                  variant="h3"
                  fontSize={40}
                  mb={2}
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      fontSize: '18px',
                    },
                  }}
                >
                  {t('phrase1')}
                </Typography>
                <Typography
                  variant="h3"
                  fontSize={40}
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      fontSize: '18px',
                    },
                  }}
                >
                  You can make an order for another time
                </Typography>
                <PrimaryButton fullWidth to="/where-to-eat">
                  Make an order
                </PrimaryButton>
              </Box>
            ) : (
              <>
                <Typography
                  variant="h3"
                  fontSize={40}
                  mb={2}
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      fontSize: '18px',
                    },
                  }}
                >
                  {t('phrase3')}
                </Typography>
                <Typography
                  variant="h3"
                  fontSize={40}
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      fontSize: '18px',
                    },
                  }}
                >
                  {t('phrase4')}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Stack>
  )
}

export default UnavailableKiosk
