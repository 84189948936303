import { Typography } from '@mui/material'
import { useProcessTranslationObject } from '@/services/translation'

interface Props {
  option: any
}

const SingleChoiceOption = ({ option }: Props) => {
  const { processTranslationObject } = useProcessTranslationObject()

  return (
    <Typography
      component="span"
      display="inline-block"
      fontSize={{ xs: '16px' }}
      color="#000"
      mr={0.5}
    >
      {processTranslationObject(option.name)}:
      {processTranslationObject(option.choices[0].name)}
    </Typography>
  )
}

export default SingleChoiceOption
