import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, useTheme } from '@mui/material'
import { LANGUAGES, NUMBERS_SYMBOLS } from './languages/Languages'

const languages = Object.keys(LANGUAGES)

interface Props {
  openKeyboard: boolean
  setOpenKeyboard: Dispatch<SetStateAction<boolean>>
  inputValue: string
  setInputValue: Dispatch<SetStateAction<string>>
  onlyNumber: boolean
}

const Keyboard = ({
  openKeyboard,
  setOpenKeyboard,
  inputValue,
  setInputValue,
  onlyNumber,
}: Props) => {
  const theme = useTheme()
  const { i18n } = useTranslation('home')
  const currentLanguage = languages.indexOf(i18n.language)

  const [currentLanguageIndex, setCurrentLanguageIndex] =
    useState<number>(currentLanguage)
  const [currentLanguageKeyboard, setCurrentLanguageKeyboard] = useState(
    i18n.language,
  )
  const [upperCaseLetters, setUpperCaseLetter] = useState<boolean>(false)
  const [openNumbersAndSymbols, setOpenNumbersAndSymbols] =
    useState<boolean>(false)

  const keyboardButtons =
    openNumbersAndSymbols || !onlyNumber
      ? NUMBERS_SYMBOLS.elements
      : LANGUAGES[currentLanguageKeyboard]

  const handleChange = (button: string) => {
    if (button === '⌫') {
      return setInputValue((prevValue) => prevValue.slice(0, -1))
    }
    if (button === '⇩') {
      return setOpenKeyboard(false)
    }
    if (button === '123') {
      return setOpenNumbersAndSymbols((prevState) => !prevState)
    }

    if (!onlyNumber && inputValue.length >= 10) {
      return
    }

    if (button === '') {
      setInputValue((prevState) => `${prevState} `)
    } else if (button === 'language') {
      setCurrentLanguageIndex((prevIndex) => (prevIndex + 1) % languages.length)
      setCurrentLanguageKeyboard(
        () => languages[(currentLanguageIndex + 1) % languages.length],
      )
    } else if (button === '⇪') {
      setUpperCaseLetter((prevState) => !prevState)
    } else if (button === 'abc') {
      setOpenNumbersAndSymbols((prevState) => !prevState)
    } else if (!onlyNumber) {
      const isNumber = /^\d+$/.test(inputValue + button)
      if (isNumber) {
        setInputValue((prevState) => prevState + button)
      }
    } else {
      setInputValue(
        upperCaseLetters
          ? inputValue + button.toUpperCase().toString()
          : inputValue + button.toString(),
      )
    }
  }

  const buttonElement = (button: string) => {
    // eslint-disable-next-line no-nested-ternary
    return button === 'language'
      ? ' '
      : upperCaseLetters
        ? button.toUpperCase()
        : button
  }

  const languageImg = () => {
    return {
      width: 220,
      height: 133,
      padding: '0 55px',
      borderRadius: 5,
      background: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.0002 36.6666C29.2049 36.6666 36.6668 29.2047 36.6668 19.9999C36.6668 10.7952 29.2049 3.33325 20.0002 3.33325C10.7954 3.33325 3.3335 10.7952 3.3335 19.9999C3.3335 29.2047 10.7954 36.6666 20.0002 36.6666Z' stroke='%23${theme.palette.primary.light.slice(
        1,
      )}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.0002 3.33325C15.7206 7.82684 13.3335 13.7945 13.3335 19.9999C13.3335 26.2054 15.7206 32.173 20.0002 36.6666C24.2798 32.173 26.6668 26.2054 26.6668 19.9999C26.6668 13.7945 24.2798 7.82684 20.0002 3.33325Z' stroke='%23${theme.palette.primary.light.slice(
        1,
      )}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3.3335 20H36.6668' stroke='%23${theme.palette.primary.light.slice(
        1,
      )}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")`,
      backgroundColor: theme.palette.primary.dark,
      '&:hover': {
        bgcolor: theme.palette.primary.dark,
      },
    }
  }

  return (
    <Box>
      <Box
        position="absolute"
        bottom={0}
        left={0}
        zIndex={1301}
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        padding="40px 10px 0 10px"
        sx={{
          bgcolor: theme.palette.primary.midtone,
          borderTopLeftRadius: 100,
          borderTopRightRadius: 100,
          visibility: openKeyboard ? 'visible' : 'hidden',
          height: openKeyboard ? '700px' : '0px',
          overflow: 'hidden',
          transition: 'height .5s ease, visibility .5s ease',
        }}
      >
        {keyboardButtons.map((rowButtons, rowButtonsIndex) => (
          <Box key={rowButtonsIndex} width="100%" margin="6px 0">
            {rowButtons.map((buttons, buttonsIndex) => (
              <Box
                key={buttonsIndex}
                display="flex"
                justifyContent="space-evenly"
                width="100%"
                margin="0"
              >
                {buttons.split(' ').map((button, index) => (
                  <Button
                    key={index}
                    onClick={() => handleChange(button)}
                    sx={
                      button !== 'language'
                        ? {
                            width: button === '' ? 5000 : '100%',
                            height: 133,
                            backgroundColor: theme.palette.primary.dark,
                            color: theme.palette.primary.light,
                            fontSize: 40,
                            borderRadius: 5,
                            margin: '0 5px',
                            padding: '6px 10px',
                            '&:hover': {
                              bgcolor: theme.palette.primary.dark,
                            },
                          }
                        : languageImg()
                    }
                  >
                    {buttonElement(button)}
                  </Button>
                ))}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default Keyboard
