const LeftPartPizzaIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="42"
      viewBox="0 0 41 42"
      fill="none"
    >
      <path
        d="M20.5 41C17.8736 41 15.2728 40.4827 12.8463 39.4776C10.4198 38.4725 8.21503 36.9993 6.35786 35.1421C4.50069 33.285 3.0275 31.0802 2.02241 28.6537C1.01731 26.2272 0.499999 23.6264 0.499999 21C0.499999 18.3736 1.01731 15.7728 2.02241 13.3463C3.0275 10.9198 4.50069 8.71503 6.35786 6.85786C8.21504 5.00069 10.4198 3.5275 12.8463 2.52241C15.2728 1.51731 17.8736 0.999999 20.5 0.999999L20.5 21L20.5 41Z"
        fill="#321313"
      />
      <circle
        cx="20.5"
        cy="21"
        r="20"
        transform="rotate(-90 20.5 21)"
        stroke="#321313"
      />
    </svg>
  )
}

export default LeftPartPizzaIcon
