import { useEffect, useState } from 'react'
import { CircularProgress, Stack } from '@mui/material'
import { CanceledError } from 'axios'
import PaymentErrorModal from '@/components/payment/PaymentErrorModal'
import { payWithPeleCard } from '@/services/payment'
import usePaymentErrorStore from '@/store/usePaymentErrorStore'
import useCartStore from '../../../store/useCartStore'

const PeleCard = () => {
  // eslint-disable-next-line no-restricted-globals
  const params = new URLSearchParams(location.search)
  const ErrorStatus = params.get('ErrorStatus')

  const getCartTotalPrice = useCartStore((s) => s.getCartTotalPrice)
  const { name, email } = useCartStore((s) => s.customer)
  const throwError = usePaymentErrorStore((s) => s.throwError)
  const price = getCartTotalPrice()

  const [pelePayment, setPelePayment] = useState<{
    url: string
    errorDescription: string
  }>({
    url: '',
    errorDescription: '',
  })

  useEffect(() => {
    if (ErrorStatus) {
      throwError('peleCard', pelePayment.errorDescription)
    }
  }, [ErrorStatus])

  useEffect(() => {
    payWithPeleCard(price * 100, name, email)
      .then(({ errorCode, errorDescription, status, url }) => {
        if (status) {
          setPelePayment({ url, errorDescription })
        } else {
          console.error(
            `Error code: ${errorCode}; Error description: ${errorDescription}`,
          )
        }
      })
      .catch((err) => {
        if (err instanceof CanceledError) return

        console.log(err)
      })
  }, [])

  return (
    <>
      {pelePayment.url ? (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe
          allow="payment"
          src={pelePayment.url}
          style={{ border: 0, width: '100%', height: '100vh' }}
        />
      ) : (
        <Stack
          height="100%"
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress color="primary" size="100px" />
        </Stack>
      )}

      <PaymentErrorModal />
    </>
  )
}

export default PeleCard
