import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import BackButton from '@/components/shared/BackButton'
import useSettings from '@/hooks/useSettings'
import MainLayout from '@/layouts/MainLayout'
import { queryClient } from '@/main'
import { axiosInstance } from '@/services/api-client'
import { getChanelId } from '@/services/auth'
import { getMenu } from '@/services/menu'
import { isKioskWorkingHours } from '@/services/working-hours'
import useCartStore from '@/store/useCartStore'
import { useOrderTypesList } from '@/utils/consts'
import type IMenu from '@/types/Menu'
import type OrderType from '@/types/OrderType'

const WhereToEat = () => {
  const { t } = useTranslation('whereToEat')
  const theme = useTheme()
  const navigate = useNavigate()
  const setOrderType = useCartStore((s) => s.setOrderType)
  const { selectedDate } = useCartStore()

  const { data: settings } = useSettings()
  const channelId = getChanelId()
  const orderTypes = settings?.orderTypes
  const skipOrderTypeSelection = orderTypes && orderTypes.length < 2

  const ORDER_TYPES_LIST = useOrderTypesList()

  const menu = queryClient.getQueryData<IMenu>(['menu', channelId])
  const [isLoading, setIsLoading] = useState(true)

  const prefetchMenu = () => {
    queryClient.prefetchQuery({
      queryKey: ['menu', channelId],
      queryFn: getMenu,
      staleTime: Infinity,
    })
  }

  if (!menu) {
    prefetchMenu()
  }

  const proccess = (orderType: OrderType) => {
    setOrderType(orderType)

    if (orderType === 'DELIVERY') {
      navigate('/delivery')
    } else if (!isKioskWorkingHours(settings?.schedule || null)) {
      navigate('/delayed-order')
    } else {
      navigate('/menu')
    }
  }

  const checkMenuChanges = async (timestamp: string) => {
    try {
      setIsLoading(false)
      const result = await axiosInstance.get(
        `/menus/check-changes?modified_time=${timestamp}`,
      )
      if (result.data) {
        queryClient.removeQueries({
          queryKey: ['menu', channelId],
          exact: true,
        })
        prefetchMenu()
      }

      if (skipOrderTypeSelection) {
        proccess(orderTypes[0])
      }
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  // const checkMenuChanges = (timestamp: string) => {
  //   const apiClient = new APIClient(
  //     `/menus/check-changes?modified_time=${timestamp}`,
  //   )
  //   apiClient
  //     .get()
  //     .then((res) => {
  //       setIsLoading(false)
  //
  //       if (res) {
  //         queryClient.removeQueries({
  //           queryKey: ['menu', channelId],
  //           exact: true,
  //         })
  //         prefetchMenu()
  //       }
  //
  //       if (skipOrderTypeSelection) {
  //         proccess(orderTypes[0])
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoading(false)
  //       console.error(err)
  //     })
  // }

  useEffect(() => {
    if (menu) {
      checkMenuChanges(menu.modifiedTime)
      return
    }
    if (skipOrderTypeSelection) {
      proccess(orderTypes[0])
      return
    }

    setIsLoading(false)
  }, [])

  if (isLoading) {
    return (
      <Stack
        height="100%"
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress color="primary" size="100px" />
      </Stack>
    )
  }

  return (
    <MainLayout showDeliverySteps={false}>
      <Stack
        direction="column"
        alignItems="center"
        sx={{
          flex: 1,
          py: 10,
          [theme.breakpoints.down('md')]: {
            py: 1,
            justifyContent: 'center',
            height: '100%',
          },
        }}
      >
        <Box
          mb={5}
          sx={{
            [theme.breakpoints.down('md')]: {
              mb: 1.5,
              display: 'none',
            },
          }}
        >
          {!selectedDate ? <BackButton to="/" /> : <BackButton to="/" />}
        </Box>
        <Box
          sx={{
            width: '1px',
            height: '100px',
            background: theme.palette.primary.dark,
            mb: 5,
            [theme.breakpoints.down('md')]: {
              mb: 1,
              height: '40px',
              display: 'none',
            },
          }}
        />

        <Typography
          variant="h1"
          textAlign="center"
          mb={26}
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '34px',
              mb: 5,
            },
          }}
        >
          {t('welcomeText')}
        </Typography>

        <Typography
          variant="body"
          textAlign="center"
          mb={7.5}
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '16px',
              mb: 3,
            },
          }}
        >
          {t('optionsTitle')}
        </Typography>

        {orderTypes?.map((orderType) => {
          const orderTypeInfo = ORDER_TYPES_LIST[orderType]

          if (orderTypeInfo) {
            const OrderTypeIcon = orderTypeInfo.icon

            return (
              <Box key={orderTypeInfo.id} width="100%" textAlign="center">
                <Button
                  variant="text"
                  fullWidth
                  sx={{
                    flexDirection: 'column',
                    gap: 2.5,
                    maxWidth: '800px',
                    borderRadius: '60px',
                    bgcolor: theme.palette.primary.light,
                    p: 5,
                    mb: 5,
                    ':hover': {
                      bgcolor: theme.palette.primary.light,
                    },
                    [theme.breakpoints.down('md')]: {
                      width: '80%',
                      gap: 0,
                      mb: 2,
                      p: 2,
                      pr: 0,
                      pl: 0,
                      borderRadius: '20px',
                      svg: {
                        width: '30px',
                      },
                    },
                  }}
                  onClick={() => proccess(orderType)}
                >
                  <Typography
                    variant="h3"
                    textAlign="center"
                    sx={{
                      [theme.breakpoints.down('md')]: {
                        fontSize: '20px',
                        mb: 1,
                      },
                    }}
                  >
                    {orderTypeInfo.name}
                  </Typography>

                  <OrderTypeIcon paletteColor={theme.palette.primary} />
                </Button>
              </Box>
            )
          }
          return null
        })}
      </Stack>
    </MainLayout>
  )
}

export default WhereToEat
