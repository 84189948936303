import { ChangeEvent, forwardRef } from 'react'
import { Input, useTheme } from '@mui/material'

interface Props {
  type: string
  name: string
  placeholder: string
  value: string
  disabled?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const CustomInput = forwardRef(
  ({ type, placeholder, value, disabled = false, onChange }: Props, ref) => {
    const theme = useTheme()

    return (
      <Input
        inputRef={ref}
        type={type}
        disableUnderline
        fullWidth
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          if (onChange) {
            onChange(e)
          }
        }}
        sx={{
          flex: 1,
          px: 5,
          py: 3.25,
          borderRadius: '30px',
          border: `2px solid ${theme.palette.primary.main}`,
          background: theme.palette.common.white,
          [theme.breakpoints.down('md')]: {
            p: '10px',
            borderRadius: '10px',
          },

          '& .MuiInput-input': {
            textAlign: 'center',
            fontSize: '48px',
            lineHeight: 1.2,
            fontWeight: 600,
            color: theme.palette.primary.dark,
            [theme.breakpoints.down('md')]: {
              fontSize: '16px',
              p: 0,
            },
          },

          '& .MuiInput-input::placeholder': {
            color: theme.palette.primary.midtone,
            opacity: 1,
          },
        }}
      />
    )
  },
)

export default CustomInput
