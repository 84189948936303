import { ChangeEvent, KeyboardEvent } from 'react'
import { Control, Controller } from 'react-hook-form'
import { Input, useTheme } from '@mui/material'

interface Props {
  type: string
  name: string
  placeholder: string
  control: Control<any>
  rules: {
    required: string | boolean
    pattern?: {
      value: RegExp
      message: string
    }
  }
  value?: string
  disabled?: boolean
  onFocus?: () => void
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const CustomInputControlled = ({
  type,
  name,
  placeholder,
  control,
  rules,
  value,
  disabled = false,
  onFocus,
  onKeyDown,
  onChange,
}: Props) => {
  const theme = useTheme()

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange: onChangeController } }) => (
        <Input
          type={type}
          disableUnderline
          fullWidth
          disabled={disabled}
          placeholder={placeholder}
          onChange={(e) => {
            onChangeController(e)
            if (onChange) {
              onChange(e)
            }
          }}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          value={value}
          sx={{
            flex: 1,
            px: 5,
            py: 3.25,
            borderRadius: '30px',
            border: `2px solid ${theme.palette.primary.main}`,
            background: theme.palette.common.white,
            [theme.breakpoints.down('md')]: {
              p: '10px',
              borderRadius: '10px',
            },

            '& .MuiInput-input': {
              textAlign: 'center',
              fontSize: '48px',
              lineHeight: 1.2,
              fontWeight: 600,
              color: theme.palette.primary.dark,
              [theme.breakpoints.down('md')]: {
                fontSize: '16px',
                p: 0,
              },
            },

            '& .MuiInput-input::placeholder': {
              color: theme.palette.primary.midtone,
              opacity: 1,
            },
          }}
        />
      )}
    />
  )
}

export default CustomInputControlled
