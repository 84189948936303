import { PaletteColor } from '@mui/material'

interface Props {
  paletteColor: PaletteColor
}

const BigLeftArrowIcon = ({ paletteColor }: Props) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35 4L14 25L35 46"
        stroke={paletteColor.dark}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default BigLeftArrowIcon
