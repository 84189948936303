import axios from 'axios'
import BASE_URL from '@/utils/base-url'
import getCookie from '@/utils/get-cookie'

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
})

class APIClient<T> {
  endpoint: string

  constructor(endpoint: string) {
    this.endpoint = endpoint

    const token = getCookie('_auth')
    axiosInstance.defaults.headers.Authorization = `Bearer ${token}`
  }

  // eslint-disable-next-line class-methods-use-this
  setAuthHeader = (authHeader: string) => {
    axiosInstance.defaults.headers.Authorization = authHeader
  }

  get = async () => {
    const { data } = await axiosInstance.get<T>(this.endpoint)
    return data
  }

  post = async <B>(body: B) => {
    const { data } = await axiosInstance.post<T>(this.endpoint, body)
    return data
  }
}

export default APIClient
