import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material'
import useSettings from '@/hooks/useSettings'

const AccessibilityScript = ({ isHome }: { isHome: boolean }) => {
  const theme = useTheme()
  const { i18n } = useTranslation()
  const { data: settings } = useSettings()

  const isPortal = settings?.type === 'PORTAL'
  const isKiosk = settings?.type === 'KIOSK'

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const button = document.getElementById('INDmenu-btn')

      if (button) {
        button.style.display = 'flex'
        button.style.justifyContent = 'center'
        button.style.alignItems = 'center'
        button.style.padding = '10px'
        button.style.bottom = '-29px'

        if (isPortal) {
          button.style.width = isHome ? '90px' : '65px'
          button.style.height = isHome ? '90px' : '65px'

          button.style.borderRadius = isHome ? '12px' : '20px'

          button.style.top = isHome ? '40px' : ''
          button.style.left = isHome ? '40px' : '6px'
        }

        if (isKiosk) {
          button.style.width = '81px'
          button.style.height = '81px'

          button.style.borderRadius = isHome ? '12px' : '20px'

          button.style.top = ''
          button.style.left = isHome ? '40px' : '40px'
        }
      }
    }, 600)

    return () => clearTimeout(timeoutId)
  }, [isPortal, isKiosk, isHome])

  useEffect(() => {
    if (window.interdeal && window.interdeal.SetLocale) {
      const htmlElement = document.querySelector('html')

      if (htmlElement) {
        window.interdeal.SetLocale(i18n.language.toLowerCase())
      }
    }

    if (document.querySelector('script[src*="accessibility.js"]')) return

    const scale =
      settings?.type === 'PORTAL' ? '["0.49", "0.49"]' : '["0.5", "0.5"]'

    const script = document.createElement('script')
    script.innerHTML = `
      window.interdeal = {
        "sitekey": "b6864b39a7166f0a7731078f8c0b31f5",
        "Position": "Left",
        "domains": {
          "js": "https://js.nagich.co.il/",
          "acc": "https://access.nagich.co.il/"
        },
        "Menulang": "${i18n.language.toUpperCase()}",
        "btnStyle": {
          "scale": ${scale},
          "color": {
            "main": "${theme.palette.primary.midtone}",
            "second": "${theme.palette.primary.main}"
          },
          "icon": {
            "type": 5,
            "shape": "rectangle",
            "outline": true
          }
        }
      };
      (function(doc, head, body){
        var coreCall = doc.createElement('script');
        coreCall.src = interdeal.domains.js + 'core/4.6.14/accessibility.js';
        coreCall.defer = true;
        coreCall.integrity = 'sha512-DeZBWLOEHK1CkmU3vQTNSXeENfZmhTHFmn7zzhvZCPERahpp/DTouMc1ZHOu+RAo1FTKnuKEf1NQyB76z8mU1Q==';
        coreCall.crossOrigin = 'anonymous';
        coreCall.setAttribute('data-cfasync', true);
        body ? body.appendChild(coreCall) : head.appendChild(coreCall);
      })(document, document.head, document.body);
    `

    document.head.appendChild(script)
  }, [i18n.language])

  return null
}

export default AccessibilityScript
