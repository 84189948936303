import { Box, Stack, Typography, useTheme } from '@mui/material'
import { useProcessTranslationObject } from '@/services/translation'
import useProductStore from '../../store/useProductStore'
import GlutenFreeIcon from '../icons/product/labels/GlutenFreeIcon'
import OrganicIcon from '../icons/product/labels/OrganicIcon'
import SpicyIcon from '../icons/product/labels/SpicyIcon'
import VeganIcon from '../icons/product/labels/VeganIcon'
import VegetarianIcon from '../icons/product/labels/VegetarianIcon'

const ProductInfo = () => {
  const theme = useTheme()
  const product = useProductStore((s) => s.product)
  const { processTranslationObject } = useProcessTranslationObject()

  const labelIconMap = {
    GLUTEN_FREE: <GlutenFreeIcon size={40} />,
    VEGAN: <VeganIcon size={40} />,
    ORGANIC: <OrganicIcon size={40} />,
    SPICY: <SpicyIcon size={40} />,
    VEGETARIAN: <VegetarianIcon size={40} />,
  }

  return (
    <>
      {product?.options.length === 0 && (
        <Box
          width="100%"
          height="390px"
          borderRadius="0px 0px 100px 100px"
          sx={{
            backgroundImage: `url(${product.imageUrl})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            filter: 'blur(25px)',
            [theme.breakpoints.down('md')]: {
              height: 0,
            },
          }}
        />
      )}

      <Box
        mb={2.5}
        mt={15}
        textAlign="center"
        px={5}
        sx={{
          [theme.breakpoints.down('md')]: {
            mt: 1,
          },
        }}
      >
        <Typography
          variant="h2"
          marginBottom={1.25}
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '18px',
            },
          }}
        >
          {processTranslationObject(product?.name)}
        </Typography>

        <Typography
          variant="body"
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '14px',
            },
          }}
        >
          {processTranslationObject(product?.description)}
        </Typography>
      </Box>

      {/* <Stack direction="row" justifyContent="center" alignItems="end">
        <CaloriesIcon color={theme.palette.primary.main} />

        <Typography variant="body" fontWeight="600" paddingLeft={1.25}>
          {product.measurement} {product.measurementType.toLowerCase()} /{' '}
          {product.calories} cal
        </Typography>
      </Stack> */}

      {product && product.labels.length > 0 && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2.5}
          mt={3.5}
          sx={{
            [theme.breakpoints.down('md')]: {
              mt: 0,
              mb: 1,
            },
          }}
        >
          {product.labels.map((label) => (
            <Box key={label} component="span">
              {labelIconMap[label as keyof typeof labelIconMap]}
            </Box>
          ))}
        </Stack>
      )}
    </>
  )
}

export default ProductInfo
