import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'

interface IUpsellStore {
  entranceUpsellVisibility: boolean
  checkoutUpsellVisibility: boolean
  entranceUpsellShown: boolean
  checkoutUpsellShown: boolean
  showEntranceUpsell: () => void
  showCheckoutUpsell: () => void
  hideEntranceUpsell: () => void
  hideCheckoutUpsell: () => void
  clear: () => void
}

const useUpsellStore = create<IUpsellStore>((set) => ({
  entranceUpsellVisibility: false,
  checkoutUpsellVisibility: false,
  entranceUpsellShown: false,
  checkoutUpsellShown: false,
  showEntranceUpsell: () => {
    if (!useUpsellStore.getState().entranceUpsellShown) {
      set({ entranceUpsellVisibility: true, entranceUpsellShown: true })
    }
  },
  showCheckoutUpsell: () => {
    if (!useUpsellStore.getState().checkoutUpsellShown) {
      set({ checkoutUpsellVisibility: true, checkoutUpsellShown: true })
    }
  },
  hideEntranceUpsell: () => set({ entranceUpsellVisibility: false }),
  hideCheckoutUpsell: () => set({ checkoutUpsellVisibility: false }),
  clear: () =>
    set({ entranceUpsellVisibility: false, entranceUpsellShown: false }),
}))

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('UpsellStore', useUpsellStore)
}

export default useUpsellStore
