import { Stack, useTheme } from '@mui/material'
import { APIProvider } from '@vis.gl/react-google-maps'
import AddressWizard from '@/components/delivery/AddressWizard'
import MainLayout from '@/layouts/MainLayout'

const Address = () => {
  const theme = useTheme()

  return (
    <MainLayout>
      <Stack
        direction="column"
        height="100%"
        px="78px"
        py={10}
        sx={{
          [theme.breakpoints.down('md')]: {
            px: 2,
            py: 3,
          },
        }}
      >
        <APIProvider apiKey="AIzaSyApxk5ddNc67g6YhqzKtTqdvI20NOWbvZg">
          <AddressWizard />
        </APIProvider>
      </Stack>
    </MainLayout>
  )
}

export default Address
