import { useCallback } from 'react'
import { initReactI18next, useTranslation } from 'react-i18next'
import i18n from 'i18next'
import useSettings from '@/hooks/useSettings'
import { resources } from '@/translations'

export const initializateTranslation = (language: string) => {
  i18n.use(initReactI18next).init({
    resources,
    lng: language.toLowerCase(), // default language,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
}

export const useProcessTranslationObject = () => {
  const { i18n } = useTranslation()
  const { data: settings } = useSettings()

  const processTranslationObject = useCallback(
    (obj: any) => {
      if (!obj) return ''

      const currentLanguage = i18n.language.toUpperCase()
      const fallbackLanguage = settings?.defaultLanguage || 'EN'

      return obj[currentLanguage] || obj[fallbackLanguage]
    },
    [settings, i18n],
  )

  return { processTranslationObject }
}
