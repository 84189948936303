import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CanceledError } from 'axios'
import { payWithCreditCard } from '@/services/payment'
import useCartStore from '../../store/useCartStore'

interface Props {
  onSuccess: (
    transactionId: string,
    amount: number,
    acquirer: string,
    issuer: string,
    card: string,
    requestId: string,
  ) => void
  onError: (error: string) => void
}

const PayWithCreditCard = ({ onSuccess, onError }: Props) => {
  const { t } = useTranslation('payment')

  const getCartTotalPrice = useCartStore((s) => s.getCartTotalPrice)
  const price = getCartTotalPrice()

  useEffect(() => {
    payWithCreditCard(price * 100)
      .then(
        ({
          status,
          transactionId,
          message,
          acquirer,
          issuer,
          card,
          requestId,
        }) => {
          if (status) {
            onSuccess(transactionId, price, acquirer, issuer, card, requestId)
          } else {
            onError(message)
          }
        },
      )
      .catch((err) => {
        if (err instanceof CanceledError) return

        console.log(err)
        onError(t('generalError'))
      })
  }, [])

  return null
}

export default PayWithCreditCard
