import { useTranslation } from 'react-i18next'
import { Stack, Typography, useTheme } from '@mui/material'
import { usePaymentsList } from '@/utils/consts'
import useSettings from '../../hooks/useSettings'

const PaymentMethods = () => {
  const { t } = useTranslation('home')
  const theme = useTheme()

  const { data: settings } = useSettings()

  const PAYMENTS_LIST = usePaymentsList()

  return (
    <>
      <Typography
        variant="h6"
        color="primary.dark"
        sx={{
          mb: 2.5,
          textTransform: 'uppercase',
          textAlign: 'center',
          fontWeight: 600,
          [theme.breakpoints.down('md')]: {
            fontSize: 16,
          },
        }}
      >
        {t('paymentMethodsTitle')}
      </Typography>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        gap="0 30px"
        sx={{
          background: theme.palette.primary.light,
          borderRadius: '16px',
          px: 7.5,
          py: 1,
          [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
            spacing: 0,
            flexWrap: 'wrap',
            pl: 2,
            pr: 2,
            gap: '0 25px',
          },
        }}
      >
        {settings?.paymentMethods.map((paymentMethod, index) => {
          const paymentMethodInfo = PAYMENTS_LIST[paymentMethod]

          if (paymentMethodInfo) {
            const PaymentMethodIcon = paymentMethodInfo.icon

            const getWidthForIcon = () => {
              if (paymentMethodInfo.name && paymentMethod === 'CIBUS') {
                return '135px'
              }
              if (paymentMethodInfo.name && paymentMethod === 'GOODI') {
                return '85px'
              }
              if (paymentMethodInfo.name && paymentMethod === 'TEN_BIS') {
                return '35px'
              }

              return 'auto'
            }

            return (
              <Stack
                key={paymentMethodInfo.name + index}
                direction="row"
                alignItems="center"
                sx={{
                  [theme.breakpoints.down('md')]: {
                    height: 50,
                    width: getWidthForIcon(),
                  },
                  [theme.breakpoints.down('sm')]: {
                    height: 40,
                  },
                }}
              >
                <PaymentMethodIcon color={theme.palette.primary.main} />
              </Stack>
            )
          }

          return null
        })}
      </Stack>
    </>
  )
}

export default PaymentMethods
