import { AxiosRequestConfig } from 'axios'
import { axiosInstance } from '@/services/api-client'

export const refreshToken = async (
  token?: string | null,
): Promise<{ accessToken: string; refreshToken: string }> => {
  const response = await axiosInstance.get(`auth/refresh?token=${token}`, {
    withoutToken: true,
  } as AxiosRequestConfig)

  return response.data
}
