import { Link as RouterLink, useNavigate } from 'react-router-dom'
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import GlutenFreeIcon from '@/components/icons/product/labels/GlutenFreeIcon'
import OrganicIcon from '@/components/icons/product/labels/OrganicIcon'
import SpicyIcon from '@/components/icons/product/labels/SpicyIcon'
import VeganIcon from '@/components/icons/product/labels/VeganIcon'
import VegetarianIcon from '@/components/icons/product/labels/VegetarianIcon'
import PrimaryButton from '@/components/shared/buttons/PrimaryButton'
import { useProcessTranslationObject } from '@/services/translation'
import useUpsellStore from '@/store/useUpsellStore'
import type Product from '@/types/Product'

interface Props {
  product: Product
  upsell?: boolean
  addToCart?: () => void
  withoutBtn?: boolean
}

const ProductCard = ({
  product,
  upsell = false,
  addToCart,
  withoutBtn,
}: Props) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const hideCheckoutUpsell = useUpsellStore((s) => s.hideCheckoutUpsell)
  const { processTranslationObject } = useProcessTranslationObject()

  const labelIconMap = {
    GLUTEN_FREE: <GlutenFreeIcon />,
    VEGAN: <VeganIcon />,
    ORGANIC: <OrganicIcon />,
    SPICY: <SpicyIcon />,
    VEGETARIAN: <VegetarianIcon />,
  }

  const handleClick = (event: React.MouseEvent) => {
    if (!upsell) return

    event.preventDefault()

    if (product.options.length > 0) {
      navigate(`/product/${product.id}`)
      return
    }

    addToCart?.()
  }

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.primary.light,
        borderRadius: '50px',
        boxShadow: 'none',
        textAlign: 'center',
        minWidth: '300px',
        width: '300px',
        border: `4px solid ${theme.palette.primary.light}`,
        borderBottom: 0,
        [theme.breakpoints.down('md')]: {
          minWidth: '115px',
          width: '146px',
          borderRadius: '20px',
          border: `2px solid ${theme.palette.primary.light}`,
        },
        [theme.breakpoints.between(430, 600)]: {
          minWidth: 115,
          width: 146,
        },
        [theme.breakpoints.between(359, 429)]: {
          minWidth: 111,
          width: 111,
        },
      }}
      onClick={handleClick}
    >
      <CardActionArea
        component={upsell ? 'div' : RouterLink}
        to={`/product/${product.id}`}
        onClick={() => hideCheckoutUpsell()}
      >
        <CardContent sx={{ p: 0 }}>
          <Box position="relative">
            <Box
              component="img"
              src={product.imageUrl}
              width="100%"
              height="300px"
              sx={{
                objectFit: 'cover',
                [theme.breakpoints.down('md')]: {
                  height: '146px',
                },
                [theme.breakpoints.down(430)]: {
                  height: '119px',
                },
              }}
            />

            {product.labels.length > 0 && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1.25}
                borderRadius="10px 10px 0px 0px"
                position="absolute"
                bottom="0"
                left="50%"
                px={2.5}
                py={1.25}
                sx={{
                  background: theme.palette.primary.light,
                  transform: 'translateX(-50%)',
                  [theme.breakpoints.down('md')]: {
                    p: '5px',
                    svg: {
                      width: '16px',
                      maxWidth: 'max-content',
                    },
                  },
                }}
              >
                {product.labels.map((label) => (
                  <Box key={label} component="span">
                    {labelIconMap[label as keyof typeof labelIconMap]}
                  </Box>
                ))}
              </Stack>
            )}
          </Box>

          <Box
            p={3}
            sx={{
              [theme.breakpoints.down('md')]: {
                p: 1,
              },
            }}
          >
            <Typography
              variant="body"
              fontSize="20px"
              fontWeight="600"
              mb={0.75}
              sx={{
                [theme.breakpoints.down('md')]: {
                  fontSize: '12px',
                  height: '27px',
                },
              }}
            >
              {processTranslationObject(product.name)}
            </Typography>

            <Typography
              variant="body"
              fontSize="20px"
              fontWeight="600"
              color="primary.main"
              sx={{
                [theme.breakpoints.down('md')]: {
                  fontSize: '12px',
                },
              }}
            >
              {product.priceRange} ₪
            </Typography>
          </Box>
        </CardContent>

        {upsell && withoutBtn && (
          <CardActions sx={{ justifyContent: 'center' }}>
            <PrimaryButton>Add to order</PrimaryButton>
          </CardActions>
        )}
      </CardActionArea>
    </Card>
  )
}

export default ProductCard
