import { useTranslation } from 'react-i18next'
import { Button, Stack, useTheme } from '@mui/material'
import useSettingsStore from '@/store/useSettinsStore'
import useSettings from '../../hooks/useSettings'

type LanguagesList = {
  [key: string]: string
}

const LANGUAGES: LanguagesList = {
  EN: 'English',
  RU: 'Русский',
  AR: 'عربي',
  HE: 'עִברִית',
} as const

const LanguageSelector = () => {
  const { i18n } = useTranslation()
  const theme = useTheme()

  const { data: settings } = useSettings()
  const setCurrentLanguage = useSettingsStore((s) => s.setCurrentLanguage)

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language)
    setCurrentLanguage(language)
    // document.dir = i18n.dir()
  }

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap={2.5}
      sx={{
        [theme.breakpoints.down('sm')]: {
          gap: 1,
        },
      }}
    >
      {settings?.languages.map((langCode) => (
        <Button
          key={langCode}
          variant="contained"
          disableElevation
          sx={{
            fontSize: '24px',
            lineHeight: 1.2,
            color:
              i18n.language === langCode.toLowerCase()
                ? theme.palette.common.white
                : theme.palette.primary.dark,
            background:
              i18n.language === langCode.toLowerCase()
                ? theme.palette.primary.main
                : theme.palette.common.white,
            borderRadius: '12px',
            px: 3.75,
            py: 1,
            ':hover': {
              bgcolor:
                i18n.language === langCode.toLowerCase()
                  ? theme.palette.primary.main
                  : theme.palette.common.white,
            },
            [theme.breakpoints.down('sm')]: {
              fontSize: '14px',
            },
          }}
          onClick={() => changeLanguage(langCode.toLowerCase())}
        >
          {LANGUAGES[langCode]}
        </Button>
      ))}
    </Stack>
  )
}

export default LanguageSelector
