import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import useCartStore from '../../store/useCartStore'

const CartHeader = () => {
  const theme = useTheme()
  const { t } = useTranslation('cart')
  const cart = useCartStore((s) => s.cart)
  const totalItems = cart.reduce((acc, item) => acc + item.quantity, 0)

  return (
    <Box
      p={2.5}
      borderBottom={`1px solid ${theme.palette.primary.dark}`}
      sx={{
        background: theme.palette.primary.midtone,
        p: '17px 0',
        [theme.breakpoints.down('md')]: {
          p: '10px 0',
          border: 0,
        },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={1.5}
      >
        <Typography
          variant="body"
          textAlign="center"
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '16px',
            },
          }}
        >
          {t('totalItems')}
        </Typography>

        <Typography
          variant="body"
          textAlign="center"
          fontWeight="600"
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '16px',
            },
          }}
        >
          {totalItems}
        </Typography>
      </Stack>
    </Box>
  )
}

export default CartHeader
