export const LANGUAGES: Record<string, string[][]> = {
  he: [
    ['ק ר א ט ו ן ם פ'],
    ['ש ד ג כ ע י ח ל ך ף'],
    ['ז ס ב ה נ מ צ ת ץ . ⌫'],
    ['123 language @  ⇩'],
  ],
  en: [
    ['q w e r t y u i o p'],
    ['a s d f g h j k l'],
    ['⇪ z x c v b n m . ⌫'],
    ['123 language @  ⇩'],
  ],
  ar: [
    ['ض ص ث ق ف غ ع ه خ ح ج د'],
    ['ش س ي ب ل ا ت ن م ك ط'],
    ['ئ ء ؤ ر ل ا ى ة و ز ظ . ⌫'],
    ['123 language @  ⇩'],
  ],
  ru: [
    ['й ц у к е н г ш щ з х ъ'],
    ['ф ы в а п р о л д ж э'],
    ['⇪ я ч с м и т ь б ю . ⌫'],
    ['123 language @  ⇩'],
  ],
}

export const NUMBERS_SYMBOLS: { elements: string[][] } = {
  elements: [['1 2 3'], ['4 5 6'], ['7 8 9'], ['⌫ abc 0 ⇩']],
}

export const ONLY_NUMBER: string[][] = [
  ['1 2 3'],
  ['4 5 6'],
  ['7 8 9'],
  ['⌫ 0 .'],
]
