import { useTranslation } from 'react-i18next'
import { Box, Typography, useTheme } from '@mui/material'

interface Props {
  price: number
}

const TotalPriceBox = ({ price }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation('payment')

  return (
    <Box
      p={2.25}
      borderRadius="25px"
      border={`2px solid ${theme.palette.primary.dark}`}
      sx={{
        [theme.breakpoints.down('md')]: {
          p: 1,
          border: 1,
          borderRadius: '15px',
        },
      }}
    >
      <Typography
        variant="body"
        fontWeight="600"
        textAlign="center"
        mb={2.5}
        sx={{
          [theme.breakpoints.down('md')]: {
            mb: 1,
            fontSize: '16px',
          },
        }}
      >
        {t('totalPrice')}
      </Typography>
      <Typography
        display="block"
        variant="summary"
        textAlign="center"
        sx={{
          [theme.breakpoints.down('md')]: {
            mb: 0,
            fontSize: '20px',
          },
        }}
      >
        {price} ₪
      </Typography>
    </Box>
  )
}

export default TotalPriceBox
