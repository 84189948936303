import arb_cart from './arb/cart.json'
import arb_delivery from './arb/delivery.json'
import arb_final_screen from './arb/final-screen.json'
import arb_global from './arb/global.json'
import arb_home from './arb/home.json'
import arb_payment from './arb/payment.json'
import arb_product from './arb/product.json'
import arb_receipt from './arb/receipt.json'
import arb_unavailable_kiosk from './arb/unavailable-kiosk.json'
import arb_upsell from './arb/upsell.json'
import arb_where_to_eat from './arb/where-to-eat.json'
import en_cart from './en/cart.json'
import en_delayed_order from './en/dalayed-order.json'
import en_delivery from './en/delivery.json'
import en_final_screen from './en/final-screen.json'
import en_global from './en/global.json'
import en_home from './en/home.json'
import en_payment from './en/payment.json'
import en_product from './en/product.json'
import en_receipt from './en/receipt.json'
import en_unavailable_kiosk from './en/unavailable-kiosk.json'
import en_upsell from './en/upsell.json'
import en_where_to_eat from './en/where-to-eat.json'
import he_cart from './he/cart.json'
import he_delivery from './he/delivery.json'
import he_final_screen from './he/final-screen.json'
import he_global from './he/global.json'
import he_home from './he/home.json'
import he_payment from './he/payment.json'
import he_product from './he/product.json'
import he_receipt from './he/receipt.json'
import he_unavailable_kiosk from './he/unavailable-kiosk.json'
import he_upsell from './he/upsell.json'
import he_where_to_eat from './he/where-to-eat.json'
import ru_cart from './ru/cart.json'
import ru_delivery from './ru/delivery.json'
import ru_final_screen from './ru/final-screen.json'
import ru_global from './ru/global.json'
import ru_home from './ru/home.json'
import ru_payment from './ru/payment.json'
import ru_product from './ru/product.json'
import ru_receipt from './ru/receipt.json'
import ru_unavailable_kiosk from './ru/unavailable-kiosk.json'
import ru_upsell from './ru/upsell.json'
import ru_where_to_eat from './ru/where-to-eat.json'

export const resources = {
  en: {
    global: en_global,
    home: en_home,
    whereToEat: en_where_to_eat,
    product: en_product,
    unavailableKiosk: en_unavailable_kiosk,
    payment: en_payment,
    cart: en_cart,
    receipt: en_receipt,
    finalScreen: en_final_screen,
    delivery: en_delivery,
    upsell: en_upsell,
    delayedOrder: en_delayed_order,
  },
  ru: {
    global: ru_global,
    home: ru_home,
    whereToEat: ru_where_to_eat,
    product: ru_product,
    unavailableKiosk: ru_unavailable_kiosk,
    payment: ru_payment,
    cart: ru_cart,
    receipt: ru_receipt,
    finalScreen: ru_final_screen,
    delivery: ru_delivery,
    upsell: ru_upsell,
  },
  he: {
    global: he_global,
    home: he_home,
    whereToEat: he_where_to_eat,
    product: he_product,
    unavailableKiosk: he_unavailable_kiosk,
    payment: he_payment,
    cart: he_cart,
    receipt: he_receipt,
    finalScreen: he_final_screen,
    delivery: he_delivery,
    upsell: he_upsell,
  },
  ar: {
    global: arb_global,
    home: arb_home,
    whereToEat: arb_where_to_eat,
    product: arb_product,
    unavailableKiosk: arb_unavailable_kiosk,
    payment: arb_payment,
    cart: arb_cart,
    receipt: arb_receipt,
    finalScreen: arb_final_screen,
    delivery: arb_delivery,
    upsell: arb_upsell,
  },
}
