import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { payWithCibus } from '@/services/payment'
import { usePaymentsList } from '@/utils/consts'
import CardForm from '../../../components/payment/CardForm'
import useSettings from '../../../hooks/useSettings'
import PaymentLayout from '../../../layouts/PaymentLayout'
import useCartStore from '../../../store/useCartStore'
import usePaymentErrorStore from '../../../store/usePaymentErrorStore'

const ManualInput = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('payment')

  const { data: settings } = useSettings()

  const throwError = usePaymentErrorStore((s) => s.throwError)
  const setPaymentResult = useCartStore((s) => s.setPaymentResult)
  const getCartTotalPrice = useCartStore((s) => s.getCartTotalPrice)
  const price = getCartTotalPrice()

  const PAYMENTS_LIST = usePaymentsList()

  const makePayment = (cardNumber: string) => {
    const companyCode = 0

    payWithCibus(price, cardNumber, companyCode)
      .then((data) => {
        if (data.status) {
          setPaymentResult({
            id: PAYMENTS_LIST.CIBUS.id,
            amount: price,
            transactionId: data.transactionId,
            card: cardNumber,
          })

          navigate('/payment/success')
        } else {
          throwError('cibus', data.errorDescription)
        }
      })
      .catch((err) => {
        console.log(err)
        throwError('cibus', t('generalError'))
      })
  }

  return (
    <PaymentLayout
      title={t('cibusTitle')}
      subTitle={t('typeCibusCard')}
      backTo={settings?.type === 'PORTAL' ? '/payment' : '/payment/cibus'}
    >
      <CardForm onSubmit={makePayment} />
    </PaymentLayout>
  )
}

export default ManualInput
