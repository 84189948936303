import { useProcessTranslationObject } from '@/services/translation'
import useProductStore from '@/store/useProductStore'
import type IOptionType from '@/types/Option'
import Choice from '../choice'
import ChoicesListWrapper from '../choice/ChoicesListWrapper'
import OptionTitle from './OptionTitle'
import OptionWrapper from './OptionWrapper'

interface Props {
  option: IOptionType
}

const Option = ({ option }: Props) => {
  const selectSingleChoice = useProductStore((s) => s.selectSingleChoice)
  const { processTranslationObject } = useProcessTranslationObject()

  return (
    <OptionWrapper>
      <OptionTitle title={processTranslationObject(option.name)} />

      <ChoicesListWrapper>
        {option.choices
          .filter((choice) => choice.visible)
          .map((choice) => (
            <Choice
              key={choice.id}
              choice={choice}
              onSelect={() => selectSingleChoice(option.id, choice.id)}
            />
          ))}
      </ChoicesListWrapper>
    </OptionWrapper>
  )
}

export default Option
