import { Typography, useTheme } from '@mui/material'

interface Props {
  title: string
}

const OptionTitle = ({ title }: Props) => {
  const theme = useTheme()

  return (
    <Typography
      variant="body"
      fontWeight="600"
      textAlign="center"
      mb={2.5}
      sx={{
        [theme.breakpoints.down('md')]: {
          fontSize: '18px',
        },
      }}
    >
      {title}
    </Typography>
  )
}

export default OptionTitle
