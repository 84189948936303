import { useQuery } from '@tanstack/react-query'
import { getChanelId } from '@/services/auth'
import { getUpsells } from '@/services/upsells'

const useUpsells = () => {
  const channelId = getChanelId()

  return useQuery({
    queryKey: ['upsells', channelId],
    queryFn: getUpsells,
    staleTime: 60 * 60 * 1000,
  })
}

export default useUpsells
