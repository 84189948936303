import { PaletteColor } from '@mui/material'

interface Props {
  paletteColor: PaletteColor
}

const CrossIcon = ({ paletteColor }: Props) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.1001 18.4L10.5001 10M10.5001 10L18.9001 1.59998M10.5001 10L2.1001 1.59998M10.5001 10L18.9001 18.4"
        stroke={paletteColor.dark}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CrossIcon
