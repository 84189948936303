import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'

interface IPaymentErrorStore {
  modalOpen: boolean
  method: string | null
  errorMessage: string | null
  throwError: (method: string, errorMessage: string) => void
  clearError: () => void
}

const usePaymentErrorStore = create<IPaymentErrorStore>((set) => ({
  modalOpen: false,
  method: null,
  errorMessage: null,
  throwError: (method: string, errorMessage: string) =>
    set({ modalOpen: true, method, errorMessage }),
  clearError: () => set({ modalOpen: false, method: null, errorMessage: null }),
}))

if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('PaymentErrorStore', usePaymentErrorStore)
}

export default usePaymentErrorStore
