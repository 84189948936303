import { Button, Stack, Typography, useTheme } from '@mui/material'
import MinusIcon from '@/components/icons/MinusIcon'
import PlusIcon from '@/components/icons/PlusIcon'

interface Props {
  quantity: number
  isDisabled: boolean
  onModify: (quantity: number) => void
}

const ChoiceCounter = ({ quantity = 0, isDisabled, onModify }: Props) => {
  const theme = useTheme()

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2.5}
      sx={{
        [theme.breakpoints.down('md')]: {
          ml: '0 !important',
        },
      }}
    >
      <Button
        variant="outlined"
        sx={{
          borderColor: theme.palette.primary.dark,
          borderRadius: '12px',
          padding: '9px',

          ':hover': {
            borderColor: theme.palette.primary.dark,
          },

          [theme.breakpoints.down('md')]: {
            padding: '5px',
            borderRadius: '6px',
            svg: {
              width: 18,
            },
          },
        }}
        onClick={() => {
          if (quantity && quantity > 0) {
            onModify(quantity - 1)
          }
        }}
      >
        <MinusIcon />
      </Button>

      <Typography
        variant="body"
        fontWeight="600"
        width={40}
        textAlign="center"
        p={1.25}
        sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: '16px',
            ml: '0px !important',
          },
        }}
      >
        {quantity}
      </Typography>

      <Button
        variant="contained"
        disableElevation
        sx={{
          borderRadius: '12px',
          padding: '10px',
          opacity: isDisabled && quantity > 0 ? 0.5 : 1,
          pointerEvents: isDisabled && quantity > 0 ? 'none' : 'unset',

          [theme.breakpoints.down('md')]: {
            padding: '5px',
            borderRadius: '6px',
            ml: '0px !important',
            svg: {
              width: 20,
            },
          },
        }}
        onClick={() => onModify(quantity + 1)}
      >
        <PlusIcon />
      </Button>
    </Stack>
  )
}

export default ChoiceCounter
