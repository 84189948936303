import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Box, Button, Stack, useTheme } from '@mui/material'
import InfoModal from '@/components/home/InfoModal'
import LanguageSelector from '@/components/home/LanguageSelector'
import ModalService from '@/components/home/ModalService'
import PaymentMethods from '@/components/home/PaymentMethods'
import BurgerIcon from '@/components/icons/home/BurgerIcon'
import AccessibilityScript from '@/components/shared/AccessibilityScript'
import useSettings from '@/hooks/useSettings'
import { createOrder, IOrder } from '@/services/order'
import { isKioskWorkingHours } from '@/services/working-hours'
import useCartStore from '@/store/useCartStore'

const Home = () => {
  const { t } = useTranslation('home')
  const theme = useTheme()
  const navigate = useNavigate()

  const clearScheduleStore = useCartStore((s) => s.clearSchedule)
  const { data: settings, dataUpdatedAt } = useSettings({
    refetchInterval: 60 * 1000,
  }) // 1 minute

  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    clearScheduleStore()
    if (settings && !settings.active) {
      navigate('/unavailable-kiosk?reason=inactive')
    }

    if (settings && !isKioskWorkingHours(settings.schedule)) {
      navigate('/unavailable-kiosk?reason=not-working-hours')
    }

    const orders: IOrder[] = JSON.parse(localStorage.getItem('orders') || '[]')

    if (window.navigator.onLine && orders.length > 0) {
      orders.forEach((order) => {
        createOrder(order)
      })

      localStorage.setItem('orders', '[]')
    }
  }, [dataUpdatedAt])

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        height: '100%',
        background: `url(${settings?.backgroundImageUrl}) center / cover no-repeat`,
      }}
    >
      <Stack
        width="100%"
        direction="column"
        justifyContent="flex-end"
        alignItems="stretch"
        spacing={5}
        sx={{ flex: 1 }}
      >
        {settings?.type === 'PORTAL' ? (
          <Button
            variant="contained"
            sx={{
              position: 'absolute',
              top: '40px',
              right: '40px',
              borderRadius: '8px',
              padding: '10px',
              backgroundColor: theme.palette.primary.main,
            }}
            onClick={() => setOpen(true)}
          >
            <BurgerIcon />
          </Button>
        ) : (
          <ModalService />
        )}
        <Box sx={{ px: 5 }}>
          <Button
            component={RouterLink}
            to="/where-to-eat"
            variant="contained"
            disableElevation
            fullWidth
            sx={{
              fontSize: '64px',
              lineHeight: 1.2,
              fontWeight: 600,
              color: theme.palette.primary.dark,
              textTransform: 'uppercase',
              p: 3.75,
              borderRadius: '35px',
              bgcolor: theme.palette.primary.light,
              ':hover': {
                bgcolor: theme.palette.primary.light,
              },
              [theme.breakpoints.down('md')]: {
                fontSize: '28px',
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: '18px',
                borderRadius: '15px',
              },
            }}
          >
            {t('actionButton')}
          </Button>
        </Box>

        <Box sx={{ px: 5 }}>
          <LanguageSelector />
        </Box>

        <Box
          sx={{
            borderRadius: '60px 60px 0 0',
            backgroundColor: theme.palette.common.white,
            p: 5,
            [theme.breakpoints.down('sm')]: {
              borderRadius: '45px 45px 0 0',
              p: 2,
            },
          }}
        >
          <PaymentMethods />
        </Box>
      </Stack>

      <InfoModal open={open} setOpen={setOpen} />

      <AccessibilityScript isHome />
    </Box>
  )
}

export default Home
