import { useCallback, useEffect, useMemo } from 'react'
import { SelectChangeEvent } from '@mui/material'
import { DateTime } from 'luxon'
import useSettings from '@/hooks/useSettings'
import useCartStore from '@/store/useCartStore'

const useDelayedOrder = () => {
  const { data: settings } = useSettings()
  const { selectedDate, setSelectedDate } = useCartStore()

  const { openTime, closeTime } = useMemo(() => {
    const dayOfWeek =
      selectedDate &&
      DateTime.fromISO(selectedDate).toFormat('cccc').toUpperCase()

    const hours = dayOfWeek && settings?.schedule?.[dayOfWeek]

    if (hours && hours.open === '00:00' && hours.close === '00:00') {
      return {
        openTime: DateTime.now().startOf('day'),
        closeTime: DateTime.now().endOf('day'),
      }
    }
    return {
      openTime: hours ? DateTime.fromFormat(hours.open, 'HH:mm') : undefined,
      closeTime: hours ? DateTime.fromFormat(hours.close, 'HH:mm') : undefined,
    }
  }, [selectedDate, settings?.schedule])

  const availableDates = useMemo(() => {
    const today = DateTime.now()
    const dayOfWeek = today.toFormat('cccc').toUpperCase()
    const todaySchedule = settings?.schedule?.[dayOfWeek]

    const isNotOpenToday =
      todaySchedule &&
      todaySchedule.open !== '00:00' &&
      today < DateTime.fromFormat(todaySchedule.open, 'HH:mm')

    return [today.plus({ days: isNotOpenToday ? 0 : 1 }).toISODate()]
  }, [settings?.schedule])

  useEffect(() => {
    if (!selectedDate && availableDates.length > 0) {
      setSelectedDate(availableDates[0])
    }
  }, [selectedDate, availableDates, setSelectedDate])

  const handleTimeChange = useCallback(
    (newTime: DateTime | null) => {
      const date = selectedDate && DateTime.fromISO(selectedDate)

      if (date && newTime) {
        const combined = DateTime.fromISO(
          `${date.toISODate()}T${newTime.toFormat('HH:mm')}`,
        ).toISO()
        setSelectedDate(combined)
      }
    },
    [selectedDate],
  )

  const handleDateChange = useCallback((event: SelectChangeEvent<string>) => {
    setSelectedDate(event.target.value as string)
  }, [])

  return {
    handleDateChange,
    handleTimeChange,
    openTime,
    closeTime,
    availableDates,
    selectedDate,
  }
}

export default useDelayedOrder
