import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Box, Stack, Typography, useTheme } from '@mui/material'

interface Props {
  deliveryStep: number
}

const DeliverySteps = ({ deliveryStep }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation('delivery')

  const STEPS = [
    {
      name: t('step1'),
      url: '/delivery/address',
    },
    {
      name: t('step2'),
      url: '/menu',
    },
    {
      name: t('step3'),
      url: '/delivery/details',
    },
    {
      name: t('step4'),
      url: '/payment',
    },
  ]

  return (
    <Stack
      direction="row"
      px="78px"
      pt={10}
      pb={5}
      gap={3}
      borderBottom={`1px solid ${theme.palette.primary.dark}`}
      sx={{
        [theme.breakpoints.down('md')]: {
          gap: 1,
          p: '22px 16px',
          textAlign: 'center',
        },
      }}
    >
      {STEPS.map((step, index) => (
        <Box
          component={Link}
          to={step.url}
          className={
            // eslint-disable-next-line no-nested-ternary
            deliveryStep === index
              ? 'active'
              : index < deliveryStep
                ? 'done'
                : ''
          }
          key={index}
          flex="1"
          border={`2px solid ${theme.palette.primary.midtone}`}
          borderRadius="20px"
          color="primary.midtone"
          px={2.5}
          py={2}
          sx={{
            pointerEvents: 'none',
            textDecoration: 'none',

            '&.active': {
              color: theme.palette.primary.dark,
              borderColor: theme.palette.primary.main,
              pointerEvents: 'auto',
            },
            '&.done': {
              color: theme.palette.primary.dark,
              borderColor: '#1E8133',
              pointerEvents: 'auto',
            },

            [theme.breakpoints.down('md')]: {
              p: 1,
            },
          }}
        >
          <Typography
            fontSize="36px"
            fontWeight="600"
            mb={1}
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: 20,
                mb: 0,
              },
            }}
          >
            {index + 1}
          </Typography>

          <Typography
            fontSize="18px"
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: 14,
              },
            }}
          >
            {step.name}
          </Typography>
        </Box>
      ))}
    </Stack>
  )
}

export default DeliverySteps
