import type ChoiceType from '@/types/Choice'
import ChoiceWithCheckbox from './ChoiceWithCheckbox'
import ChoiceWithCounter from './ChoiceWithCounter'
import ChoiceWithPizza from './ChoiceWithPizza'

interface Props {
  choice: ChoiceType
  type: 'COUNTER' | 'PIZZA' | 'CHECKBOX'
  isDisabled: boolean
  showPrice: boolean
  onModify: (quantity: number, options?: { pizzaPart?: string }) => void
}

const Choice = ({ choice, type, isDisabled, showPrice, onModify }: Props) => {
  if (type === 'COUNTER') {
    return (
      <ChoiceWithCounter
        choice={choice}
        isDisabled={isDisabled}
        showPrice={showPrice}
        onModify={(quantity) => onModify(quantity)}
      />
    )
  }

  if (type === 'PIZZA') {
    return (
      <ChoiceWithPizza
        choice={choice}
        isDisabled={isDisabled}
        showPrice={showPrice}
        onModify={(quantity, options) => onModify(quantity, options)}
      />
    )
  }

  if (type === 'CHECKBOX') {
    return (
      <ChoiceWithCheckbox
        choice={choice}
        isDisabled={isDisabled}
        showPrice={showPrice}
        onModify={(quantity) => onModify(quantity)}
      />
    )
  }

  return null
}

export default Choice
