const GoodiIcon = ({ width = '138px' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height="55px"
      viewBox="0 0 138 55"
    >
      <g>
        <path
          fill="#9ab844"
          d="M 102.5,0.5 C 105.85,0.334989 109.183,0.501655 112.5,1C 114.286,1.78451 115.786,2.95118 117,4.5C 120.66,0.632638 125.16,-0.534028 130.5,1C 135.453,5.64259 135.787,10.6426 131.5,16C 131.867,19.4069 132.033,22.9069 132,26.5C 135.91,32.1616 134.743,36.6616 128.5,40C 123.102,42.0627 117.769,41.8961 112.5,39.5C 109.882,40.3727 107.216,41.0394 104.5,41.5C 100.833,40.1667 97.1667,40.1667 93.5,41.5C 89.8568,41.232 86.6901,39.8987 84,37.5C 80.5781,38.6241 78.0781,40.7908 76.5,44C 80.0397,44.3287 81.2064,46.162 80,49.5C 67.7022,55.7902 55.2022,56.1235 42.5,50.5C 40.912,47.5316 41.7454,45.5316 45,44.5C 49.0078,46.3983 53.1745,47.2316 57.5,47C 60.1263,45.544 61.793,43.3773 62.5,40.5C 62.2972,38.7923 61.4638,37.459 60,36.5C 52.7381,42.1169 45.0714,42.7835 37,38.5C 26.717,43.969 16.5504,43.8023 6.5,38C 1.71341,29.4761 1.21341,20.6428 5,11.5C 12.7106,3.21574 22.2106,0.0490712 33.5,2C 38.7904,2.95752 41.6237,6.12419 42,11.5C 46.7477,10.3651 51.581,10.1985 56.5,11C 58.6238,12.1217 60.4571,13.6217 62,15.5C 63.693,13.3965 65.8596,11.8965 68.5,11C 72.8333,10.3333 77.1667,10.3333 81.5,11C 83.492,11.5743 84.992,12.741 86,14.5C 88.913,11.6269 92.413,10.2935 96.5,10.5C 96.9924,6.16914 98.9924,2.83581 102.5,0.5 Z"
        />
      </g>
      <g>
        <path
          fill="#fcfcf9"
          d="M 122.5,5.5 C 129.19,4.3306 130.857,6.49726 127.5,12C 125.19,12.4966 122.857,12.6633 120.5,12.5C 120.185,9.78924 120.852,7.45591 122.5,5.5 Z"
        />
      </g>
      <g>
        <path
          fill="#fcfdf9"
          d="M 104.5,5.5 C 107.641,4.90816 109.974,5.90816 111.5,8.5C 110.09,15.4471 109.09,22.4471 108.5,29.5C 109.5,29.5 110.5,29.5 111.5,29.5C 112.035,32.2385 111.035,34.0718 108.5,35C 101.629,34.8594 94.7953,34.6927 88,34.5C 83.8244,21.8564 88.3244,15.5231 101.5,15.5C 101.022,11.6625 102.022,8.32914 104.5,5.5 Z"
        />
      </g>
      <g>
        <path
          fill="#fbfcf8"
          d="M 18.5,7.5 C 23.8437,7.33398 29.1771,7.50065 34.5,8C 37.1667,10.6667 37.1667,13.3333 34.5,16C 29.962,14.887 25.6286,15.5536 21.5,18C 19.4766,21.1831 18.9766,24.6831 20,28.5C 21.3876,29.5536 22.8876,29.7203 24.5,29C 23.2309,26.8615 23.2309,24.6948 24.5,22.5C 35.2607,19.8322 38.2607,23.6655 33.5,34C 25.5311,37.4907 17.6978,37.3241 10,33.5C 4.5693,22.0462 7.40263,13.3796 18.5,7.5 Z"
        />
      </g>
      <g>
        <path
          fill="#fafbf6"
          d="M 44.5,15.5 C 49.4082,14.874 53.9082,15.874 58,18.5C 59.8984,33.9353 53.5651,38.9353 39,33.5C 35.0741,25.7757 36.9075,19.7757 44.5,15.5 Z"
        />
      </g>
      <g>
        <path
          fill="#f8faf2"
          d="M 70.5,15.5 C 84.8967,15.4537 88.23,21.6203 80.5,34C 77.5677,32.9007 74.401,32.0674 71,31.5C 68.6477,31.3049 66.6477,31.9716 65,33.5C 60.4127,25.4977 62.246,19.4977 70.5,15.5 Z"
        />
      </g>
      <g>
        <path
          fill="#f9faf3"
          d="M 117.5,15.5 C 126.863,13.1947 129.863,16.528 126.5,25.5C 127.246,27.6767 128.246,29.6767 129.5,31.5C 129.069,33.7661 127.736,35.2661 125.5,36C 122.007,37.0037 118.841,36.5037 116,34.5C 116.446,29.6512 116.279,24.8179 115.5,20C 115.519,18.167 116.185,16.667 117.5,15.5 Z"
        />
      </g>
      <g>
        <path
          fill="#9ebb4b"
          d="M 46.5,21.5 C 47.8734,21.3433 49.2068,21.51 50.5,22C 47.9557,23.0985 47.9557,24.2652 50.5,25.5C 51.086,25.2907 51.586,24.9574 52,24.5C 52.7203,26.1124 52.5536,27.6124 51.5,29C 43.6557,30.0293 41.989,27.5293 46.5,21.5 Z"
        />
      </g>
      <g>
        <path
          fill="#9cba48"
          d="M 71.5,21.5 C 72.8734,21.3433 74.2068,21.51 75.5,22C 74.269,23.1827 74.269,24.3493 75.5,25.5C 76.9038,24.0011 77.5704,24.3344 77.5,26.5C 77.3918,27.4419 77.0585,28.2753 76.5,29C 69.3844,30.151 67.7177,27.651 71.5,21.5 Z"
        />
      </g>
      <g>
        <path
          fill="#a3bf56"
          d="M 97.5,22.5 C 98.6935,22.9704 99.3601,23.9704 99.5,25.5C 99.6578,27.3186 98.9911,28.6519 97.5,29.5C 94.9617,27.2599 94.9617,24.9265 97.5,22.5 Z"
        />
      </g>
      <g>
        <path
          fill="#f6f8ee"
          d="M 67.5,34.5 C 68.8333,34.5 70.1667,34.5 71.5,34.5C 70.0497,44.3654 70.7164,44.6987 73.5,35.5C 74.8913,42.9656 71.7246,47.2989 64,48.5C 63.1667,48.3333 62.3333,48.1667 61.5,48C 65.268,44.2965 67.268,39.7965 67.5,34.5 Z"
        />
      </g>
    </svg>
  )
}

export default GoodiIcon
