import { Stack, useTheme } from '@mui/material'

interface Props {
  children: React.ReactNode
}

const ChoicesListWrapper = ({ children }: Props) => {
  const theme = useTheme()
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={5}
      sx={{
        [theme.breakpoints.down('md')]: {
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: '10px',
        },
      }}
    >
      {children}
    </Stack>
  )
}

export default ChoicesListWrapper
