import { useTranslation } from 'react-i18next'
import { Stack, Typography, useTheme } from '@mui/material'
import useProductStore from '../../store/useProductStore'

const ProductPrice = () => {
  const theme = useTheme()
  const { t } = useTranslation('product')
  const getTotalPrice = useProductStore((s) => s.getTotalPrice)
  const price = getTotalPrice()

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={5}
      paddingX={2.5}
      sx={{
        [theme.breakpoints.down('md')]: {
          justifyContent: 'space-between',
          pl: 0,
          pr: 0,
          width: '100%',
        },
      }}
    >
      <Typography
        variant="body"
        fontWeight="600"
        textTransform="uppercase"
        sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: '16px',
            width: '100%',
          },
        }}
      >
        {t('totalPrice')}
      </Typography>

      <Typography
        variant="summary"
        sx={{
          [theme.breakpoints.down('md')]: {
            textAlign: 'center',
            fontSize: '20px',
            width: '30%',
            margin: '0 !important',
          },
        }}
      >
        {price} ₪
      </Typography>
    </Stack>
  )
}

export default ProductPrice
