import { Box, Stack, useTheme } from '@mui/material'
import useSettings from '../../hooks/useSettings'

const CompanyLogo = () => {
  const theme = useTheme()
  const { data: settings } = useSettings()

  return (
    <Stack
      justifyContent="center"
      minHeight="180px"
      height="auto"
      width="180px"
      sx={{
        [theme.breakpoints.down('md')]: {
          width: '40px',
          maxHeight: '40px',
          minHeight: '40px',
        },
      }}
    >
      <Box component="img" src={settings?.companyLogoUrl} />
    </Stack>
  )
}

export default CompanyLogo
