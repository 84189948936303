import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import useCartStore from '@/store/useCartStore'
import { usePaymentsList } from '@/utils/consts'

interface Props {
  total: number
  paymentId: number
  cardNumber?: string
}

const Footer = ({ total, paymentId, cardNumber }: Props) => {
  const { t } = useTranslation('receipt')
  const date = DateTime.now().toFormat('dd.LL.yy HH:mm:ss')
  const selectedDate = useCartStore((s) => s.selectedDate)
  const orderDate = DateTime.fromISO(selectedDate as string).toFormat(
    'dd.LL.yy HH:mm',
  )

  const PAYMENTS_LIST = usePaymentsList()
  const paymentMethod = Object.values(PAYMENTS_LIST).find(
    (p) => p.id === paymentId,
  )

  return (
    <Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pb={1}
        mb={1}
        borderBottom="1px solid #000"
      >
        <Typography
          fontSize={{ xs: '18px' }}
          color="#000"
          textTransform="uppercase"
        >
          {t('total')}
        </Typography>

        <Typography
          fontSize={{ xs: '18px' }}
          textTransform="uppercase"
          fontWeight="600"
          color="#000"
        >
          {total} ₪
        </Typography>
      </Stack>

      <Box pb={1} mb={1} borderBottom="1px solid #000">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={0.5}
        >
          <Typography
            fontSize={{ xs: '16px' }}
            color="#000"
            textTransform="uppercase"
          >
            {t('paymentMethod')}
          </Typography>

          <Typography
            fontSize={{ xs: '16px' }}
            textTransform="uppercase"
            fontWeight="600"
            color="#000"
          >
            {paymentMethod?.name}
          </Typography>
        </Stack>

        {cardNumber && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={0.5}
          >
            <Typography
              fontSize={{ xs: '16px' }}
              color="#000"
              textTransform="uppercase"
            >
              {t('cardNumber')}
            </Typography>

            <Typography
              fontSize={{ xs: '16px' }}
              textTransform="uppercase"
              fontWeight="600"
              color="#000"
            >
              **** {cardNumber?.slice(-4)}
            </Typography>
          </Stack>
        )}
      </Box>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          fontSize={{ xs: '16px' }}
          color="#000"
          textTransform="uppercase"
        >
          {t('date')}
        </Typography>

        <Typography
          fontSize={{ xs: '16px' }}
          textTransform="uppercase"
          fontWeight="600"
          color="#000"
        >
          {date}
        </Typography>
      </Stack>
      {selectedDate && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            fontSize={{ xs: '16px' }}
            color="#000"
            textTransform="uppercase"
          >
            order date
          </Typography>

          <Typography
            fontSize={{ xs: '16px' }}
            textTransform="uppercase"
            fontWeight="600"
            color="#000"
          >
            {orderDate}
          </Typography>
        </Stack>
      )}
    </Box>
  )
}

export default Footer
