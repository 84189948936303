import { axiosInstance } from '@/services/api-client'
import IMapCoordinates from '@/types/MapCoordinates'

// export const getDeliveryInfo = ({ lat, lng }: IMapCoordinates) => {
//   const apiClient = new APIClient<any>(`/delivery/info/${lat}/${lng}`)
//
//   return apiClient.get()
// }

export const getDeliveryInfo = async ({ lat, lng }: IMapCoordinates) => {
  const result = await axiosInstance.get(`/delivery/info/${lat}/${lng}`)

  return result.data
}
