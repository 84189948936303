import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, FormHelperText, Stack, Typography, useTheme } from '@mui/material'
import { AdvancedMarker, APIProvider, Map } from '@vis.gl/react-google-maps'
import PrimaryButton from '@/components/shared/buttons/PrimaryButton'
import CustomInput from '@/components/shared/form/CustomInput'
import CustomInputControlled from '@/components/shared/form/CustomInputControlled'
import MainLayout from '@/layouts/MainLayout'
import useCartStore from '@/store/useCartStore'
import IFormCustomer from '@/types/FormCustomer'

const DeliveryDetails = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation('delivery')
  const customer = useCartStore((s) => s.customer)
  const setCustomer = useCartStore((s) => s.setCustomer)

  const markerPosition = {
    lat: customer.address?.location?.lat || 0,
    lng: customer.address?.location?.lng || 0,
  }

  const [inputName, setInputName] = useState<string>('')
  const [inputEmail, setInputEmail] = useState<string>('')
  const [inputComment, setInputComment] = useState<string>('')
  const nameRef = useRef<HTMLDivElement>(null)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormCustomer>({
    defaultValues: {
      name: inputName,
      email: inputEmail,
      comment: '',
    },
  })

  useEffect(() => {
    if (errors.name) {
      nameRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [errors.name])

  const onSubmit = async (data: IFormCustomer) => {
    setCustomer({
      ...customer,
      name: data.name,
      email: data.email,
      address: {
        ...customer.address,
        comment: data.comment,
      },
    })

    navigate('/payment')
  }

  return (
    <MainLayout deliveryStep={2}>
      <Stack
        direction="column"
        height="100%"
        px="78px"
        py={10}
        gap={20}
        sx={{
          [theme.breakpoints.down('md')]: {
            px: 2,
            py: 3,
          },
        }}
      >
        <Stack
          component="form"
          flex="1"
          direction="column"
          gap={7.5}
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            [theme.breakpoints.down('md')]: {
              gap: 3,
            },
          }}
        >
          <Typography
            variant="body"
            textAlign="center"
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: 22,
              },
            }}
          >
            {t('clientInfo')}
          </Typography>

          <Box>
            <Typography
              component="label"
              variant="h3"
              textAlign="center"
              display="block"
              mb={2.5}
              sx={{
                [theme.breakpoints.down('md')]: {
                  fontSize: 20,
                  mb: 1,
                },
              }}
            >
              {t('phoneNumber')}
            </Typography>

            <CustomInput
              type="tel"
              name="phone"
              value={customer.phone}
              placeholder=""
              disabled
            />
          </Box>

          <Box ref={nameRef}>
            <Typography
              variant="h3"
              textAlign="center"
              mb={2.5}
              sx={{
                [theme.breakpoints.down('md')]: {
                  fontSize: 20,
                  mb: 1,
                },
              }}
            >
              {t('name')}
            </Typography>

            <CustomInputControlled
              type="text"
              placeholder=""
              name="name"
              value={inputName}
              control={control}
              rules={{ required: true }}
              onChange={(e) => setInputName(e.target.value)}
            />
            {errors.name?.type === 'required' && (
              <FormHelperText
                error={!!errors.name}
                sx={{
                  fontSize: '24px',
                  textAlign: 'center',
                  p: 1,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '16px',
                  },
                }}
              >
                {t('nameIsRequired')}
              </FormHelperText>
            )}
          </Box>

          <Box>
            <Typography
              variant="h3"
              textAlign="center"
              mb={2.5}
              sx={{
                [theme.breakpoints.down('md')]: {
                  fontSize: 20,
                  mb: 1,
                },
              }}
            >
              {t('email')}
            </Typography>

            <CustomInputControlled
              type="email"
              placeholder=""
              name="email"
              value={inputEmail}
              control={control}
              rules={{ required: '' }}
              onChange={(e) => setInputEmail(e.target.value)}
            />
          </Box>

          <Box height="1px" sx={{ background: '#F2EAD3' }} />

          <Typography
            variant="body"
            textAlign="center"
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: 18,
              },
            }}
          >
            {t('checkYourAddress')}
          </Typography>

          <Box>
            <Typography
              variant="h3"
              textAlign="center"
              mb={2.5}
              sx={{
                [theme.breakpoints.down('md')]: {
                  fontSize: 20,
                  mb: 1,
                },
              }}
            >
              {t('address')}
            </Typography>

            <Box>
              <CustomInput
                type="text"
                name="address"
                placeholder="Type to show options"
                disabled
                value={customer.address?.address || ''}
              />
            </Box>
          </Box>

          <Box
            width="100%"
            height="222px"
            bgcolor="#ccc"
            overflow="hidden"
            borderRadius="30px"
            border={`1px solid ${theme.palette.primary.midtone}`}
          >
            <APIProvider apiKey="AIzaSyApxk5ddNc67g6YhqzKtTqdvI20NOWbvZg">
              <Map
                mapId="bf51a910020fa25b"
                defaultZoom={14}
                defaultCenter={markerPosition}
                gestureHandling="greedy"
                disableDefaultUI
              />

              <AdvancedMarker position={markerPosition} />
            </APIProvider>
          </Box>

          <Stack direction="row" alignItems="center" gap={5}>
            <Box flex="1">
              <Typography
                variant="h3"
                textAlign="center"
                mb={2.5}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: 20,
                    mb: 1,
                  },
                }}
              >
                {t('entrance')}
              </Typography>

              <CustomInput
                type="text"
                placeholder="XX"
                name="entrance"
                disabled
                value={customer.address?.entrance || ''}
              />
            </Box>

            <Box flex="1">
              <Typography
                variant="h3"
                textAlign="center"
                mb={2.5}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: 20,
                    mb: 1,
                  },
                }}
              >
                {t('floor')}
              </Typography>

              <CustomInput
                type="text"
                placeholder="XX"
                name="floor"
                disabled
                value={customer.address?.floor || ''}
              />
            </Box>

            <Box flex="1">
              <Typography
                variant="h3"
                textAlign="center"
                mb={2.5}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: 20,
                    mb: 1,
                  },
                }}
              >
                {t('apartment')}
              </Typography>

              <CustomInput
                type="text"
                placeholder="XX"
                name="apartment"
                disabled
                value={customer.address?.apartment || ''}
              />
            </Box>
          </Stack>

          <Box height="1px" sx={{ background: '#F2EAD3' }} />

          <Typography
            variant="body"
            textAlign="center"
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: 22,
              },
            }}
          >
            {t('anyComments')}
          </Typography>

          <Box>
            <Typography
              variant="h3"
              textAlign="center"
              mb={2.5}
              sx={{
                [theme.breakpoints.down('md')]: {
                  fontSize: 20,
                },
              }}
            >
              {t('deliveryComment')}
            </Typography>

            <CustomInputControlled
              type="text"
              placeholder={t('anyDetails')}
              name="comment"
              value={inputComment}
              control={control}
              rules={{
                required: '',
              }}
              onChange={(e) => setInputComment(e.target.value)}
            />
          </Box>

          <Box
            py={15}
            sx={{
              [theme.breakpoints.down('md')]: {
                p: '0 0 24px 0',
              },
            }}
          >
            <PrimaryButton type="submit" fullWidth size="large">
              {t('goToPayment')}
            </PrimaryButton>
          </Box>
        </Stack>
      </Stack>
    </MainLayout>
  )
}

export default DeliveryDetails
