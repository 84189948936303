import { ElementType } from 'react'
import { useTranslation } from 'react-i18next'
import AccessibilityAr from '@/components/home/documents/accessibilityStatement/Accessibility-ar'
import AccessibilityEn from '@/components/home/documents/accessibilityStatement/Accessibility-en'
import AccessibilityHe from '@/components/home/documents/accessibilityStatement/Accessibility-he'
import AccessibilityRu from '@/components/home/documents/accessibilityStatement/Accessibility-ru'
import PrivacyPolicyAr from '@/components/home/documents/privacyPolicy/PrivacyPolicy-ar'
import PrivacyPolicyEn from '@/components/home/documents/privacyPolicy/PrivacyPolicy-en'
import PrivacyPolicyHe from '@/components/home/documents/privacyPolicy/PrivacyPolicy-he'
import PrivacyPolicyRu from '@/components/home/documents/privacyPolicy/PrivacyPolicy-ru'
import TermOfServiceAr from '@/components/home/documents/termOfService/TermOfService-ar'
import TermOfServiceEn from '@/components/home/documents/termOfService/TermOfService-en'
import TermOfServiceHe from '@/components/home/documents/termOfService/TermOfService-he'
import TermOfServiceRu from '@/components/home/documents/termOfService/TermOfService-ru'
import DeliveryIcon from '@/components/icons/DeliveryIcon'
import DineInIcon from '@/components/icons/DineInIcon'
import AccessibilityIcon from '@/components/icons/home/AccessibilityIcon'
import PrivacyPolicyIcon from '@/components/icons/home/PrivacyPolicyIcon'
import TermsOfServiceIcon from '@/components/icons/home/TermsOfServiceIcon'
import CashIcon from '@/components/icons/payment/CashIcon'
import CibusIcon from '@/components/icons/payment/CibusIcon'
import CreditCardIcon from '@/components/icons/payment/CreditCardIcon'
import GoodiIcon from '@/components/icons/payment/GoodiIcon'
import SplitPaymentIcon from '@/components/icons/payment/SplitPaymentIcon'
import TenBisIcon from '@/components/icons/payment/TenBisIcon'
import PickupIcon from '@/components/icons/PickupIcon'

type PaymentsList = {
  [key: string]: {
    id: number
    name: string
    icon: ElementType
    url?: string
  }
}

export const usePaymentsList = () => {
  const { t } = useTranslation('home')

  const PAYMENTS_LIST: PaymentsList = {
    SPLIT_PAYMENT: {
      id: 0,
      name: t('splitPayment'),
      icon: SplitPaymentIcon,
      url: '/',
    },
    CREDIT_CARD: {
      id: 3,
      name: t('creditCard'),
      icon: CreditCardIcon,
      url: '/payment/credit-card/terminal',
    },
    CASH: {
      id: 1,
      name: t('cash'),
      icon: CashIcon,
      url: '/payment/cash',
    },
    CIBUS: {
      id: 5,
      name: t('cibus'),
      icon: CibusIcon,
      url: '/payment/cibus',
    },
    TEN_BIS: {
      id: 7,
      name: t('tenBis'),
      icon: TenBisIcon,
      url: '/payment/tenbis',
    },
    GOODI: {
      id: 6,
      name: t('goodi'),
      icon: GoodiIcon,
      url: '/payment/goodi',
    },
    CASH_ON_CLOUD: {
      id: 1,
      name: t('cash'),
      icon: CashIcon,
      url: '/payment/cash-on-cloud',
    },
    PELE_CARD: {
      id: 3,
      name: t('peleCard'),
      icon: CreditCardIcon,
      url: '/payment/pelecard',
    },
    BALAMUT: {
      id: 1,
      name: t('cash'),
      icon: CashIcon,
      url: '/payment/balamut',
    },
  }

  return PAYMENTS_LIST
}

type OrderTypesList = {
  [key: string]: {
    id: number
    name: string
    icon: ElementType
  }
}

export const useOrderTypesList = () => {
  const { t } = useTranslation('whereToEat')

  const ORDER_TYPES_LIST: OrderTypesList = {
    DINE_IN: {
      id: 0,
      name: t('dineIn'),
      icon: DineInIcon,
    },
    PICKUP: {
      id: 1,
      name: t('pickup'),
      icon: PickupIcon,
    },
    DELIVERY: {
      id: 2,
      name: t('delivery'),
      icon: DeliveryIcon,
    },
  }

  return ORDER_TYPES_LIST
}

type DocumentsList = {
  id: number
  name: string
  icon: ElementType
  components: IComponents
}

interface IComponents {
  he: ElementType
  en: ElementType
  ru: ElementType
  ar: ElementType
}

export const useDocumentsList = () => {
  const { t } = useTranslation('home')

  const DOCUMENTS_LIST: DocumentsList[] = [
    {
      id: 1,
      name: t('accessibilityStatement'),
      icon: AccessibilityIcon,
      components: {
        he: AccessibilityHe,
        en: AccessibilityEn,
        ru: AccessibilityRu,
        ar: AccessibilityAr,
      },
    },
    {
      id: 2,
      name: t('privacyPolicy'),
      icon: PrivacyPolicyIcon,
      components: {
        he: PrivacyPolicyHe,
        en: PrivacyPolicyEn,
        ru: PrivacyPolicyRu,
        ar: PrivacyPolicyAr,
      },
    },
    {
      id: 3,
      name: t('termsOfService'),
      icon: TermsOfServiceIcon,
      components: {
        he: TermOfServiceHe,
        en: TermOfServiceEn,
        ru: TermOfServiceRu,
        ar: TermOfServiceAr,
      },
    },
  ]

  return DOCUMENTS_LIST
}
