import React from 'react'
import { Box, Stack } from '@mui/material'
import DeliverySteps from '@/components/delivery/DeliverySteps'
import AccessibilityScript from '@/components/shared/AccessibilityScript'
import InactivityDetector from '@/components/shared/InactivityDetector'
import ResetAppBanner from '@/components/shared/ResetAppBanner'
import useCartStore from '@/store/useCartStore'

interface Props {
  children: React.ReactNode
  showDeliverySteps?: boolean
  deliveryStep?: number
}

const MainLayout = ({
  children,
  showDeliverySteps = true,
  deliveryStep = 0,
}: Props) => {
  const orderType = useCartStore((s) => s.orderType)
  const isDelivery = orderType === 'DELIVERY'

  return (
    <Stack direction="column" height="100vh">
      <Stack direction="column" flex="1" overflow="auto">
        {isDelivery && showDeliverySteps && (
          <DeliverySteps deliveryStep={deliveryStep} />
        )}

        <Box flex="1" overflow="auto" position="relative">
          {children}
        </Box>
      </Stack>

      <ResetAppBanner />

      <AccessibilityScript isHome={false} />

      {!isDelivery && <InactivityDetector />}
    </Stack>
  )
}

export default MainLayout
