import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography, useTheme } from '@mui/material'
import useCartStore from '@/store/useCartStore'
import PrimaryButton from '../shared/buttons/PrimaryButton'
import CustomInput from '../shared/form/CustomInput'

const RESEND_TIME = 30

export interface ICodePhone {
  code: string
}

interface Props {
  onSubmit: (data: ICodePhone) => void
  onResend: () => void
}

const PhoneConfirmationForm = ({ onSubmit, onResend }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation('delivery')
  const customer = useCartStore((s) => s.customer)
  const [inputCode, setInputCode] = useState<string>('')
  const [timer, setTimer] = useState<number>(RESEND_TIME)

  const tick = () => {
    setTimer((prev) => {
      if (prev === 1) {
        return 0
      }

      return prev - 1
    })
  }

  const setCode = (code: string) => {
    setInputCode(code)

    if (code.length === 4) {
      onSubmit({ code })
    }
  }

  const resendCode = () => {
    onResend()
    setTimer(RESEND_TIME)
  }

  useEffect(() => {
    const id = setTimeout(tick, 1000)

    return () => {
      clearTimeout(id)
    }
  })

  return (
    <Box>
      <Typography
        variant="body"
        textAlign="center"
        mb={7.5}
        sx={{
          [theme.breakpoints.down('md')]: {
            fontSize: '16px',
            mb: 3,
          },
        }}
      >
        {t('deliveryText')}
      </Typography>

      <Box
        mb={7.5}
        sx={{
          [theme.breakpoints.down('md')]: {
            mb: 2,
          },
        }}
      >
        <Typography
          component="label"
          variant="h3"
          textAlign="center"
          display="block"
          mb={2.5}
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '20px',
              mb: 1,
            },
          }}
        >
          {t('phoneNumber')}
        </Typography>

        <CustomInput
          type="tel"
          name={t('phoneNumber')}
          value={customer.phone}
          placeholder=""
          disabled
        />
      </Box>

      <Box
        mb={7.5}
        sx={{
          [theme.breakpoints.down('md')]: {
            mb: 2,
          },
        }}
      >
        <Typography
          component="label"
          variant="h3"
          textAlign="center"
          display="block"
          mb={2.5}
          sx={{
            [theme.breakpoints.down('md')]: {
              fontSize: '20px',
              mb: 1,
            },
          }}
        >
          {t('codeSMS')}
        </Typography>

        <CustomInput
          type="tel"
          name="code"
          value={inputCode}
          placeholder="— — — —"
          onChange={(e) => setCode(e.target.value)}
        />
      </Box>

      <PrimaryButton
        type="button"
        disabled={timer > 0}
        fullWidth
        size="large"
        onClick={() => resendCode()}
      >
        {t('resendCode')}
        {timer > 0 ? `in ${timer} sec` : ''}
      </PrimaryButton>
    </Box>
  )
}

export default PhoneConfirmationForm
