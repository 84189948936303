import { useTheme } from '@mui/material'
import { DesktopTimePicker } from '@mui/x-date-pickers'
import { DateTime } from 'luxon'

interface Props {
  disabled?: boolean
  value?: DateTime<true> | DateTime<false> | null | undefined
  minTime?: DateTime<true> | DateTime<false> | undefined
  maxTime?: DateTime<true> | DateTime<false> | undefined
  onChange?: (value: DateTime | null) => void
}

const TimePicker = ({ disabled, maxTime, minTime, onChange, value }: Props) => {
  const theme = useTheme()

  return (
    <DesktopTimePicker
      ampm={false}
      disabled={disabled}
      value={value}
      minTime={minTime}
      maxTime={maxTime}
      minutesStep={15}
      onChange={onChange}
      slotProps={{
        textField: { onKeyDown: (e) => e.preventDefault() },
        popper: {
          sx: {
            '& .MuiList-root': {
              width: '150px',
              [theme.breakpoints.down('md')]: {
                width: '80px',
              },
            },
            '.MuiButtonBase-root': {
              fontSize: '40px',
              width: '100%',
              [theme.breakpoints.down('md')]: {
                fontSize: '20px',
              },
            },
            '& .MuiMenuItem-root': {
              '&.Mui-selected': {
                fontSize: '40px',
                [theme.breakpoints.down('md')]: {
                  fontSize: '20px',
                },
              },
            },
            '& .MuiDialogActions-root': {
              height: '60px',
              [theme.breakpoints.down('md')]: {
                height: '40px',
              },
            },
          },
        },
      }}
      sx={{
        width: '100%',
        '& .MuiInputBase-root': {
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '30px',
          fontSize: '48px',
          px: 5,
          py: 3.25,
          color: theme.palette.primary.main,
          background: theme.palette.common.white,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '.MuiInputAdornment-root': {
          svg: {
            fontSize: '65px',
            color: ` ${theme.palette.primary.main}`,
          },
          '.MuiClockNumber-root': {
            color: 'red',
            fontSize: '18px',
          },
          '.MuiClockNumber-root.Mui-selected': {
            backgroundColor: 'blue',
          },
        },
        [theme.breakpoints.down('md')]: {
          '& .MuiInputBase-root': {
            borderRadius: '20px',
            p: '10px',
            fontSize: '16px',
          },
          '.MuiInputAdornment-root': {
            svg: {
              fontSize: '25px',
              mr: '20px',
            },
          },
        },
      }}
    />
  )
}

export default TimePicker
