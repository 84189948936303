import { useQuery } from '@tanstack/react-query'
import { getChanelId } from '@/services/auth'
import { getDeliveryZone } from '@/services/delivery-zone'

const useDeliveryZone = () => {
  const channelId = getChanelId()

  return useQuery({
    queryKey: ['delivery-zone', channelId],
    queryFn: getDeliveryZone,
    staleTime: 60 * 60 * 1000,
  })
}

export default useDeliveryZone
