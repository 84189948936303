import { useTranslation } from 'react-i18next'
import { Box, Fade, Grid, Modal, Typography, useTheme } from '@mui/material'
import ExitIcon from '@/components/icons/ExitIcon'
import PrimaryButton from '@/components/shared/buttons/PrimaryButton'
import SecondaryButton from '@/components/shared/buttons/SecondaryButton'
import UpsellProduct from '@/components/upsell/UpsellProduct'
import useUpsells from '@/hooks/useUpsells'
import useUpsellStore from '@/store/useUpsellStore'

interface Props {
  onProceed: () => void
}

const CheckoutUpsell = ({ onProceed }: Props) => {
  const theme = useTheme()
  const { t } = useTranslation('upsell')

  const { data: upsells } = useUpsells()
  const upsell = upsells?.checkout?.bunches[0]

  const checkoutUpsellVisibility = useUpsellStore(
    (s) => s.checkoutUpsellVisibility,
  )
  const hideCheckoutUpsell = useUpsellStore((s) => s.hideCheckoutUpsell)

  const handleProceed = () => {
    hideCheckoutUpsell()
    onProceed()
  }

  return (
    <Modal open={checkoutUpsellVisibility} onClose={() => hideCheckoutUpsell()}>
      <Fade in={checkoutUpsellVisibility}>
        <Box
          width="100%"
          maxWidth="1000px"
          position="absolute"
          top="50%"
          left="50%"
          borderRadius="100px"
          pt={13}
          px={7.5}
          pb={7.5}
          sx={{
            transform: 'translate(-50%, -50%)',
            background: theme.palette.primary.light,
            [theme.breakpoints.down('md')]: {
              top: '50%',
              borderRadius: '30px',
              p: 2,
              width: '94%',
            },
          }}
        >
          <Box
            width={100}
            height={100}
            position="absolute"
            sx={{
              transform: 'translate(-50%, -50%)',
              top: '-7%',
              left: '50%',
              [theme.breakpoints.down('md')]: {
                width: '46px',
                height: '44px',
                svg: {
                  width: 30,
                },
              },
            }}
          >
            <PrimaryButton fullWidth onClick={() => hideCheckoutUpsell()}>
              <ExitIcon />
            </PrimaryButton>
          </Box>

          <Box maxWidth="700px" mx="auto">
            <Box
              mb={7.5}
              sx={{
                [theme.breakpoints.down('md')]: {
                  mb: 2,
                },
              }}
            >
              <Typography
                variant="body"
                textAlign="center"
                marginBottom={5}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    mb: 2,
                    fontSize: 20,
                  },
                }}
              >
                {t('proposition')}
              </Typography>

              <Box
                className="hide-scroll"
                marginBottom={7.5}
                sx={{
                  overflowY: 'auto',
                  height: '55vh',
                  [theme.breakpoints.down('md')]: {
                    height: '59vh',
                    mt: '0px !important',
                    pl: '36px',
                    pr: '10px',
                    pt: '25px',
                    mb: '20px',
                  },
                }}
              >
                <Grid
                  container
                  rowSpacing={5}
                  columnSpacing={5}
                  justifyContent="center"
                >
                  {upsell?.items.map((productId: string) => (
                    <UpsellProduct
                      key={productId}
                      productId={productId}
                      onAddToCart={handleProceed}
                      withoutBtn
                    />
                  ))}
                </Grid>
              </Box>

              <SecondaryButton onClick={handleProceed}>
                {t('thanks')}
              </SecondaryButton>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  )
}

export default CheckoutUpsell
