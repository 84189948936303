const InfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
    >
      <path
        d="M25.5003 24.4583V34.875M25.5003 16.1458L25.5212 16.1229M25.5003 46.3333C37.0066 46.3333 46.3337 37.0062 46.3337 25.5C46.3337 13.9937 37.0066 4.66663 25.5003 4.66663C13.9941 4.66663 4.66699 13.9937 4.66699 25.5C4.66699 37.0062 13.9941 46.3333 25.5003 46.3333Z"
        stroke="#321313"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default InfoIcon
