import { Stack, useTheme } from '@mui/material'
import { isCategoryWorkingHours } from '@/services/working-hours'
import useCartStore from '@/store/useCartStore'
import useMenu from '../../hooks/useMenu'
import useCategoryStore from '../../store/useCategoryStore'
import CategoryCard from './CategoryCard'
import CompanyLogo from './CompanyLogo'

const CategoryList = () => {
  const theme = useTheme()
  const { data: menu } = useMenu()
  const selectedCategoryId = useCategoryStore((s) => s.selectedCategoryId)
  const selectedDate = useCartStore((s) => s.selectedDate)
  const setSelectedCategoryId = useCategoryStore((s) => s.setSelectedCategoryId)

  return (
    <Stack
      direction="column"
      alignItems="center"
      spacing={2.5}
      px={5}
      pt={5}
      borderLeft={`2px solid ${theme.palette.primary.midtone}`}
      sx={{
        pb: '450px',
        backgroundColor: theme.palette.primary.light,
        [theme.breakpoints.down('md')]: {
          pt: 1,
          pl: 1,
          pr: 1,
          pb: '120px',
        },
      }}
    >
      <CompanyLogo />

      <Stack
        className="hide-scroll"
        direction="column"
        gap={2.5}
        pb={5}
        sx={{
          overflowY: 'auto',
          [theme.breakpoints.down('md')]: {
            mt: '18px !important',
          },
        }}
      >
        {menu?.categories
          .filter(
            (c) =>
              c.visible && isCategoryWorkingHours(c.schedule, selectedDate),
          )
          .map((category) => (
            <CategoryCard
              key={category.id}
              category={category}
              onSelect={(id) => setSelectedCategoryId(id)}
              active={selectedCategoryId === category.id}
            />
          ))}
      </Stack>
    </Stack>
  )
}

export default CategoryList
