interface Props {
  size?: number
}

const VeganIcon = ({ size = 24 }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect width="24" height="24" rx="5" fill="#4CB8C4" />
      <path
        d="M13.1358 17.4557C12.9647 17.7898 12.621 18 12.2456 18H11.7544C11.379 18 11.0353 17.7898 10.8642 17.4557L5 6H6.80952L12 16.4057L17.1905 6H19L13.1358 17.4557Z"
        fill="#FEFEFE"
      />
    </svg>
  )
}

export default VeganIcon
