import { Box } from '@mui/material'

interface Props {
  children: React.ReactNode
}

const OptionWrapper = ({ children }: Props) => {
  return <Box mb={5}>{children}</Box>
}

export default OptionWrapper
