import { PaletteColor } from '@mui/material'

interface Props {
  paletteColor: PaletteColor
}

const DeliveryIcon = ({ paletteColor }: Props) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6667 39.5833C17.7717 39.5833 18.8315 39.1443 19.6129 38.3629C20.3943 37.5815 20.8333 36.5217 20.8333 35.4167C20.8333 34.3116 20.3943 33.2518 19.6129 32.4704C18.8315 31.689 17.7717 31.25 16.6667 31.25C15.5616 31.25 14.5018 31.689 13.7204 32.4704C12.939 33.2518 12.5 34.3116 12.5 35.4167C12.5 36.5217 12.939 37.5815 13.7204 38.3629C14.5018 39.1443 15.5616 39.5833 16.6667 39.5833ZM37.5 39.5833C38.6051 39.5833 39.6649 39.1443 40.4463 38.3629C41.2277 37.5815 41.6667 36.5217 41.6667 35.4167C41.6667 34.3116 41.2277 33.2518 40.4463 32.4704C39.6649 31.689 38.6051 31.25 37.5 31.25C36.3949 31.25 35.3351 31.689 34.5537 32.4704C33.7723 33.2518 33.3333 34.3116 33.3333 35.4167C33.3333 36.5217 33.7723 37.5815 34.5537 38.3629C35.3351 39.1443 36.3949 39.5833 37.5 39.5833Z"
        stroke={paletteColor.dark}
        strokeWidth="2"
        strokeMiterlimit="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9377 35.4167H31.2502V13.75C31.2502 13.4185 31.1185 13.1005 30.884 12.8661C30.6496 12.6317 30.3317 12.5 30.0002 12.5H2.0835M11.771 35.4167H7.50016C7.33601 35.4167 7.17347 35.3843 7.02181 35.3215C6.87015 35.2587 6.73235 35.1666 6.61628 35.0505C6.50021 34.9345 6.40813 34.7967 6.34531 34.645C6.28249 34.4934 6.25016 34.3308 6.25016 34.1667V23.9583"
        stroke={paletteColor.dark}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4.1665 18.75H12.4998"
        stroke={paletteColor.dark}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.25 18.75H42.9375C43.1791 18.7501 43.4155 18.8201 43.6182 18.9518C43.8208 19.0834 43.9809 19.2709 44.0792 19.4917L47.8083 27.8833C47.8794 28.0427 47.9163 28.2151 47.9167 28.3896V34.1667C47.9167 34.3308 47.8843 34.4934 47.8215 34.645C47.7587 34.7967 47.6666 34.9345 47.5506 35.0506C47.4345 35.1666 47.2967 35.2587 47.145 35.3215C46.9934 35.3843 46.8308 35.4167 46.6667 35.4167H42.7083M31.25 35.4167H33.3333"
        stroke={paletteColor.dark}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default DeliveryIcon
