import { Box, Paper, Typography, useTheme } from '@mui/material'
import { useProcessTranslationObject } from '@/services/translation'
import type ICategory from '@/types/Category'

interface Props {
  category: ICategory
  onSelect: (categoryId: string) => void
  active?: boolean
}

const CategoryCard = ({ category, onSelect, active = false }: Props) => {
  const theme = useTheme()
  const { processTranslationObject } = useProcessTranslationObject()

  return (
    <Paper
      sx={{
        boxShadow: 'none',
        borderRadius: '40px',
        width: 180,
        height: 276,
        backgroundColor: active
          ? theme.palette.primary.main
          : theme.palette.primary.contrastText,
        transition: '.3s',
        [theme.breakpoints.down('md')]: {
          width: '85px',
          borderRadius: '15px',
        },
      }}
      onClick={() => onSelect(category.id)}
    >
      {category.imageUrl ? (
        <Box
          p="3px"
          component="img"
          src={category.imageUrl}
          width="100%"
          borderRadius="40px 40px 0 0"
          sx={{
            [theme.breakpoints.down('md')]: {
              p: '2px',
              borderRadius: '15px 15px 0 0',
            },
          }}
        />
      ) : (
        <Box
          width="100%"
          height={180}
          sx={{
            [theme.breakpoints.down('md')]: {
              height: 85,
            },
          }}
        />
      )}

      <Typography
        variant="smallBody"
        fontWeight="600"
        textAlign="center"
        p={3}
        color={active ? 'common.white' : 'primary.dark'}
        sx={{
          borderRadius: '0 0 40px 40px',
          [theme.breakpoints.down('md')]: {
            p: 1,
            fontSize: '12px',
          },
        }}
      >
        {processTranslationObject(category.name)}
      </Typography>
    </Paper>
  )
}

export default CategoryCard
