import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { FreeMode, Mousewheel, Pagination, Scrollbar } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useOrderTypesList } from '@/utils/consts'
import useCartStore from '../../store/useCartStore'
// TODO fix cycle import
// eslint-disable-next-line import/no-cycle
import { MyContext } from './Cart'
import CartCard from './CartCard'

const CartBody = () => {
  const theme = useTheme()
  const { t } = useTranslation('cart')
  const { isExtended, setIsExtended } = useContext(MyContext)!

  const cart = useCartStore((s) => s.cart)
  const removeFromCart = useCartStore((s) => s.removeFromCart)
  const orderType = useCartStore((s) => s.orderType)
  const getCartTotalPrice = useCartStore((s) => s.getCartTotalPrice)

  const ORDER_TYPES_LIST = useOrderTypesList()

  const totalPrice = getCartTotalPrice()

  const handleCloseLastElementInBasket = () => {
    if (cart.length === 1) setIsExtended((prevState) => !prevState)
  }

  return (
    <Stack
      direction="row"
      alignItems="stretch"
      mb={5}
      pt={5}
      pl={6.25}
      sx={{
        [theme.breakpoints.down('md')]: {
          mb: 1,
          pt: 1,
          pl: 1,
          pr: 1,
        },
      }}
    >
      <Stack
        position="relative"
        direction="row"
        flex="1"
        overflow="scroll"
        spacing={2.25}
        height={isExtended ? '50vh' : '11vh'}
        sx={{
          transition: 'height .5s ease',
          [theme.breakpoints.down('md')]: {
            height: `${isExtended ? '66vh' : '0px'}`,
            overflow: 'hidden',
          },
          '& .swiper-wrapper': {
            display: 'flex',
            flexWrap: `${isExtended ? 'wrap' : 'no-wrap'}`,
            height: '202px',
            gap: 2.5,
          },
          '& .swiper-slide-active': {
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            ml: '2px',
            [theme.breakpoints.down('md')]: {
              width: '98%',
            },
          },
        }}
      >
        {isExtended && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            width="95%"
            sx={{
              [theme.breakpoints.down('md')]: {
                width: '100%',
              },
            }}
          >
            <Swiper
              direction="vertical"
              slidesPerView="auto"
              freeMode
              scrollbar
              mousewheel
              modules={[FreeMode, Scrollbar, Mousewheel]}
              className="mySwiper"
            >
              <SwiperSlide>
                {cart.map((i) => (
                  <Box
                    key={i.uniqueId}
                    display="inline-block"
                    mr="28px"
                    mb={7.5}
                    sx={{
                      [theme.breakpoints.down('md')]: {
                        mr: 0,
                        mb: 1,
                        width: '46%',
                      },
                    }}
                  >
                    <CartCard
                      product={i}
                      onRemove={(id) => removeFromCart(id)}
                      onClickDelete={handleCloseLastElementInBasket}
                    />
                  </Box>
                ))}
              </SwiperSlide>
            </Swiper>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
              gap="30px"
              padding="24px 0 24px"
              zIndex={99}
              sx={{
                backgroundColor: theme.palette.primary.light,
                borderTop: `1px solid ${theme.palette.primary.main}`,
                borderBottom: `1px solid ${theme.palette.primary.main}`,
                [theme.breakpoints.down('md')]: {
                  padding: '8px 0 8px',
                  width: '100%',
                },
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                maxWidth="475px"
                fontSize="24px"
                fontWeight="600"
                borderRadius="20px"
                textTransform="uppercase"
                lineHeight={1.2}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: '14px',
                  },
                }}
              >
                {t('myOrderIs')}
                <Box
                  marginLeft="10px"
                  fontWeight="800"
                  fontSize="40px"
                  pl={3}
                  color={theme.palette.primary.main}
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      fontSize: '14px',
                      pl: 0,
                    },
                  }}
                >
                  {orderType ? ORDER_TYPES_LIST[orderType].name : ''}
                </Box>
              </Box>
              <Box
                height="30px"
                sx={{
                  borderRight: `2px solid ${theme.palette.primary.main}`,
                }}
              />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                maxWidth="475px"
                fontSize="24px"
                fontWeight="600"
                borderRadius="20px"
                textTransform="uppercase"
                lineHeight={1.2}
                sx={{
                  [theme.breakpoints.down('md')]: {
                    fontSize: '14px',
                  },
                }}
              >
                {t('totalPrice')}
                <Box
                  marginLeft="10px"
                  fontWeight="800"
                  fontSize="40px"
                  pl={3}
                  color={theme.palette.primary.main}
                  sx={{
                    [theme.breakpoints.down('md')]: {
                      fontSize: '14px',
                      pl: 0,
                    },
                  }}
                >
                  {`${totalPrice} ₪`}
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {!isExtended && (
          <Swiper
            slidesPerView={1}
            pagination={{
              clickable: true,
            }}
            centeredSlides={false}
            modules={[Pagination]}
            className="mySwiper"
            style={{
              width: '216px',
            }}
          >
            {cart.map((i) => (
              <SwiperSlide key={i.uniqueId}>
                <CartCard product={i} onRemove={(id) => removeFromCart(id)} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </Stack>

      {!isExtended && (
        <Stack
          width="258px"
          flexShrink="0"
          px={3}
          direction="column"
          alignItems="center"
          justifyContent="center"
          borderLeft={`1px solid ${theme.palette.primary.dark}`}
          sx={{
            [theme.breakpoints.down('md')]: {
              width: '100%',
              alignItems: 'unset',
              flexDirection: 'row',
              justifyContent: 'space-around',
              border: 0,
              borderBottom: `1px solid ${theme.palette.primary.main}`,
              padding: '0 0 5px 0',
            },
          }}
        >
          <Typography
            fontSize="24px"
            fontWeight="600"
            lineHeight="1.2"
            color="primary.dark"
            mb={2.5}
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: '16px',
                m: 0,
              },
            }}
          >
            {t('totalPrice')}
          </Typography>
          <Typography
            fontSize="40px"
            fontWeight="800"
            lineHeight="1.2"
            color="primary.main"
            sx={{
              [theme.breakpoints.down('md')]: {
                fontSize: '18px',
              },
            }}
          >
            {totalPrice} ₪
          </Typography>
        </Stack>
      )}
    </Stack>
  )
}

export default CartBody
