import { AxiosRequestConfig } from 'axios'
import { axiosInstance } from '@/services/api-client'
import decodeToken from '@/utils/decode-jwt'
import getCookie from '@/utils/get-cookie'

interface LoginResponse {
  accessToken: string
  refreshToken: string
}

export const logIn = async (secretKey: string) => {
  const result = await axiosInstance.post<LoginResponse>(
    '/auth/login',
    {
      secretKey,
    },
    {
      withoutToken: true,
    } as AxiosRequestConfig,
  )

  return result.data
}

// export const logIn = async (secretKey: string) => {
//   const apiClient = new APIClient<LoginResponse>('auth/login')
//
//   return apiClient.post({ secretKey })
// }

export const getChanelId = () => {
  const token = getCookie('_auth')

  if (!token) return null

  const decodedToken = decodeToken(token)

  return decodedToken?.sub
}

export const isTokenExpires = (expires?: string | null) => {
  if (!expires) return false

  return new Date(expires).getTime() - Date.now() > 60
}
